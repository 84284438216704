import React, { FC } from 'react';
import { IInfoItem } from './types';
import { InfoBlock } from '../../ui/redesign/InfoBlock';

export const InfoCard: FC<{ infoItem: IInfoItem }> = ({ infoItem }) => {
  return (
    <div className="flex flex-col gap-[50px] bg-base p-10 ">
      <InfoBlock text={infoItem.text} description={infoItem.description} />
      <div className="m-auto block max-h-[363px] max-w-[350px]">
        <img src={infoItem.img} alt="слайд" />
      </div>
    </div>
  );
};
