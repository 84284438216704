import { AgentOrClientOrderStatusForTable } from 'components/AgentOrClientOrderStatusForTable';
import { Tab } from 'components/redesign/Tabs/types';
import { OrderDetailsStatusEnum } from 'services/AgentService/types';
import { formatAmount, getDateWithTimezone, getMonthByNumber } from '../../utils/helpers';
import { AgentOrderTabsEnum } from './types';

const repeatingTableHeadings: TableHeading[] = [
  {
    attributeName: 'number',
    name: 'Заявка',
    renderColumn: (column) => <span className="whitespace-nowrap">№ {column as string}</span>,
  },
  {
    attributeName: 'month',
    name: 'Месяц',
    renderColumn: (column) => (
      <span className="whitespace-nowrap">{getMonthByNumber(Number(column))}</span>
    ),
  },
  { attributeName: 'year', name: 'Год' },
  {
    attributeName: 'creationTime',
    name: 'Дата создания заявки',
    renderColumn: (column) => (
      <span className="whitespace-nowrap">
        {getDateWithTimezone(String(column), undefined, 'dd.MM.yyyy HH:mm')}
      </span>
    ),
  },
  {
    attributeName: 'paymentAmount',
    name: 'Вознаграждение',
    renderColumn: (column) => (
      <span className="whitespace-nowrap">
        {formatAmount(Number(column), { minimumFractionDigits: 2 })} ₽
      </span>
    ),
  },
  {
    attributeName: 'status',
    name: 'Статус',
    renderColumn: (column) => (
      <AgentOrClientOrderStatusForTable status={column as OrderDetailsStatusEnum} />
    ),
  },
];

const inProgressOrdersTableHeadings: TableHeading[] = [...repeatingTableHeadings];

const revisionOrdersTableHeadings: TableHeading[] = [...repeatingTableHeadings];

const completedOrdersTableHeadings: TableHeading[] = [...repeatingTableHeadings];

export const agentOrdersTableHeadings = {
  [AgentOrderTabsEnum.inProgress]: inProgressOrdersTableHeadings,
  [AgentOrderTabsEnum.revision]: revisionOrdersTableHeadings,
  [AgentOrderTabsEnum.completed]: completedOrdersTableHeadings,
};

export const agentTabs: Tab<AgentOrderTabsEnum>[] = [
  { link: '/agent/orders/inProgress', orderType: AgentOrderTabsEnum.inProgress, name: 'В работе' },
  {
    link: '/agent/orders/revision',
    orderType: AgentOrderTabsEnum.revision,
    name: 'На доработке',
  },
  {
    link: '/agent/orders/completed',
    orderType: AgentOrderTabsEnum.completed,
    name: 'Завершённые',
  },
];
