import { UseFormSetError } from 'react-hook-form';
import { OptionType } from 'ui/redesign/Select/types';
import { CreateAgentReceiptFormValuesService } from './types';

export const sourceIncomeOptions: OptionType[] = [
  { label: 'Физическое лицо', value: 1 },
  { label: 'Юридическое лицо', value: 2 },
  { label: 'Иностранная организация', value: 3 },
];

export const checkSourceIncome = (
  value: OptionType | undefined,
  fieldName: string,
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  let errorMessage = '';
  const errorRequiredMessage = 'Поле Доход от должно быть заполнено';
  if (!value) {
    errorMessage = errorRequiredMessage;
  }

  if (errorMessage) {
    setError(fieldName, {
      type: 'manual',
      message: errorMessage,
    });
    toastErrors.push(errorMessage);
  }
};

export const checkBuyerTin = (
  value: string | undefined,
  fieldName: string,
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  let errorMessage = '';
  const errorRequiredMessage = "Поле 'ИНН' должно быть заполнено";
  const errorCountMessage = 'ИНН должен состоять из 10 или 12 цифр';
  const errorValidMessage = 'Введите валидный ИНН';
  const innNumbers = value?.split('').map(Number) ?? [];
  if (!value) {
    errorMessage = errorRequiredMessage;
  } else if (value.length !== 10 && value.length !== 12) {
    errorMessage = errorCountMessage;
  } else if (value.length === 10) {
    if (
      !(
        innNumbers[9] ===
        ((2 * innNumbers[0] +
          4 * innNumbers[1] +
          10 * innNumbers[2] +
          3 * innNumbers[3] +
          5 * innNumbers[4] +
          9 * innNumbers[5] +
          4 * innNumbers[6] +
          6 * innNumbers[7] +
          8 * innNumbers[8]) %
          11) %
          10
      )
    ) {
      errorMessage = errorValidMessage;
    }
  } else {
    if (
      !(
        innNumbers[10] ===
          ((7 * innNumbers[0] +
            2 * innNumbers[1] +
            4 * innNumbers[2] +
            10 * innNumbers[3] +
            3 * innNumbers[4] +
            5 * innNumbers[5] +
            9 * innNumbers[6] +
            4 * innNumbers[7] +
            6 * innNumbers[8] +
            8 * innNumbers[9]) %
            11) %
            10 &&
        innNumbers[11] ===
          ((3 * innNumbers[0] +
            7 * innNumbers[1] +
            2 * innNumbers[2] +
            4 * innNumbers[3] +
            10 * innNumbers[4] +
            3 * innNumbers[5] +
            5 * innNumbers[6] +
            9 * innNumbers[7] +
            4 * innNumbers[8] +
            6 * innNumbers[9] +
            8 * innNumbers[10]) %
            11) %
            10
      )
    ) {
      errorMessage = errorValidMessage;
    }
  }

  if (errorMessage) {
    setError(fieldName, {
      type: 'manual',
      message: errorMessage,
    });
    toastErrors.push(errorMessage);
  }
};

export const checkBuyerName = (
  value: string | undefined,
  fieldName: string,
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  let errorMessage = '';
  const errorRequiredMessage = "Поле 'Наименование организации' должно быть заполнено";
  if (!value || !value?.trim()) {
    errorMessage = errorRequiredMessage;
  }

  if (errorMessage) {
    setError(fieldName, {
      type: 'manual',
      message: errorMessage,
    });
    toastErrors.push(errorMessage);
  }
};

export const checkPurchaseTime = (
  value: Date | undefined,
  fieldName: string,
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  let errorMessage = '';
  const errorRequiredMessage = "Поле 'Дата' должно быть заполнено";
  if (!value) {
    errorMessage = errorRequiredMessage;
  }

  if (errorMessage) {
    setError(fieldName, {
      type: 'manual',
      message: errorMessage,
    });
    toastErrors.push(errorMessage);
  }
};

export const checkServices = (
  value: CreateAgentReceiptFormValuesService[],
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  const errorRequiredCostMessage = "Поле 'Сумма дохода' должно быть заполнено";
  const errorRequiredNameMessage = "Поле 'Наименование товаров' должно быть заполнено";

  value.map((service, index) => {
    if (!service.cost) {
      toastErrors.push(errorRequiredCostMessage);
      setError(`services${index}cost`, {
        type: 'manual',
        message: errorRequiredCostMessage,
      });
    }
    if (!service.name?.trim()) {
      toastErrors.push(errorRequiredNameMessage);
      setError(`services${index}name`, {
        type: 'manual',
        message: errorRequiredNameMessage,
      });
    }
  });
};

export const checkSummary = (
  value: string,
  fieldName: string,
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  let errorMessage = '';
  const errorSumMessage = 'Максимум 2 399 999 руб. 99 коп';
  if (Number(value.replace(/ /g, '')) > 2399999.99) {
    errorMessage = errorSumMessage;
  }

  if (errorMessage) {
    setError(fieldName, {
      type: 'manual',
      message: errorMessage,
    });
    toastErrors.push(errorMessage);
  }
};

export const newService: CreateAgentReceiptFormValuesService = {
  name: '',
  cost: undefined,
};
