import { TrashIcon } from 'assets/icons';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { FieldDatePicker } from 'components/redesign/FieldDatePicker';
import { FieldInput } from 'components/redesign/FieldInput';
import { FieldSelect } from 'components/redesign/FieldSelect';
import { FieldTextarea } from 'components/redesign/FieldTextArea';
import { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { AgentService } from 'services/AgentService';
import { CreditParameters, GetProductNames } from 'services/AgentService/types';
import { CustomInput } from 'ui/redesign/CustomInput';
import { OptionType } from 'ui/redesign/Select/types';
import { FieldContractsProps } from './types';
import useDebounce from 'hooks/useDebounce';
import { formatAmount } from 'utils/helpers';

export const FieldContracts: FC<FieldContractsProps> = ({
  control,
  contracts,
  contractRemove,
  watch,
  setValue,
  clearErrors,
  errors,
  clientOrderDetailsFees,
}) => {
  const [productsNames, setProductsNames] = useState<OptionType[]>([]);
  const disabled = false;

  useQuery(['getProductNames'], () => AgentService.getProductNames(), {
    onSuccess: (data: AxiosResponse<GetProductNames>) => {
      const names = data.data.items?.map((item) => ({
        value: item.id,
        label: item.name,
        Icon: undefined,
      }));
      names && setProductsNames(names);
    },
  });

  const debouncedCreditParameters = useDebounce(clientOrderDetailsFees, 1000);

  return (
    <div className="mt-8">
      {contracts.map((item, index) => {
        const creditParameters: CreditParameters[] = [];
        return (
          <div key={item.id} className="mb-10 shadow-3xl">
            <div className="flex justify-end">
              <button type="button" onClick={() => contractRemove(index)}>
                <TrashIcon
                  className={classNames('m-2', {
                    'cursor-not-allowed text-text-50': disabled,
                    'cursor-pointer text-danger': !disabled,
                  })}
                />
              </button>
            </div>
            <div className="flex flex-col gap-8 p-5 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
              <FieldInput
                name={`contracts.${index}.number`}
                control={control}
                isClearable
                label="Номер договора"
                maxLength={11}
                type="number"
                errorMessage={errors.contracts?.length && errors.contracts[index]?.number?.message}
                clearErrors={clearErrors}
              />
              <FieldTextarea
                name={`contracts.${index}.clientFullName`}
                control={control}
                label="ФИО Клиента"
                isClearable
                maxLength={300}
                errorMessage={
                  errors.contracts?.length && errors.contracts[index]?.clientFullName?.message
                }
                clearErrors={clearErrors}
              />
              <FieldSelect
                options={productsNames}
                name={`contracts.${index}.product`}
                control={control}
                label="Наименование услуги/работы"
                className="!border-b border-light-50"
                isClearable
                errorMessage={errors.contracts?.length && errors.contracts[index]?.product?.message}
                clearErrors={clearErrors}
              />
              <FieldDatePicker
                name={`contracts.${index}.creditDate`}
                control={control}
                label="Дата"
                errorMessage={
                  errors.contracts?.length && errors.contracts[index]?.creditDate?.message
                }
                clearErrors={clearErrors}
              />
              <Controller
                name={`contracts.${index}.creditAmount`}
                control={control}
                render={({ field }) => {
                  const changeHandler = (value?: string) => {
                    field.onChange(value);
                    clearErrors && clearErrors(field.name);
                    watch('contracts')?.map((contract, i) => {
                      contract?.rewardPercent &&
                        contract?.creditAmount &&
                        creditParameters.push({
                          creditAmount:
                            typeof contract.creditAmount === 'string'
                              ? Number(contract.creditAmount.replaceAll(' ', ''))
                              : contract.creditAmount,
                          rewardPercent: Number(contract.rewardPercent),
                        }) &&
                        setValue(
                          `contracts.${i}.rewardAmount`,
                          (
                            (creditParameters[i].creditAmount * creditParameters[i].rewardPercent) /
                            100
                          ).toFixed(2)
                        );
                    });
                    creditParameters.length && debouncedCreditParameters({ creditParameters });
                  };
                  return (
                    <CustomInput
                      label="Сумма по услуге"
                      onChange={changeHandler}
                      value={field.value}
                      maxValue={99999999.99}
                      decimal={2}
                      errorMessage={
                        errors.contracts?.length && errors.contracts[index]?.creditAmount?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                name={`contracts.${index}.rewardPercent`}
                control={control}
                render={({ field }) => {
                  const changeHandler = (value?: string) => {
                    field.onChange(value);
                    clearErrors && clearErrors(field.name);
                    watch('contracts')?.map((contract, i) => {
                      contract.rewardPercent &&
                        contract.creditAmount &&
                        creditParameters.push({
                          creditAmount:
                            typeof contract.creditAmount === 'string'
                              ? Number(contract.creditAmount.replaceAll(' ', ''))
                              : contract.creditAmount,
                          rewardPercent: Number(contract.rewardPercent),
                        }) &&
                        setValue(
                          `contracts.${i}.rewardAmount`,
                          (
                            (creditParameters[i].creditAmount * creditParameters[i].rewardPercent) /
                            100
                          ).toFixed(2)
                        );
                    });
                    creditParameters.length && debouncedCreditParameters({ creditParameters });
                  };
                  return (
                    <CustomInput
                      label="Вознаграждение"
                      onChange={changeHandler}
                      value={field.value}
                      maxValue={100}
                      decimal={15}
                      errorMessage={
                        errors.contracts?.length && errors.contracts[index]?.rewardPercent?.message
                      }
                    />
                  );
                }}
              />
              <FieldInput
                name={`contracts.${index}.rewardAmount`}
                control={control}
                label="Вознаграждение (по договору), руб.: 0,00"
                className="headline-normal border-0 border-b-2 border-solid border-light-50 text-text-100"
                readOnly
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
