import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC, useCallback } from 'react';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { OrderDetailsForAgentSuccessForm } from './OrderDetailsForAgentSuccessForm';
import { OrderDetailsForAgentRejectForm } from './OrderDetailsForAgentRejectForm';
import { OrderDetailsForAgentActionsProps } from './types';
import { OrderDetailsActionEnum, OrderDetailsStatusEnum } from 'services/AgentService/types';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ExecutorPaymentRequestDto } from 'types/orders';
import { AgentService } from 'services/AgentService';
import { BaseError } from 'types';
import { toast } from 'react-toastify';
import { useAppSelector } from 'hooks/useAppSelector';
import { getFtsProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getFtsProfile';
import { FtsStatusEnum } from 'services/FtsOrganizationService/types';

export const OrderDetailsForAgentActions: FC<OrderDetailsForAgentActionsProps> = ({
  order,
  setActualOrder,
}) => {
  const dispatch = useAppDispatch();
  const ftsProfile = useAppSelector(getFtsProfile);
  const isShowCreateReceiptAction =
    order.status === OrderDetailsStatusEnum.NotFiscalize &&
    ftsProfile?.ftsStatus === FtsStatusEnum.FtsCheckingComplete;

  const handleModal = useCallback(
    (action: 'success' | 'reject') => {
      dispatch(
        modalActions.handleOpenModal({
          content:
            action === 'success' ? (
              <OrderDetailsForAgentSuccessForm order={order} setActualOrder={setActualOrder} />
            ) : (
              <OrderDetailsForAgentRejectForm order={order} setActualOrder={setActualOrder} />
            ),
          options: {
            title:
              action === 'success' ? 'Подписание акта выполненных работ' : 'Укажите причину отказа',
            contentClassName: 'md:min-w-[650px] text-text-100',
          },
        })
      );
    },
    [dispatch]
  );

  const { mutateAsync: createReceiptByAgent } = useMutation<
    AxiosResponse<ExecutorPaymentRequestDto>,
    AxiosError<BaseError>,
    { id: string; concurrencyStamp: string }
  >({
    mutationKey: 'createReceiptByAgent',
    mutationFn: ({ id, concurrencyStamp }) =>
      AgentService.createReceiptByAgent(id, concurrencyStamp),
    onSuccess: ({ data }) => {
      setActualOrder(data);
    },
    onError: (data) => {
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  return (
    <div className="z-10 flex items-center justify-start">
      {order.actions?.includes(OrderDetailsActionEnum.RefuseByExecutor) && (
        <Button
          variant={ButtonVariant.OUTLINE}
          className="mr-5"
          onClick={() => handleModal('reject')}
        >
          ОТКЛОНИТЬ
        </Button>
      )}
      {order.actions?.includes(OrderDetailsActionEnum.SignByExecutor) && (
        <Button
          variant={ButtonVariant.SUCCESS}
          onClick={() => handleModal('success')}
          className="mr-5"
        >
          ПОДТВЕРДИТЬ
        </Button>
      )}
      {isShowCreateReceiptAction && (
        <Button
          variant={ButtonVariant.SUCCESS}
          onClick={() =>
            order.concurrencyStamp &&
            createReceiptByAgent({ id: order.id, concurrencyStamp: order.concurrencyStamp })
          }
        >
          СФОРМИРОВАТЬ ЧЕК
        </Button>
      )}
    </div>
  );
};
