import classNames from 'classnames';
import { AlertBanner } from 'components/redesign/AlertBanner';
import { useAppSelector } from 'hooks/useAppSelector';
import { useGetFtsProfile } from 'hooks/useGetFtsProfile';
import { useQuery } from 'react-query';
import { getExecutorSpecializationIds } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorSpecializationIds';
import { getFtsProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getFtsProfile';
import { AgentService } from 'services/AgentService';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { Spinner } from 'ui/Spinner';
import { formatAmount } from 'utils/helpers';
import { StatusIcon } from './consts';
import { OptionsIcon } from 'assets/icons';
import { useState } from 'react';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { ConfirmReceiptForm } from './ConfirmReceiptForm';
import { OrderReceiptStatusEnum } from 'services/AgentService/types';
import { AxiosError } from 'axios';
import { BaseError } from 'types';
import { toast } from 'react-toastify';
import { FtsStatusEnum } from 'services/FtsOrganizationService/types';

export const AgentReceipts = () => {
  const ftsProfile = useAppSelector(getFtsProfile);
  const executorSpecializationIds = useAppSelector(getExecutorSpecializationIds);
  useGetFtsProfile(executorSpecializationIds);
  const [isOptionOpen, setIsOptionOpen] = useState<null | string>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: receipts, isFetching } = useQuery(
    ['getFtsReceiptList'],
    () => AgentService.getFtsReceiptList(),
    {
      onError: (data: AxiosError<BaseError>) => {
        data.response?.data.errors
          ? Object.values(data.response?.data.errors).forEach((errors) =>
              errors.forEach((message) => toast.error(message))
            )
          : toast.error(data.response?.data.detail);
      },
    }
  );

  const showOption = (id: string) => {
    setIsOptionOpen(id === isOptionOpen ? null : id);
  };

  const cancelReceipt = (id: string) => {
    dispatch(
      modalActions.handleOpenModal({
        content: <ConfirmReceiptForm id={id} refetchPathId="ReceiptList" />,
        options: {
          title: 'Подтвердите действие',
          modalClassName: 'w-full',
        },
      })
    );
  };

  const handleRowClick = (id: string): void => {
    navigate(`/agent/receipts/${id}`);
  };

  return (
    <PageWrapper title="Чеки" isGoBack={false}>
      {ftsProfile && (
        <AlertBanner
          status={ftsProfile.ftsStatus}
          unpaidAmount={ftsProfile.unpaidAmount}
          debtAmount={ftsProfile.debtAmount}
          bonusAmount={ftsProfile.bonusAmount}
        />
      )}
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <div>
            <div
              className={classNames(
                'mb-12 max-h-[75vh] max-w-[700px] overflow-scroll rounded-lg shadow-3xl sm:pb-0'
              )}
            >
              {receipts &&
                receipts.data.items.map((receipt) => {
                  return (
                    <fieldset
                      className="flex flex-col justify-between !border-b border-gray-light bg-white p-4 py-4"
                      key={receipt.id}
                    >
                      {receipt.status === OrderReceiptStatusEnum.Actual && (
                        <div className="flex flex-col justify-between">
                          <span
                            className={classNames(
                              'flex cursor-pointer items-center justify-center self-end rounded-[100px] bg-white py-[1px]',
                              {
                                'bg-light-30': isOptionOpen === receipt.id,
                              }
                            )}
                          >
                            <OptionsIcon onClick={() => showOption(receipt.id)} />
                          </span>
                          <div className="relative flex items-center self-end bg-white py-2">
                            {isOptionOpen === receipt.id && (
                              <div className="absolute top-full right-0 z-50 w-[165px] rounded bg-white py-2 shadow-lg">
                                <button
                                  onClick={() => cancelReceipt(receipt.id)}
                                  className="body-normal w-full px-3 text-left transition hover:bg-light-30"
                                >
                                  Аннулировать чек
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        className="flex cursor-pointer"
                        onClick={() => handleRowClick(receipt.id)}
                      >
                        <div className="flex flex-grow flex-col justify-between gap-1">
                          {receipt.id && (
                            <div className="mb-2 flex max-w-[95%] flex-col">
                              <span className="pb-1 !text-xs !font-normal !text-gray-medium">
                                Назначение
                              </span>
                              <span className="!font-normal">{receipt.services[0].name}</span>
                            </div>
                          )}
                        </div>
                        <div className="flex min-w-[30%] flex-col items-end justify-center">
                          <span className="pb-1 font-semibold !text-text-100">
                            {formatAmount(receipt.totalAmount, { minimumFractionDigits: 2 })} руб.
                          </span>
                          <StatusIcon status={receipt.status} />
                        </div>
                      </div>
                    </fieldset>
                  );
                })}
            </div>
            {ftsProfile?.ftsStatus === FtsStatusEnum.FtsCheckingComplete && (
              <NavLink to="/agent/receipts/create">
                <Button variant={ButtonVariant.SUCCESS}>СФОРМИРОВАТЬ ЧЕК</Button>
              </NavLink>
            )}
          </div>
        </>
      )}
    </PageWrapper>
  );
};
