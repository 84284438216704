import { EExecutorSpecialization } from 'enums/executorSpecialization';
import { UseFormSetError } from 'react-hook-form';
import { Settlement } from 'services/ModerationRequestService/types';

export const specsData = [
  { value: EExecutorSpecialization.ITEngineer, label: 'ИТ-инженер', disabled: false },
  { value: EExecutorSpecialization.InstallationWork, label: 'Монтажные работы', disabled: false },
  { value: EExecutorSpecialization.Delivery, label: 'Доставка', disabled: false },
  { value: EExecutorSpecialization.Cleaning, label: 'Клининг', disabled: false },
  { value: EExecutorSpecialization.Agent, label: 'Консультант', disabled: false },
];

export const checkServiceCities = (
  serviceCities: Settlement[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const serviceCitiesErrorMessage = 'Города обслуживания должны быть заполнены';
  if (!serviceCities?.length) {
    setError('serviceCities', {
      type: 'manual',
      message: serviceCitiesErrorMessage,
    });
    toastErrors.push(serviceCitiesErrorMessage);
  }
  return toastErrors;
};

export const checkExecutorSpecializations = (
  executorSpecializations: EExecutorSpecialization[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const executorSpecializationsErrorMessage = 'Область специализации должна быть заполнена';
  if (!executorSpecializations?.length) {
    setError('executorSpecializations', {
      type: 'manual',
      message: executorSpecializationsErrorMessage,
    });
    toastErrors.push(executorSpecializationsErrorMessage);
  }
  return toastErrors;
};

export const checkAcceptsOffer = (
  acceptsOffer: boolean,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const acceptsOfferErrorMessage = 'Необходимо принять оферту';
  if (!acceptsOffer) {
    setError('acceptsOffer', {
      type: 'manual',
      message: acceptsOfferErrorMessage,
    });
    toastErrors.push(acceptsOfferErrorMessage);
  }
  return toastErrors;
};


