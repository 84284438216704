import { OrdersJobIcon, PaymentResultsIcon } from 'assets/icons';
import { NavLink } from 'react-router-dom';
import { PageWrapper } from 'ui/redesign/PageWrapper';

export const CustomerRepresentativeOrdersPage = () => {
  return (
    <PageWrapper title="Заявки" isGoBack={false}>
      <div className="flex flex-col gap-6">
        <NavLink className="max-w-[600px]" to={'work'}>
          <div className="flex cursor-pointer items-center justify-between rounded-[0.625rem] hover:bg-light-30 sm:p-4 sm:shadow-profileMenuItem">
            <div>
              <OrdersJobIcon className="mr-4" />
              <span className="body-bold sm:title2-bold !text-text-100">Работа с заявками</span>
            </div>
          </div>
        </NavLink>
        <NavLink className="max-w-[600px]" to={'results'}>
          <div className="flex cursor-pointer items-center justify-between rounded-[0.625rem] hover:bg-light-30 sm:p-4 sm:shadow-profileMenuItem">
            <div>
              <PaymentResultsIcon className="mr-4" />
              <span className="body-bold sm:title2-bold !text-text-100">Результаты выплат</span>
            </div>
          </div>
        </NavLink>
      </div>
    </PageWrapper>
  );
};
