import { FC, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { Dropdown } from 'ui/redesign/Dropdown';
import { CashIcon, DropdownListEditIcon, DropdownListIcon } from 'assets/icons';
import { FieldSelect } from 'components/redesign/FieldSelect';
import {
  checkBuyerName,
  checkBuyerTin,
  checkPurchaseTime,
  checkServices,
  checkSourceIncome,
  checkSummary,
  sourceIncomeOptions,
} from './consts';
import { OptionType } from 'ui/redesign/Select/types';
import { FieldInput } from 'components/redesign/FieldInput';
import { FieldTextarea } from 'components/redesign/FieldTextArea';
import { FieldDatePicker } from 'components/redesign/FieldDatePicker';
import { FieldServices } from './FieldServices';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ReceiptCreateCommandDto, ReceiptDto } from 'services/AgentService/types';
import { AgentService } from 'services/AgentService';
import { CreateAgentReceiptFormValues, RedirectCreateFtsReceiptErrors } from './types';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Input } from 'ui/redesign/Input';
import { BaseError } from 'types';

export const CreateAgentReceiptForm: FC = () => {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    clearErrors,
    getValues,
    setError,
    watch,
    setValue,
  } = useForm<CreateAgentReceiptFormValues>();

  const services = [...(watch('services') ?? [])];

  const {
    fields: serviceFields,
    append: serviceAppend,
    remove: serviceRemove,
  } = useFieldArray({
    control,
    name: 'services',
  });

  const { mutateAsync: createFtsReceipt } = useMutation<
    AxiosResponse<ReceiptDto>,
    AxiosError<BaseError>,
    ReceiptCreateCommandDto
  >({
    mutationKey: 'createFtsReceipt',
    mutationFn: (body) => AgentService.createFtsReceipt(body),
    onSuccess: () => {
      navigate('/agent/receipts');
      toast.success('Чек по доходам, полученным вне площадки, создан');
    },
    onError: (data) => {
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => {
              toast.error(message);
              if (
                Object.values(RedirectCreateFtsReceiptErrors).includes(
                  message as RedirectCreateFtsReceiptErrors
                )
              ) {
                setTimeout(() => navigate('/agent/receipts'), 2000);
              }
            })
          )
        : Object.values(RedirectCreateFtsReceiptErrors).includes(
            data.response?.data.detail as RedirectCreateFtsReceiptErrors
          )
        ? (toast.error(data.response?.data.detail),
          setTimeout(() => navigate('/agent/receipts'), 2000))
        : toast.error(data.response?.data.detail);
    },
  });

  const onSubmit = () => {
    clearErrors();
    const actualFormValues = getValues();
    const toastErrors: string[] = [];

    checkSourceIncome(
      actualFormValues.sourceIncome as OptionType,
      'sourceIncome',
      setError,
      toastErrors
    );

    if (actualFormValues.sourceIncome?.value === 2 || actualFormValues.sourceIncome?.value === 3) {
      checkBuyerName(actualFormValues.buyerName, 'buyerName', setError, toastErrors);
    }

    if (actualFormValues.sourceIncome?.value === 2) {
      checkBuyerTin(actualFormValues.buyerTin, 'buyerTin', setError, toastErrors);
    }

    checkPurchaseTime(actualFormValues.purchaseTime, 'purchaseTime', setError, toastErrors);

    checkServices(actualFormValues.services ?? [], setError, toastErrors);

    checkSummary(actualFormValues.summary ?? '', 'summary', setError, toastErrors);

    if (toastErrors.length) {
      const uniqueMessages = Array.from(new Set(toastErrors));
      uniqueMessages.forEach((message: string) => {
        toast.error(message);
      });

      return;
    }

    const requestData = {
      buyerName: actualFormValues.buyerName ?? null,
      buyerTin: actualFormValues.buyerTin ?? null,
      purchaseTime: actualFormValues.purchaseTime
        ? format(actualFormValues.purchaseTime, 'yyyy-MM-dd')
        : '',
      sourceIncome: actualFormValues.sourceIncome?.value ?? 0,
      services:
        actualFormValues.services?.map((service) => ({
          name: service.name ?? null,
          cost: service.cost ? Number(service.cost.replace(/ /g, '')) : 0,
        })) ?? [],
    };

    createFtsReceipt(requestData);
  };

  const sourceIncomeChangeHandle = () => {
    setValue('buyerTin', undefined);
    setValue('buyerName', undefined);
    clearErrors('buyerTin');
    clearErrors('buyerName');
  };

  useEffect(() => {
    let summary = 0;
    services.map((service) => {
      if (service.cost) {
        summary += Number(service.cost.replace(/ /g, ''));
      }
    });
    const [integer, decimal = ''] = String(summary).split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const formattedValue =
      decimal.length < 2
        ? `${formattedInteger}.${decimal.padEnd(2, '0')}`
        : `${formattedInteger}.${decimal.slice(0, 2)}`;
    setValue('summary', formattedValue);
  }, [services, setValue]);

  return (
    <div className="sm:max-w-[950px]">
      <form>
        <Dropdown title="Тип дохода" Icon={CashIcon}>
          <FieldSelect
            options={sourceIncomeOptions}
            name="sourceIncome"
            control={control}
            label="Доход от"
            isClearable
            errorMessage={errors?.sourceIncome?.message}
            clearErrors={clearErrors}
            className="!border-b border-light-50"
            onChange={sourceIncomeChangeHandle}
          />
        </Dropdown>
        {(watch('sourceIncome')?.value === 2 || watch('sourceIncome')?.value === 3) && (
          <Dropdown title="Данные контрагента" Icon={DropdownListIcon} className="mt-6">
            {watch('sourceIncome')?.value === 2 && (
              <FieldInput
                name={'buyerTin'}
                control={control}
                label="ИНН"
                errorMessage={errors.buyerTin?.message}
                isClearable
                type="number"
                maxLength={12}
              />
            )}
            <br />
            <FieldTextarea
              name="buyerName"
              control={control}
              label="Наименование организации"
              errorMessage={errors.buyerName?.message}
              isClearable
              maxLength={1000}
            />
          </Dropdown>
        )}
        <Dropdown title="Сведения об операции" Icon={DropdownListEditIcon} className="mt-6">
          <FieldDatePicker
            name="purchaseTime"
            control={control}
            label="Дата"
            errorMessage={errors.purchaseTime?.message}
            clearErrors={clearErrors}
            maxDate={new Date()}
          />
          <FieldServices
            services={serviceFields}
            control={control}
            errors={errors}
            serviceRemove={serviceRemove}
            serviceAppend={serviceAppend}
          />
          <Controller
            name="summary"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  label="Итого, руб"
                  value={field.value}
                  readOnly
                  errorMessage={errors.summary?.message}
                />
              );
            }}
          />
        </Dropdown>
        <Button variant={ButtonVariant.SUCCESS} className="mt-6" type="button" onClick={onSubmit}>
          СФОРМИРОВАТЬ ЧЕК
        </Button>
      </form>
    </div>
  );
};
