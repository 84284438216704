import {
  IconInstruction,
  IconModerationRequest,
  IconNewspaper,
  IconOrders,
  IconProfile,
  NotificationIcon,
} from 'assets/icons';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';

interface NavLinkModel {
  link: string;
  name: string;
  icon: import('react').ReactNode;
  counter?: boolean;
  guardPermissions?: ProfilePermissions[];
}

export const navLinks: NavLinkModel[] = [
  { link: '/profile', name: 'Профиль', icon: <IconProfile /> },
  {
    link: '/moderation-request',
    name: 'Заявка на модерацию',
    icon: <IconModerationRequest />,
    guardPermissions: [
      ProfilePermissions.Request_Executor,
      ProfilePermissions.Request_ExecutorSubcontractor,
      ProfilePermissions.Agent_PaymentRequest_ReadExecutor,
      ProfilePermissions.ModerationRequest_Create,
      ProfilePermissions.VerificationQuestionary_Create,
    ],
  },
  {
    link: '/orders',
    name: 'Заявки',
    icon: <IconOrders />,
    guardPermissions: [
      ProfilePermissions.Request_Executor,
      ProfilePermissions.Request_ExecutorSubcontractor,
    ],
  },
  {
    link: '/agent/orders',
    name: 'Заявки',
    icon: <IconOrders />,
    guardPermissions: [ProfilePermissions.Agent_PaymentRequest_ReadExecutor],
  },
  {
    link: '/client/orders',
    name: 'Заявки',
    icon: <IconOrders />,
    guardPermissions: [
      ProfilePermissions.Agent_Bank_Manager,
      ProfilePermissions.Agent_Accreditaion_employee,
    ],
  },
  {
    link: '/client/customerrepresentative/orders',
    name: 'Заявки',
    icon: <IconOrders />,
    guardPermissions: [ProfilePermissions.Agent_Customers_Representative],
  },
  {
    link: '/receipts',
    name: 'Чеки',
    icon: <IconNewspaper />,
    guardPermissions: [ProfilePermissions.Billing_GetMyExecutorReceipts],
  },
  {
    link: '/agent/receipts',
    name: 'Чеки',
    icon: <IconNewspaper />,
    guardPermissions: [ProfilePermissions.Agent_PaymentRequest_ReadExecutor],
  },
  {
    link: '/help',
    name: 'Инструкция',
    icon: <IconInstruction />,
    guardPermissions: [
      ProfilePermissions.Request_Executor,
      ProfilePermissions.Request_ExecutorSubcontractor,
      ProfilePermissions.Agent_PaymentRequest_ReadExecutor,
      ProfilePermissions.VerificationQuestionary_Create,
      ProfilePermissions.ModerationRequest_Create,
    ],
  },
  {
    link: '/fts-notifications',
    name: 'Уведомления ФНС',
    icon: <NotificationIcon />,
    counter: true,
    guardPermissions: [ProfilePermissions.Agent_PaymentRequest_ReadExecutor],
  },
];

export const textMotionVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export const SIDEBAR_OPEN_LOCALSTORAGE_NAME = 'sidebar-open';
