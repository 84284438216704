export enum FtsStatusEnum {
  NotBind = 1,
  WaitingBind,
  CheckingYearLimits,
  ExceededYearLimits,
  AbsentSelfEmployedStatus,
  CheckingFtsData,
  IncorrectFtsData,
  IncorrectFtsFullName,
  IncorrectFtsPhone,
  FtsCheckingComplete,
  BindingInProgress,
  TechnicalIssue,
}

export interface FtsOrganizationProfileDto {
  ftsFirstName: string | null;
  ftsMiddleName: string | null;
  ftsLastName: string | null;
  ftsPhoneNumber: string | null;
  bonusAmount: number;
  unpaidAmount: number;
  debtAmount: number;
  ftsStatus?: FtsStatusEnum;
}
