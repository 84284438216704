import { CashIcon, QuestionIcon, StepIcon } from 'assets/icons';
import classNames from 'classnames';
import { ExecutorTypeEnum } from 'enums/executorType';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useMediaQuery from 'hooks/useMediaQuery';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { getModerationRequestData } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestData';
import { RadioGroup } from 'ui/RadioGroup';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { Dropdown } from 'ui/redesign/Dropdown';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { IS_MEDIUM_DEVICE } from 'utils/settings';
import { executorTypes, stepCards } from './consts';

export const RedesignExecutorModeration: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { executorType, serviceCities, acceptsOffer } = useAppSelector(getModerationRequestData);
  const isMedium = useMediaQuery(IS_MEDIUM_DEVICE);

  const [status, setStatus] = useState<ExecutorTypeEnum>(
    executorType ?? ExecutorTypeEnum.Selfemployed
  );

  const clickHandler = () => {
    dispatch(moderationRequestActions.setExecutorType(status));
    navigate('/moderation-request/step1');
  };

  return (
    <PageWrapper title="Заявка на модерацию">
      <div className="relative flex min-h-layout flex-col justify-between">
        <div className="mb-6 flex flex-col gap-6">
          <div>
            <CashIcon className="mr-3.5" />
            <span className="title2-bold text-text-100">Налоговый статус</span>
          </div>
          <div>
            <RadioGroup
              data={executorTypes}
              value={status}
              onChange={(val) => {
                setStatus(val);
                dispatch(
                  moderationRequestActions.setSettlementsAndSpecializations({
                    serviceCities: serviceCities,
                    executorSpecializations: [],
                    acceptsOffer: acceptsOffer,
                  })
                );
              }}
              disabled={false}
            />
          </div>
          <Dropdown title={`Как стать ${status === 1 ? 'самозанятым' : 'ИП'}`} Icon={QuestionIcon}>
            <div className="mb-3">Установите приложение «Мой налог»</div>
            <div className="flex flex-col gap-5 lg:flex-row">
              {stepCards.map((card) => (
                <div
                  key={card.title}
                  className={classNames('py-1', {
                    'min-h-[225px] flex-1 flex-row rounded-xl bg-base p-4 shadow-3xl': !isMedium,
                  })}
                >
                  <p className="callout-bold sm:headline-bold mt-2 !text-primary-60 sm:text-text-100">
                    {card.title}
                  </p>
                  <p className="body-normal sm:headline-normal py-2">{card.description}</p>
                </div>
              ))}
            </div>
            {!isMedium && (
              <div className="relative flex h-20 flex-row gap-5">
                <hr className="absolute top-[55px] z-10 w-full text-text-50" />
                {stepCards.map((card) => (
                  <StepIcon key={card.title} className="z-20 flex-1 flex-row" />
                ))}
              </div>
            )}
          </Dropdown>
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <span className="mr-2 mb-8 break-words sm:mb-3">
            Если у вас нет смартфона, то можно работать через веб-версию приложения «Мой налог»:
            <a
              href={'https://lknpd.nalog.ru/auth/login'}
              target="blank"
              className="ml-1 cursor-pointer text-primary-100"
            >
              https://lknpd.nalog.ru/auth/login
            </a>
          </span>
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={clickHandler}
            type="button"
            className="w-full max-w-[640px] sm:max-w-[150px]"
          >
            ДАЛЕЕ{' '}
          </Button>
        </div>
      </div>
    </PageWrapper>
  );
};
