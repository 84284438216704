import { PageWrapper } from 'ui/redesign/PageWrapper';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { DocumentFileEmptyBlock } from './DocumentFileEmptyBlock';
import { DocumentFileBlock } from './DocumentFileBlock';
import { useMutation, useQuery } from 'react-query';
import { AgentService } from 'services/AgentService';
import { Spinner } from 'ui/Spinner';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { ReferenceDto } from 'services/AgentService/types';
import { useAppSelector } from 'hooks/useAppSelector';
import { getFtsProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getFtsProfile';
import { getExecutorSpecializationIds } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorSpecializationIds';
import { useGetFtsProfile } from 'hooks/useGetFtsProfile';
import { AlertBanner } from 'components/redesign/AlertBanner';
import { BaseError } from 'types';

export const ProfileDocumentsPage = () => {
  const { isFetching, data, refetch } = useQuery(['getFtsOrganizationReference'], () =>
    AgentService.getFtsOrganizationReference()
  );
  const ftsProfile = useAppSelector(getFtsProfile);
  const executorSpecializationIds = useAppSelector(getExecutorSpecializationIds);

  useGetFtsProfile(executorSpecializationIds);

  const incomeCertificate = data?.data.find((item) => item.type === 1);
  const certificateOfRegistration = data?.data.find((item) => item.type === 2);

  const { mutateAsync: makeFtsOrganizationReference } = useMutation<
    AxiosResponse<ReferenceDto>,
    AxiosError<BaseError>,
    number
  >({
    mutationKey: 'makeFtsOrganizationReference',
    mutationFn: (type: number) => AgentService.makeFtsOrganizationReference(type),
    onSuccess: () => {
      refetch();
    },
    onError: (data) => {
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  return (
    <PageWrapper title="Документы">
      {ftsProfile && (
        <AlertBanner
          status={ftsProfile.ftsStatus}
          unpaidAmount={ftsProfile.unpaidAmount}
          debtAmount={ftsProfile.debtAmount}
          bonusAmount={ftsProfile.bonusAmount}
        />
      )}
      <section className="flex flex-col">
        <div className="flex flex-col">
          {isFetching ? (
            <Spinner />
          ) : incomeCertificate ? (
            <DocumentFileBlock
              title="Справка о доходах"
              file={incomeCertificate.file}
              creationTime={incomeCertificate.creationTime}
            />
          ) : (
            <DocumentFileEmptyBlock title="Справка о доходах" />
          )}
          <div className="mt-4 mb-10 flex justify-end sm:max-w-[950px]">
            <Button variant={ButtonVariant.SUCCESS} onClick={() => makeFtsOrganizationReference(1)}>
              СФОРМИРОВАТЬ
            </Button>
          </div>
          {isFetching ? (
            <Spinner />
          ) : certificateOfRegistration ? (
            <DocumentFileBlock
              title="Справка о постановке на учет"
              file={certificateOfRegistration.file}
              creationTime={certificateOfRegistration.creationTime}
            />
          ) : (
            <DocumentFileEmptyBlock title="Справка о постановке на учет" />
          )}
          <div className="mt-4 flex justify-end sm:max-w-[950px]">
            <Button variant={ButtonVariant.SUCCESS} onClick={() => makeFtsOrganizationReference(2)}>
              СФОРМИРОВАТЬ
            </Button>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};
