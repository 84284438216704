import { monthsMap } from 'const';
import { UseFormSetError } from 'react-hook-form';
import { OptionType } from 'ui/redesign/Select/types';
import { ClientRequestHandler, ContractForm } from './types';
import { toast } from 'react-toastify';
import { Range } from 'react-date-range';

export const newContract: ContractForm = {
  number: undefined,
  clientFullName: undefined,
  product: { value: '', label: '' },
  creditDate: undefined,
  creditAmount: undefined,
  rewardPercent: undefined,
  rewardAmount: undefined,
};

export const defaultContracts: ContractForm[] = [{ ...newContract }];

export const monthOptions = Object.entries(monthsMap).map(([key, value]) => ({
  label: value,
  value: Number(key),
}));

const currentYear: number = new Date().getFullYear();
export const yearOptions: OptionType[] = [
  { label: currentYear - 1, value: currentYear - 1 },
  { label: currentYear, value: currentYear },
  { label: currentYear + 1, value: currentYear + 1 },
];

export const checkValue = (
  value: OptionType,
  fieldName: string,
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  const errorMessage = 'Должно быть заполнено';
  if (!value || !value.value) {
    setError(fieldName, {
      type: 'manual',
      message: errorMessage,
    });
    toastErrors.push(errorMessage);
  }
  return toastErrors;
};

export const checkPeriod = (
  value: Range,
  fieldName: string,
  minDurationDays: number,
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  const errorMessage = 'Должно быть заполнено';
  const minDurationErrorMessage = `Минимальный период ${minDurationDays} календарных дней`;
  if (!value.startDate) {
    setError(fieldName, {
      type: 'manual',
      message: errorMessage,
    });
    toastErrors.push(errorMessage);
  } else if (value.startDate === value.endDate) {
    setError(fieldName, {
      type: 'manual',
      message: minDurationErrorMessage,
    });
    toastErrors.push(minDurationErrorMessage);
  }
  return toastErrors;
};

export const checkContract = (
  contract: ContractForm,
  index: number,
  request: ClientRequestHandler,
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  const errorMessage = 'Должно быть заполнено';
  const errorLengthMessage = 'Не меньше десяти символов';

  if (!contract?.number) {
    setError(`contracts.${index}.number`, {
      type: 'manual',
      message: errorMessage,
    });
    toastErrors.push(errorMessage);
  }
  if (contract?.number && contract?.number.length < 10) {
    setError(`contracts.${index}.number`, {
      type: 'manual',
      message: errorLengthMessage,
    });
    toastErrors.push(errorLengthMessage);
  }
  if (request === ClientRequestHandler.SENDFORSIGN) {
    if (!contract?.clientFullName) {
      setError(`contracts.${index}.clientFullName`, {
        type: 'manual',
        message: errorMessage,
      });
      toastErrors.push(errorMessage);
    }
    if (!(contract?.product as unknown as OptionType)?.value) {
      setError(`contracts.${index}.product`, {
        type: 'manual',
        message: errorMessage,
      });
      toastErrors.push(errorMessage);
    }
    if (!contract?.creditDate) {
      setError(`contracts.${index}.creditDate`, {
        type: 'manual',
        message: errorMessage,
      });
      toastErrors.push(errorMessage);
    }
    if (!contract?.creditAmount) {
      setError(`contracts.${index}.creditAmount`, {
        type: 'manual',
        message: errorMessage,
      });
      toastErrors.push(errorMessage);
    }
    if (!contract?.rewardPercent) {
      setError(`contracts.${index}.rewardPercent`, {
        type: 'manual',
        message: errorMessage,
      });
      toastErrors.push(errorMessage);
    }
  }
  return toastErrors;
};

export const findDuplicateNumbers = (
  contracts: ContractForm[],
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  const errorMessage = 'Номера договоров должны быть уникальными';
  const numbersCounts: Record<string, number[]> = {};

  contracts.forEach((contract, index) => {
    if (contract.number) {
      if (!numbersCounts[contract.number]) {
        numbersCounts[contract.number] = [];
      }
      numbersCounts[contract.number].push(index);
    }
  });

  for (const indexList of Object.values(numbersCounts)) {
    if (indexList.length > 1) {
      indexList.map((index) => {
        setError(`contracts${index}number`, {
          type: 'manual',
          message: errorMessage,
        });
        toastErrors.push(errorMessage);
      });
    }
  }
};

export const checkSumm = (
  value: string,
  fieldName: string,
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  const errorMessage = 'Сумма вознаграждения не может превышать 300 тыс. рублей';
  if (Number(value) > 300000) {
    setError(fieldName, {
      type: 'manual',
      message: errorMessage,
    });
    toast.error(errorMessage);
    toastErrors.push(errorMessage);
  }
  return toastErrors;
};