import { AxiosError, AxiosResponse } from 'axios';
import { FieldTextarea } from 'components/redesign/FieldTextArea';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import {
  ExecutorPaymentRequestDto,
  ExecutorPaymentRequestRejectCommandDto,
  ExecutorPaymentRequestRejectCommandError,
} from 'types/orders';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { OrderDetailsForAgentRejectFormProps } from './types';
import { AgentService } from 'services/AgentService';
import { BaseError } from 'types';

export const OrderDetailsForAgentRejectForm: FC<OrderDetailsForAgentRejectFormProps> = ({
  order,
  setActualOrder,
}) => {
  const dispatch = useAppDispatch();

  const { control, handleSubmit, watch } = useForm<{ comment: string | null }>({
    defaultValues: { comment: null },
  });

  const { mutateAsync: orderDetailsForAgentReject } = useMutation<
    AxiosResponse<ExecutorPaymentRequestDto>,
    AxiosError<BaseError>,
    ExecutorPaymentRequestRejectCommandDto & { orderId: string }
  >({
    mutationKey: 'orderDetailsForAgentReject',
    mutationFn: (
      rejectCommandWithOrderId: ExecutorPaymentRequestRejectCommandDto & { orderId: string }
    ) =>
      AgentService.orderDetailsForAgentReject(rejectCommandWithOrderId.orderId, {
        rejectReasonComment: rejectCommandWithOrderId.rejectReasonComment,
        concurrencyStamp: rejectCommandWithOrderId.concurrencyStamp,
      }),
    onSuccess: ({ data }) => {
      dispatch(modalActions.closeModal());
      setActualOrder(data);
    },
    onError: (data) => {
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        orderDetailsForAgentReject({
          orderId: order.id,
          rejectReasonComment: data.comment,
          concurrencyStamp: order.concurrencyStamp,
        });
      })}
    >
      <div className="mt-2">
        <FieldTextarea
          label="Причина отказа"
          name="comment"
          control={control}
          title="Комментарий"
          maxLength={1000}
          isClearable
        />
      </div>
      <div className="z-10 mt-5 flex justify-end">
        <Button
          variant={ButtonVariant.OUTLINE}
          className="mr-5"
          type="button"
          onClick={() => dispatch(modalActions.closeModal())}
        >
          ОТМЕНА
        </Button>
        <Button variant={ButtonVariant.PRIMARY} disabled={!watch('comment')}>
          СОХРАНИТЬ
        </Button>
      </div>
    </form>
  );
};
