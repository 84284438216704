import { EExecutorSpecialization } from 'enums/executorSpecialization';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { SpecSelectProps } from './types';
import { CustomSelect } from 'ui/redesign/CustomSelect';
import { ExecutorTypeEnum } from 'reduxStore/reducers/ProfileSlice/types';

export const SpecSelect = ({
  name,
  label,
  onChange,
  control,
  errorMessage,
  options,
  watch,
  clearErrors,
  executorType,
  ...rest
}: SpecSelectProps) => {
  const currentSpecializations: number[] | null = watch('executorSpecializations');
  const actualOptions = useMemo(() => {
    const temp = options.filter(
      (option) =>
        option.value !== EExecutorSpecialization.Agent ||
        (executorType === ExecutorTypeEnum.Selfemployed &&
          option.value === EExecutorSpecialization.Agent)
    );
    if (!currentSpecializations || !currentSpecializations.length) {
      return temp;
    }
    if (currentSpecializations?.includes(EExecutorSpecialization.Agent)) {
      return temp.map((elem) => {
        if (elem.value !== EExecutorSpecialization.Agent) {
          return { ...elem, disabled: true };
        } else {
          return { ...elem, disabled: false };
        }
      });
    } else {
      return temp.map((elem) => {
        if (elem.value === EExecutorSpecialization.Agent) {
          return { ...elem, disabled: true };
        } else {
          return { ...elem, disabled: false };
        }
      });
    }
  }, [options, executorType, currentSpecializations]);

  return (
    <div className="field-select">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <CustomSelect
              name={name}
              label={label}
              value={field.value}
              errorMessage={errorMessage}
              onChange={(value: any) => {
                field.onChange(value);
                onChange && onChange(value);
                value.length && clearErrors && clearErrors(name);
              }}
              options={actualOptions}
              {...rest}
            />
          );
        }}
      />
    </div>
  );
};
