import { OrderDetailsStatusEnum } from 'services/AgentService/types';

export interface AgentOrderRequest {
  items: [
    {
      id: string;
      number: number;
      creationTime: string;
      status: OrderDetailsStatusEnum;
      month: number;
      year: number;
      paymentAmount: number;
    }
  ];
  totalCount: number;
}

export enum AgentOrderTabsEnum {
  'inProgress' = 'inProgress',
  'revision' = 'revision',
  'completed' = 'completed',
}
