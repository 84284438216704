import { Layout } from 'layout';
import { AgentsOrdersPage } from 'pages/AgentOrders';
import { AgentReceipts } from 'pages/AgentReceipts';
import { AgentReceiptDetailsPage } from 'pages/AgentReceipts/ReceiptDetails';
import { ClientOrdersPage } from 'pages/ClientOrders';
import { ContactData } from 'pages/ContactData';
import { CreateAgentReceiptPage } from 'pages/CreateAgentReceipt';
import { CustomerRepresentativeOrdersPage } from 'pages/CustomerRepresentativeOrders';
import { CustomerRepresentativeResultsPage } from 'pages/CustomerRepresentativeOrders/CustomerRepresentativeResultsPage';
import { CustomerRepresentativeWorkPage } from 'pages/CustomerRepresentativeOrders/CustomerRepresentativeWorkPage';
import { FtsNotificationPage } from 'pages/FtsNotification';
import { FtsNotificationsPage } from 'pages/FtsNotifications';
import Help from 'pages/Help';
import Moderation from 'pages/ModerationRequest';
import { FinalStep } from 'pages/ModerationRequest/ExecutorModeration/steps/finalStep';
import { Step1 } from 'pages/ModerationRequest/ExecutorModeration/steps/step1';
import { Step2 } from 'pages/ModerationRequest/ExecutorModeration/steps/step2';
import { Step3 } from 'pages/ModerationRequest/ExecutorModeration/steps/step3';
import { Step4 } from 'pages/ModerationRequest/ExecutorModeration/steps/step4';
import { Step5 } from 'pages/ModerationRequest/ExecutorModeration/steps/step5';
import { Step6 } from 'pages/ModerationRequest/ExecutorModeration/steps/step6';
import { Step7 } from 'pages/ModerationRequest/ExecutorModeration/steps/step7';
import { FinalStepSubcontractor } from 'pages/ModerationRequest/SubcontractorModeration/steps/finalStep';
import { Step1Subcontractor } from 'pages/ModerationRequest/SubcontractorModeration/steps/step1';
import { Step2Subcontractor } from 'pages/ModerationRequest/SubcontractorModeration/steps/step2';
import { Step3Subcontractor } from 'pages/ModerationRequest/SubcontractorModeration/steps/step3';
import { Step4Subcontractor } from 'pages/ModerationRequest/SubcontractorModeration/steps/step4';
import NotFound from 'pages/NotFound';
import { AgentOrderDetailsPage } from 'pages/OrderDetails/OrderDetailsForAgent';
import { ClientOrderDetailsCreateOrUpdatePage } from 'pages/OrderDetails/OrderDetailsForClient/ClientOrderDetailsCreateOrUpdate';
import { ClientOrderDetailsViewPage } from 'pages/OrderDetails/OrderDetailsForClient/ClientOrderDetailsView';
import OrderDetailsForSubOrExPage from 'pages/OrderDetails/OrderDetailsForSubOrEx';
import Orders from 'pages/Orders';
import { PrivateData } from 'pages/PrivateData';
import { Profile } from 'pages/Profile';
import { ChangePhone } from 'pages/Profile/components/ChangePhone';
import { ChangeTimezone } from 'pages/Profile/components/ChangeTimezone';
import { ConfirmEmail } from 'pages/Profile/components/ConfirmEmail';
import { ProfileDocumentsPage } from 'pages/ProfileDocuments';
import { ProfileSettings } from 'pages/ProfileSettings';
import { Receipts } from 'pages/Receipts';
import { Support } from 'pages/Support';
import { UserAgreementPage } from 'pages/UserAgreement';
import { Navigate } from 'react-router-dom';
import { ExecutorTypeEnum } from 'reduxStore/reducers/ProfileSlice/types';

export function createRouter(role: ExecutorTypeEnum) {
  const isSubcontractor = role === ExecutorTypeEnum.Subcontractor;

  const routes = [
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: 'user-agreement',
          element: <UserAgreementPage />,
        },
        {
          path: 'receipts',
          element: <Receipts />,
        },
        {
          path: 'moderation-request',
          children: [
            { index: true, element: <Moderation /> },
            {
              path: 'step1',
              element: isSubcontractor ? <Step1Subcontractor /> : <Step1 />,
            },
            {
              path: 'step2',
              element: isSubcontractor ? <Step2Subcontractor /> : <Step2 />,
            },
            {
              path: 'step3',
              element: isSubcontractor ? <Step3Subcontractor /> : <Step3 />,
            },
            {
              path: 'step4',
              element: isSubcontractor ? <Step4Subcontractor /> : <Step4 />,
            },
            {
              path: 'step5',
              element: <Step5 />,
            },
            {
              path: 'step6',
              element: <Step6 />,
            },
            {
              path: 'step7',
              element: <Step7 />,
            },
            {
              path: 'final-step',
              element: isSubcontractor ? <FinalStepSubcontractor /> : <FinalStep />,
            },
          ],
        },
        {
          path: 'help',
          element: <Help />,
        },
        {
          path: 'orders',
          children: [
            { index: true, element: <Orders /> },
            { path: ':type', element: <Orders /> },
          ],
        },
        {
          path: 'orders/:type/:id',
          element: <OrderDetailsForSubOrExPage />,
        },
        {
          path: 'profile',
          children: [
            {
              index: true,
              element: <Profile />,
            },
            {
              path: 'private',
              element: <PrivateData />,
            },
            {
              path: 'contacts',
              element: <ContactData />,
            },
            {
              path: 'settings',
              element: <ProfileSettings />,
            },
            {
              path: 'support',
              element: <Support />,
            },
            {
              path: 'phone',
              element: <ChangePhone />,
            },
            {
              path: 'timezone',
              element: <ChangeTimezone />,
            },
            {
              path: 'email',
              element: <ConfirmEmail />,
            },
            {
              path: 'documents',
              element: <ProfileDocumentsPage />,
            },
          ],
        },
        {
          path: 'agent',
          children: [
            { path: 'orders', element: <AgentsOrdersPage /> },
            {
              path: 'orders/:tab',
              element: <AgentsOrdersPage />,
            },
            {
              path: 'orders/:tab/:id',
              element: <AgentOrderDetailsPage />,
            },
            { path: 'receipts', element: <AgentReceipts /> },
            {
              path: 'receipts/create',
              element: <CreateAgentReceiptPage />,
            },
            {
              path: 'receipts/:id',
              element: <AgentReceiptDetailsPage />,
            },
          ],
        },
        {
          path: 'client',
          children: [
            {
              path: 'orders',
              element: <ClientOrdersPage />,
            },
            {
              path: 'orders/:tab',
              element: <ClientOrdersPage />,
            },
            {
              path: 'orders/:tab/create',
              element: <ClientOrderDetailsCreateOrUpdatePage />,
            },
            {
              path: 'orders/:tab/:id',
              element: <ClientOrderDetailsViewPage />,
            },
            {
              path: 'orders/:tab/:id/update',
              element: <ClientOrderDetailsCreateOrUpdatePage />,
            },
            {
              path: 'customerrepresentative/orders',
              element: <CustomerRepresentativeOrdersPage />,
            },
            {
              path: 'customerrepresentative/orders/work',
              element: <CustomerRepresentativeWorkPage />,
            },
            {
              path: 'customerrepresentative/orders/work/:tab',
              element: <CustomerRepresentativeWorkPage />,
            },
            {
              path: 'customerrepresentative/orders/results',
              element: <CustomerRepresentativeResultsPage />,
            },
            {
              path: 'customerrepresentative/orders/work/:tab/:id',
              element: <ClientOrderDetailsViewPage />,
            },
            {
              path: 'customerrepresentative/orders/results/:id',
              element: <ClientOrderDetailsViewPage />,
            },
          ],
        },
        {
          path: 'fts-notifications',
          children: [
            {
              index: true,
              element: <FtsNotificationsPage />,
            },
            {
              path: ':id',
              element: <FtsNotificationPage />,
            },
          ],
        },
        {
          path: 'not-found',
          element: <NotFound />,
        },
        {
          path: '*',
          element: <Navigate to="/not-found" />,
        },
      ],
    },
  ];

  return routes;
}
