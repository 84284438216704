import { AxiosError, AxiosResponse } from 'axios';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { AgentService } from 'services/AgentService';
import { ClientPaymentRequestDto } from 'services/AgentService/types';
import { ClientPaymentRequestRejectCommandDto } from 'types/orders';
import { ClientOrderDetailsViewRejectFormProps } from './types';
import { FieldTextarea } from 'components/redesign/FieldTextArea';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { toast } from 'react-toastify';
import { BaseError } from 'types';

export const ClientOrderDetailsViewRejectForm: FC<ClientOrderDetailsViewRejectFormProps> = ({
  order,
  setActualOrder,
}) => {
  const dispatch = useAppDispatch();

  const { control, handleSubmit, watch } = useForm<{ comment: string | null }>({
    defaultValues: { comment: null },
  });

  const { mutateAsync: clientOrderDetailsViewReject } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    AxiosError<BaseError>,
    ClientPaymentRequestRejectCommandDto & { orderId: string }
  >({
    mutationKey: 'clientOrderDetailsViewReject',
    mutationFn: (
      rejectCommandWithOrderId: ClientPaymentRequestRejectCommandDto & { orderId: string }
    ) =>
      AgentService.clientOrderDetailsViewReject(rejectCommandWithOrderId.orderId, {
        rejectReasonComment: rejectCommandWithOrderId.rejectReasonComment,
        concurrencyStamp: rejectCommandWithOrderId.concurrencyStamp,
      }),
    onSuccess: ({ data }) => {
      dispatch(modalActions.closeModal());
      toast.success('Изменения сохранены');
      setActualOrder(data);
    },
    onError: (data) => {
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        clientOrderDetailsViewReject({
          orderId: order.id,
          rejectReasonComment: data.comment,
          concurrencyStamp: order.concurrencyStamp,
        });
      })}
    >
      <FieldTextarea name="comment" control={control} title="Комментарий" maxLength={1000} />
      <div className="z-10 mt-5 flex justify-end">
        <Button
          variant={ButtonVariant.GHOST}
          className="mr-5"
          type="button"
          onClick={() => dispatch(modalActions.closeModal())}
        >
          ОТМЕНИТЬ
        </Button>
        <Button variant={ButtonVariant.PRIMARY} disabled={!Boolean(watch('comment'))}>
          СОХРАНИТЬ
        </Button>
      </div>
    </form>
  );
};
