import { MonthValues } from 'const/types';
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';
import { ru } from 'date-fns/locale';

// https://github.com/hypeserver/react-date-range/issues/330#issuecomment-802601417
export const initialValueRange = {
  startDate: undefined,
  endDate: new Date(''),
  key: 'selection',
};

export const variants = {
  initial: {
    opacity: 0,
    marginTop: 0,
  },
  animate: {
    opacity: 1,
    marginTop: 10,
  },
};

export const filterMonthValues: MonthValues = {
  narrow: ['Я', 'Ф', 'М', 'А', 'М', 'И', 'И', 'А', 'С', 'О', 'Н', 'Д'],
  wide: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
};

export const editFilterRuLocale = () => {
  const buildMonths = buildLocalizeFn({
    values: filterMonthValues,
    defaultWidth: 'wide',
    formattingValues: filterMonthValues,
    defaultFormattingWidth: 'wide',
  });

  if (ru.localize) {
    ru.localize.month = buildMonths;
  }
  return ru;
};
