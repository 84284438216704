import { CashIcon } from 'assets/icons';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { AgentService } from 'services/AgentService';
import { DropDown, MenuItem } from 'ui/DropDown';
import { Spinner } from 'ui/Spinner';
import { ROUBLE_SIGN } from 'utils/settings';

export const BalanceInformerViewOrUpdate: FC = () => {
  const actions: MenuItem[] = [
    {
      title: 'Обновить баланс',
      action: () => {
        refetch();
        toast.warning('Обновление баланса...');
      },
    },
  ];

  const { isFetching, data, refetch } = useQuery(['getBalance'], () => AgentService.getBalance(), {
    onSuccess(data) {
      setBalanceIsLoaded(true);
      balanceIsLoaded && toast.success('Баланс успешно обновлен');
    },
  });

  const balance = data?.data;

  const [balanceIsLoaded, setBalanceIsLoaded] = useState<boolean>(false);

  return (
    <section className="mb-8 flex items-center rounded-[10px] p-5 shadow-chatPanel sm:max-w-[650px]">
      <CashIcon />
      {isFetching ? (
        <Spinner />
      ) : (
        <div className="ml-4 mr-8">
          <div>
            <span>Баланс: </span>
            <span className="body-bold">
              {String(balance?.amount).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ?? 0}&nbsp;
              {ROUBLE_SIGN}
            </span>
          </div>
          <div>
            <div>
              <span>Время обновления: </span>
              <span className="body-bold">
                {balance?.lastDateAmountUpdate &&
                  format(new Date(balance.lastDateAmountUpdate), 'dd.MM.yyyy, HH:mm')}
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="my-0 w-full max-w-[290px] rounded-[10px] bg-primary-60 text-white sm:max-w-[180px]">
        <DropDown buttonMode button="ДЕЙСТВИЕ" menuItems={actions} />
      </div>
    </section>
  );
};
