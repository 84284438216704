import { FileCommonIcon, ListIcon } from 'assets/icons';
import { FC } from 'react';
import { DocumentFilesEmptyBlockProps } from './types';

export const DocumentFileEmptyBlock: FC<DocumentFilesEmptyBlockProps> = ({ title }) => {
  return (
    <section className="flex flex-col gap-4 py-4 sm:max-w-[950px] sm:gap-6">
      <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
        <div>
          <ListIcon />
          <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">{title}</span>
        </div>
        <div className="mb-1 flex items-center justify-between rounded-[0.625rem] border border-light-50 p-3 last-of-type:mb-0">
          <div className="mr-2 self-center">
            <FileCommonIcon />
          </div>
          <div className="callout-normal flex grow flex-col text-ellipsis break-all !text-text-100 sm:text-xs md:text-base">
            <span>Файл еще не был сформирован</span>
          </div>
        </div>
        <div className="mb-1 text-text-50">Дата последнего формирования</div>
      </div>
    </section>
  );
};
