import { format } from 'date-fns';
import { GetOrdersBody } from '../../services/OrderService/types';
import { OrderTypesEnum } from './enums';
import { formatAmount } from '../../utils/helpers';
import { IconLightning, OrderNotificationIcon } from 'assets/icons';
import { OrderStatusForTable } from './OrderStatusForTable';
import { OrderStatusesEnum } from '../../enums/orderStatusesEnum';
import Customer from 'components/Customer';

const repeatingTableHeadings: TableHeading[] = [
  {
    attributeName: 'clientName',
    name: 'Заказчик',
    renderColumn: (column) => {
      return (
        <span className="flex w-max items-center gap-1">
          <Customer name={column as string} />
        </span>
      );
    },
  },
  {
    attributeName: 'customId',
    name: 'Заявка',
    renderColumn: (column) => <span className="whitespace-nowrap">№ {column as string}</span>,
  },
  { attributeName: 'name', name: 'Описание' },
];

const newOrdersTableHeadings: TableHeading[] = [
  ...repeatingTableHeadings,
  {
    attributeName: 'created',
    name: 'Дата создания',
    renderColumn: (column) => format(new Date(column as string), 'dd.MM.yyyy'),
  },
  {
    attributeName: 'estimatedCost',
    name: 'Стоимость',
    renderColumn: (column) => `${formatAmount(column as number, { minimumFractionDigits: 2 })} ₽`,
  },
  { attributeName: 'address', name: 'Адрес' },
  {
    attributeName: 'targetDate',
    name: 'Срок',
    renderColumn: (column) => {
      return (
        <span className="flex w-max items-center gap-1">
          <IconLightning className="text-[#FF992C]" />{' '}
          {format(new Date(column as string), 'dd.MM.yyyy')}{' '}
          {format(new Date(column as string), 'HH:mm')}
        </span>
      );
    },
  },
];

const inProgressOrdersTableHeadings: TableHeading[] = [
  ...repeatingTableHeadings,
  { attributeName: 'address', name: 'Адрес' },
  {
    attributeName: 'targetDate',
    name: 'Срок',
    renderColumn: (column) => {
      return (
        <span className="flex w-max items-center gap-1">
          <IconLightning className="text-[#FF992C]" />{' '}
          {format(new Date(column as string), 'dd.MM.yyyy')}
        </span>
      );
    },
  },
  {
    attributeName: 'created',
    name: 'Дата создания',
    renderColumn: (column) => format(new Date(column as string), 'dd.MM.yyyy'),
  },

  {
    attributeName: 'status',
    name: 'Статус',
    renderColumn: (column) => <OrderStatusForTable status={column as OrderStatusesEnum} />,
  },
  {
    attributeName: 'newChatMessages',
    name: 'Уведомление',
    renderColumn: (column) => <>{column ? <OrderNotificationIcon /> : null}</>,
  },
];

const completedOrdersTableHeadings: TableHeading[] = [
  ...repeatingTableHeadings,
  { attributeName: 'address', name: 'Адрес' },
  {
    attributeName: 'targetDate',
    name: 'Срок',
    renderColumn: (column) => {
      return (
        <span className="flex w-max items-center gap-1">
          <IconLightning className="text-[#FF992C]" />{' '}
          {format(new Date(column as string), 'dd.MM.yyyy')}
        </span>
      );
    },
  },
  {
    attributeName: 'status',
    name: 'Статус',
    renderColumn: (column) => <OrderStatusForTable status={column as OrderStatusesEnum} />,
  },
  {
    attributeName: 'newChatMessages',
    name: 'Уведомление',
    renderColumn: (column) => <>{column ? <OrderNotificationIcon /> : null}</>,
  },
  { attributeName: 'address', name: 'Адрес' },
];

const subcontractorInProgressOrdersTableHeadings: TableHeading[] = [
  {
    attributeName: 'customId',
    name: 'Заявка',
    renderColumn: (column) => <span className="whitespace-nowrap">№ {column as string}</span>,
  },
  {
    attributeName: 'clientName',
    name: 'Заказчик',
    renderColumn: (column) => {
      return (
        <span className="flex w-max items-center gap-1">
          <Customer name={column as string} />
        </span>
      );
    },
  },
  {
    attributeName: 'created',
    name: 'Дата создания',
    renderColumn: (column) => format(new Date(column as string), 'dd.MM.yyyy'),
  },
  { attributeName: 'address', name: 'Адрес' },
  {
    attributeName: 'targetDate',
    name: 'Целевая дата',
    renderColumn: (column) => {
      return (
        <span className="flex w-max items-center gap-1">
          {format(new Date(column as string), 'dd.MM.yyyy')}
        </span>
      );
    },
  },
  {
    attributeName: 'status',
    name: 'Статус',
    renderColumn: (column) => <OrderStatusForTable status={column as OrderStatusesEnum} />,
  },
  {
    attributeName: 'newChatMessages',
    name: 'Уведомление',
    renderColumn: (column) => <>{column ? <OrderNotificationIcon /> : null}</>,
  },
];

const subcontractorCompletedOrdersTableHeadings: TableHeading[] = [
  {
    attributeName: 'customId',
    name: 'Заявка',
    renderColumn: (column) => <span className="whitespace-nowrap">№ {column as string}</span>,
  },
  {
    attributeName: 'clientName',
    name: 'Заказчик',
    renderColumn: (column) => {
      return (
        <span className="flex w-max items-center gap-1">
          <Customer name={column as string} />
        </span>
      );
    },
  },
  {
    attributeName: 'created',
    name: 'Дата создания',
    renderColumn: (column) => format(new Date(column as string), 'dd.MM.yyyy'),
  },
  { attributeName: 'address', name: 'Адрес' },
  {
    attributeName: 'targetDate',
    name: 'Целевая дата',
    renderColumn: (column) => {
      return (
        <span className="flex w-max items-center gap-1">
          {format(new Date(column as string), 'dd.MM.yyyy')}
        </span>
      );
    },
  },
  {
    attributeName: 'status',
    name: 'Статус',
    renderColumn: (column) => <OrderStatusForTable status={column as OrderStatusesEnum} />,
  },
  {
    attributeName: 'newChatMessages',
    name: 'Уведомление',
    renderColumn: (column) => <>{column ? <OrderNotificationIcon /> : null}</>,
  },
];

export const ordersTableHeadings = {
  [OrderTypesEnum.new]: newOrdersTableHeadings,
  [OrderTypesEnum.inProgress]: inProgressOrdersTableHeadings,
  [OrderTypesEnum.completed]: completedOrdersTableHeadings,
};

export const subcontractorOrdersTableHeadings = {
  [OrderTypesEnum.new]: newOrdersTableHeadings,
  [OrderTypesEnum.inProgress]: subcontractorInProgressOrdersTableHeadings,
  [OrderTypesEnum.completed]: subcontractorCompletedOrdersTableHeadings,
};

export const initialOrderBody: Omit<GetOrdersBody, 'tab'> = {
  pageNumber: 1,
  pageSize: 250,
  searchParameters: {
    creationDateTimeStart: null,
    creationDateTimeEnd: null,
    clientIds: [],
    cities: [],
    customId: null,
    areDependentSettlementsIncluded: true,
  },
};

export const statusesData: Record<OrderStatusesEnum, { style: string; text: string }> = {
  [OrderStatusesEnum.ExecutorNotAssigned]: {
    text: 'Исполнитель не назначен',
    style: 'bg-primary-60',
  },
  [OrderStatusesEnum.DispatcherAssigned]: {
    text: 'Диспетчер назначен',
    style: '',
  },
  [OrderStatusesEnum.InProgress]: {
    text: 'В работе',
    style: 'bg-primary-60',
  },
  [OrderStatusesEnum.Refused]: {
    text: 'От заявки отказались',
    style: '',
  },
  [OrderStatusesEnum.Review]: {
    text: 'На проверке',
    style: 'bg-warning',
  },
  [OrderStatusesEnum.WorkApproved]: {
    text: 'Выполнена',
    style: 'bg-success-60',
  },
  [OrderStatusesEnum.Cancelled]: {
    text: 'Отменена',
    style: 'bg-danger',
  },
  [OrderStatusesEnum.CancelledWithPayment]: {
    text: 'Отменена с оплатой',
    style: 'bg-danger',
  },
  [OrderStatusesEnum.UnderInspection]: {
    text: 'Ожидает подтверждения',
    style: 'bg-warning',
  },
  [OrderStatusesEnum.Confirmed]: {
    text: 'Выполнена',
    style: 'bg-success-60',
  },
  [OrderStatusesEnum.Awaiting]: {
    text: 'Ожидает подтверждения',
    style: 'bg-warning',
  },
};
