import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export const oidcSettings: UserManagerSettings = {
  authority: process.env.REACT_APP_AUTHORITY ?? '',
  client_id: process.env.REACT_APP_CLIENT_ID ?? '',
  redirect_uri: process.env.REACT_APP_REDIRECT_URI ?? '',
  post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
  response_type: 'code',
  scope: process.env.REACT_APP_SCOPE,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
};

export const userManager = new UserManager(oidcSettings);

export const SMALL_DEVICE_MEDIA_QUERY = '(max-width: 767px)';
export const IS_SMALL_DEVICE = '(max-width: 640px)';
export const IS_MEDIUM_DEVICE = '(max-width: 1024px)';

export const UploadFileExtensions = {
  pdfAndImages: '.png, .jpg, .jpeg, .pdf',
  ogrn: '.png, .jpg, .jpeg, .pdf',
  ogrnip: '.png, .jpg, .jpeg, .pdf',
  egrul: '.pdf',
  egrip: '.pdf',
  inn: '.png, .jpg, .jpeg, .pdf',
  otherSubcontractorDocs: '.png, .jpg, .jpeg, .pdf',
};

export const YANDEX_API_KEY = '2519b112-9a6f-4c93-94cb-460aa4bdca90';

export const ROUBLE_SIGN = '\u20BD';

export enum StaticFiles {
  Agent_Instruction = 'Agent_Instruction',
  Platform_Instruction = 'Platform_Instruction',
  SelfEmployed_Contract_Offer = 'SelfEmployed_Contract_Offer',
  Agent_Contract_Offer = 'Agent_Contract_Offer',
  User_Agreement = 'User_Agreement',
}
