import { AxiosResponse } from 'axios';
import { Tabs } from 'components/redesign/Tabs';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { useQueries } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getPermissions } from 'reduxStore/reducers/ProfileSlice/selectors/getPermissions';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';
import { AgentService } from 'services/AgentService';
import { GetClientOrders } from 'services/AgentService/types';
import { Checkbox } from 'ui/redesign/Checkbox';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { Table } from 'ui/redesign/Table';
import { TableCard } from 'ui/redesign/Table/TableCard';
import { TableLoading } from 'ui/redesign/Table/TableLoading';
import { Spinner } from 'ui/Spinner';
import {
  accreditologOrdersTableHeadings,
  accreditologTabQueryKeys,
  bankManagerOrdersTableHeadings,
  bankManagerTabQueryKeys,
  clientTabs,
} from './consts';
import { ClientOrderTabsEnum } from './types';

export const ClientOrdersPage = () => {
  const navigate = useNavigate();
  const { tab = ClientOrderTabsEnum.all } = useParams<{ tab: ClientOrderTabsEnum }>();
  const permissions = useAppSelector(getPermissions);
  const isBankManager = permissions.includes(ProfilePermissions.Agent_Bank_Manager);
  const [isMine, setIsMine] = useState(isBankManager);

  const [ordersCount, setOrdersCount] = useState<Record<ClientOrderTabsEnum, number>>(
    {} as Record<ClientOrderTabsEnum, number>
  );
  const clientOrderTypes = Object.values(ClientOrderTabsEnum);
  const clientTabQueryKeys = isBankManager ? bankManagerTabQueryKeys : accreditologTabQueryKeys;

  const queryResults = useQueries(
    clientOrderTypes.map((tab, index) => ({
      queryKey: ['clientOrders', clientTabQueryKeys[tab], isMine],
      queryFn: () => AgentService.getClientOrders(clientTabQueryKeys[tab], isMine, 999),
      onSuccess: (data: AxiosResponse<GetClientOrders>) => {
        setOrdersCount((prevState) => ({
          ...prevState,
          [clientOrderTypes[index]]: data.data.totalCount,
        }));
      },
    }))
  );

  const isFetching = queryResults.some((query) => query.isFetching);

  const handleRowClick = (tableRow: TableData): void => {
    navigate(`/client/orders/${tab}/${tableRow.id}`);
  };

  const changeHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    setIsMine(value.target.checked);
  };

  useEffect(() => {
    const index = Object.values(ClientOrderTabsEnum).findIndex(
      (key) => ClientOrderTabsEnum[key] === tab
    );
    queryResults[index].refetch();
  }, [tab]);

  if (!Object.keys(ClientOrderTabsEnum).includes(tab)) return <Navigate to="/client/orders/all" />;

  return (
    <>
      {queryResults.map((query, index) => {
        if (clientOrderTypes[index] !== tab) {
          return;
        }
        const id = clientOrderTypes[index];
        const { data } = query;
        return (
          <PageWrapper title={'Работа с заявками'} key={id} isBankManager={isBankManager}>
            <section className="flex flex-col gap-6">
              {isFetching ? (
                <Spinner />
              ) : (
                <>
                  <Tabs
                    tabs={clientTabs}
                    ordersCounts={ordersCount}
                    isFetching={isFetching}
                    activeTab={tab}
                  />
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <Checkbox checked={isMine} onChange={changeHandler} />
                      <span className="body-medium -ml-3">МОИ ЗАЯВКИ</span>
                    </div>
                  </div>
                </>
              )}
              {isFetching ? (
                <TableLoading />
              ) : (
                <>
                  {data?.data.totalCount === 0 ? (
                    <div className="min-h-[90vh] overflow-hidden rounded-[10px] bg-base shadow-3xl">
                      <div className="max-w-[550px] sm:p-4">
                        {' '}
                        Данные по вкладке отсутствуют или не найдены совпадения по поисковому
                        запросу
                      </div>
                    </div>
                  ) : (
                    <>
                      <TableCard>
                        <Table
                          tableHeading={
                            isBankManager
                              ? bankManagerOrdersTableHeadings[tab]
                              : accreditologOrdersTableHeadings[tab]
                          }
                          tableData={(data?.data.items || []) as unknown as TableData[]}
                          onRowClick={handleRowClick}
                        />
                      </TableCard>
                    </>
                  )}
                </>
              )}
            </section>
          </PageWrapper>
        );
      })}
    </>
  );
};
