import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { ArrowBack, PlusIcon } from 'assets/icons';
import { AxiosError, AxiosResponse } from 'axios';
import classNames from 'classnames';
import { FieldDateRangePicker } from 'components/redesign/FieldDateRangePicker';
import { FieldInput } from 'components/redesign/FieldInput';
import { FieldSelect } from 'components/redesign/FieldSelect';
import { FieldTextarea } from 'components/redesign/FieldTextArea';
import { monthsMap } from 'const';
import { format } from 'date-fns';
import { useNavigationHistory } from 'navigationProvider';
import { CommonSettlementsResponse } from 'pages/Orders/OrdersFiltersModal/types';
import { FC, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AgentService } from 'services/AgentService';
import {
  ClientOrderDetailsFeesRequestBody,
  ClientPaymentRequestCalculateFeeQueryDto,
  ClientPaymentRequestCreateCommandDto,
  ClientPaymentRequestDto,
  ClientPaymentRequestSendForSignAfterReworkCommandDto,
  ClientPaymentRequestSendForSignCommandDto,
  ClientPaymentRequestUpdateCommandDto,
  GetOrganizationList,
  OrderDetailsStatusEnum,
} from 'services/AgentService/types';
import { BaseError, Settlement } from 'types';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { initialValueRange } from 'ui/redesign/DateRangePicker/constants';
import { OptionType } from 'ui/redesign/Select/types';
import { transformToSelectOptions } from 'utils/helpers';
import {
  checkContract,
  checkPeriod,
  checkValue,
  defaultContracts,
  findDuplicateNumbers,
  monthOptions,
  newContract,
  yearOptions,
} from './consts';
import { FieldContracts } from './FieldContracts';
import { ClientOrderCreateForm, ClientRequestHandler, ContractForm } from './types';
import { useAppSelector } from 'hooks/useAppSelector';
import { getPermissions } from 'reduxStore/reducers/ProfileSlice/selectors/getPermissions';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';

export const ClientOrderDetailsCreateOrUpdatePage: FC = () => {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const { history, removeLastRoute } = useNavigationHistory();
  const [cities, setCities] = useState<Settlement[]>([]);
  const [status, setStatus] = useState<OrderDetailsStatusEnum | null>(null);
  const [organizations, setOrganizations] = useState<OptionType[]>([]);
  const permissions = useAppSelector(getPermissions);
  const isAgentBankManager = permissions.includes(ProfilePermissions.Agent_Bank_Manager);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const {
    control,
    watch,
    setValue,
    getValues,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<ClientOrderCreateForm>({
    defaultValues: {
      cityName: undefined,
      month: undefined,
      year: undefined,
      agentOrganizationFullName: undefined,
      contracts: defaultContracts,
      description: undefined,
      period: initialValueRange,
      paymentAmount: '',
      concurrencyStamp: '',
      clientOrganizationFee: '',
      agentOrganizationFee: '',
    },
  });

  const handleGOBack = () => {
    if (history.length <= 1) {
      return;
    }
    navigate(-1);
    removeLastRoute();
    removeLastRoute();
  };

  const minDurationDays = 7;
  const maxDurationDays = 90;

  const getCities = async (template: string) => {
    const response = await request<CommonSettlementsResponse>(
      endpoints.GET_COMMON_SETTLEMENTS.type,
      endpoints.GET_COMMON_SETTLEMENTS.url(template)
    );
    if (response.status === 200) {
      setCities(response.data.pagedList);
    }
  };

  useQuery(['getOrganiztionList'], () => AgentService.getOrganizationList(), {
    onSuccess: (data: AxiosResponse<GetOrganizationList>) => {
      const list = data.data.items?.map((item) => ({
        value: item.id,
        label: item.fullName,
        tin: item.tin,
      }));
      list && setOrganizations(list);
    },
    onError: (data: AxiosError<BaseError>) => {
      setIsDataLoading(false);
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  const { mutateAsync: clientOrderDetailsFees } = useMutation<
    AxiosResponse<ClientPaymentRequestCalculateFeeQueryDto>,
    AxiosError<BaseError>,
    ClientOrderDetailsFeesRequestBody
  >({
    mutationKey: 'clientOrderDetailsFees',
    mutationFn: (body) => AgentService.clientOrderDetailsFees(body),
    onSuccess: ({ data }) => {
      data?.paymentAmount >= 0
        ? setValue(
            'paymentAmount',
            String(Math.trunc(data.paymentAmount)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          )
        : '';
      data?.agentOrganizationFee >= 0
        ? setValue(
            'agentOrganizationFee',
            String(data.agentOrganizationFee).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          )
        : '';
      data?.clientOrganizationFee >= 0
        ? setValue(
            'clientOrganizationFee',
            String(data.clientOrganizationFee).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          )
        : '';
    },
    onError: (data) => {
      setIsDataLoading(false);
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  const { data: clientOrder } = useQuery(
    ['getClientOrder'],
    () => AgentService.getClientOrder(id as string),
    {
      onSuccess: ({ data }: AxiosResponse<ClientPaymentRequestDto>) => {
        setValue('cityName', { value: data.cityFiasId, label: data.cityName });
        setValue('month', { value: data.month, label: monthsMap[data.month] });
        setValue('year', { value: data.year, label: data.year });
        setValue('period', {
          startDate: new Date(data.periodStartDate),
          endDate: new Date(data.periodEndDate),
        });
        setValue('agentOrganizationFullName', {
          label: data.agentOrganizationFullName,
          value: data.agentOrganizationId,
        });
        setValue('description', data.description ?? '');
        setValue('concurrencyStamp', data.concurrencyStamp ?? '');
        setValue(
          'contracts',
          data.contracts?.map((contract) => ({
            ...contract,
            product: { value: contract?.product?.id, label: contract?.product?.name },
          })) ?? []
        );
        setStatus(data.status);
        const creditParameters = data.contracts?.map((contract) => ({
          creditAmount: contract.creditAmount,
          rewardPercent: contract.rewardPercent,
        }));
        creditParameters && clientOrderDetailsFees({ creditParameters });
      },
      enabled: Boolean(id),
    }
  );

  const { mutateAsync: createClientPaymentRequest, data: clientPaymentRequestData } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    AxiosError<BaseError>,
    ClientPaymentRequestCreateCommandDto
  >({
    mutationKey: 'createClientPaymentRequest',
    mutationFn: (body) => AgentService.createClientPaymentRequest(body),
    onSuccess: ({ data }) => {
      setIsDataLoading(false);
      setValue('concurrencyStamp', data.concurrencyStamp ?? '');
      setStatus(data.status);
      navigate(`/client/orders/${tab}/${data.id}/update`);
      const creditParameters = data.contracts?.map((contract) => ({
        creditAmount: contract.creditAmount,
        rewardPercent: contract.rewardPercent,
      }));
      creditParameters && clientOrderDetailsFees({ creditParameters });
      toast.success('Черновик сохранен');
    },
    onError: (data) => {
      setIsDataLoading(false);
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  const { mutateAsync: updateClientPaymentRequest } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    AxiosError<BaseError>,
    { body: ClientPaymentRequestUpdateCommandDto; id: string }
  >({
    mutationKey: 'updateClientPaymentRequest',
    mutationFn: ({ id, body }) => AgentService.updateClientPaymentRequest(id ?? '', body),
    onSuccess: ({ data }) => {
      setIsDataLoading(false);
      setValue('concurrencyStamp', data.concurrencyStamp ?? '');
      setStatus(data.status);
      toast.success('Черновик обновлен');
    },
    onError: (data) => {
      setIsDataLoading(false);
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  const { mutateAsync: sendForSign } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    AxiosError<BaseError>,
    { body: ClientPaymentRequestSendForSignCommandDto; id: string }
  >({
    mutationKey: 'clientOrderSendForSign',
    mutationFn: ({ body, id }: { body: ClientPaymentRequestSendForSignCommandDto; id: string }) =>
      AgentService.clientOrderSendForSign(id, body),
    onSuccess: ({ data }) => {
      navigate(`/client/orders/${tab}/${data.id}`);
    },
    onError: (data) => {
      setIsDataLoading(false);
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  const { mutateAsync: sendForSignAfterRework } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    AxiosError<BaseError>,
    { body: ClientPaymentRequestSendForSignAfterReworkCommandDto; id: string }
  >({
    mutationKey: 'clientOrderSendForSignAfterRework',
    mutationFn: ({
      body,
      id,
    }: {
      body: ClientPaymentRequestSendForSignAfterReworkCommandDto;
      id: string;
    }) => AgentService.clientOrderSendForSignAfterRework(id, body),
    onSuccess: ({ data }) => {
      navigate(`/client/orders/${tab}/${data.id}`);
    },
    onError: (data) => {
      setIsDataLoading(false);
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  const customFilterOption = (candidate: OptionType, input: string) => {
    const inputLowerCase = input.toLowerCase();
    const labelMatches = candidate.label.toLowerCase().includes(inputLowerCase);
    const numberMatches = String(candidate.data.tin).includes(input);
    return labelMatches || numberMatches;
  };

  // TODO написать свой хук для получения и хранения списка городов
  useEffect(() => {
    getCities('');
  }, []);

  const onSubmit = async (request: ClientRequestHandler) => {
    clearErrors();
    const actualFormValues = getValues();
    const toastErrors: string[] = [];

    checkValue(actualFormValues.month as OptionType, 'month', setError, toastErrors);
    checkValue(actualFormValues.year as OptionType, 'year', setError, toastErrors);
    checkValue(actualFormValues.cityName as OptionType, 'cityName', setError, toastErrors);
    checkPeriod(actualFormValues.period, 'period', minDurationDays, setError, toastErrors);
    checkValue(
      actualFormValues.agentOrganizationFullName as OptionType,
      'agentOrganizationFullName',
      setError,
      toastErrors
    );
    if (actualFormValues.contracts?.length) {
      actualFormValues.contracts.map((item, index) => {
        checkContract(item as ContractForm, index, request, setError, toastErrors);
      });
      findDuplicateNumbers(actualFormValues.contracts as ContractForm[], setError, toastErrors);
    }

    if (toastErrors.length) return;
    setIsDataLoading(true);

    const requestData:
      | ClientPaymentRequestCreateCommandDto
      | ClientPaymentRequestUpdateCommandDto
      | ClientPaymentRequestSendForSignCommandDto
      | ClientPaymentRequestSendForSignAfterReworkCommandDto = {
      month: actualFormValues.month?.value,
      year: actualFormValues.year?.value,
      cityFiasId: actualFormValues.cityName?.value,
      periodStartDate: actualFormValues.period.startDate
        ? format(actualFormValues.period.startDate, 'yyyy-MM-dd')
        : null,
      periodEndDate: actualFormValues.period.endDate
        ? format(actualFormValues.period.endDate, 'yyyy-MM-dd')
        : null,
      agentOrganizationId: actualFormValues.agentOrganizationFullName?.value,
      description: actualFormValues.description ? actualFormValues.description : '',
      contracts: actualFormValues.contracts.map((contract) => {
        if (contract.creditAmount && typeof contract.creditAmount === 'string') {
          contract.creditAmount = contract.creditAmount.replace(/ /g, '');
        }
        return {
          id: contract.id,
          number: contract.number ?? '',
          clientFullName: contract.clientFullName ? contract.clientFullName : '',
          productId: contract.product.value,
          creditDate: contract.creditDate
            ? format(new Date(contract.creditDate), 'yyyy-MM-dd')
            : null,
          creditAmount: contract.creditAmount ? Number(contract.creditAmount) : 0,
          rewardPercent: contract.rewardPercent ? Number(contract.rewardPercent) : 0,
        };
      }),
    };

    if (!id) {
      if (request === ClientRequestHandler.SENDFORSIGN) {
        const response = await createClientPaymentRequest(requestData);
        const rd = response?.data;
        requestData.contracts = requestData.contracts.map((contract, index) => ({
          ...contract,
          id: rd?.contracts ? rd?.contracts[index].id : 0,
        }));
        await sendForSign({
          body: {
            ...requestData,
            concurrencyStamp: rd?.concurrencyStamp,
          } as ClientPaymentRequestSendForSignCommandDto,
          id: rd?.id ?? '',
        });
      } else {
        await createClientPaymentRequest(requestData);
      }
    } else {
      const requestDataWithStamp = {
        ...requestData,
        concurrencyStamp: actualFormValues.concurrencyStamp ?? '',
      };
      if (request === ClientRequestHandler.DRAFT) {
        await updateClientPaymentRequest({
          id: id,
          body: requestDataWithStamp as ClientPaymentRequestUpdateCommandDto,
        });
      } else if (status === OrderDetailsStatusEnum.Draft) {
        await sendForSign({
          body: requestDataWithStamp as ClientPaymentRequestSendForSignCommandDto,
          id: id,
        });
      } else if (status === OrderDetailsStatusEnum.ForRework) {
        await sendForSignAfterRework({
          body: requestDataWithStamp as ClientPaymentRequestSendForSignAfterReworkCommandDto,
          id: id,
        });
      }
    }
  };

  const {
    fields: contractFields,
    append: contractAppend,
    remove: contractRemove,
  } = useFieldArray({
    control,
    name: 'contracts',
  });

  const contractAppendHandler = () => {
    contractAppend(newContract);
  };

  return (
    <section className="flex flex-col gap-4 sm:max-w-[950px] sm:gap-6">
      <div className="flex items-center">
        <div>
          <ArrowBack
            className={classNames('mr-5 h-5 w-5', {
              '!cursor-not-allowed': history.length <= 1,
            })}
            onClick={handleGOBack}
          />
        </div>
        <span className="title2-medium sm:title2-bold lg:large-title2-bold mt-1">
          Создание заявки на оплату
        </span>
      </div>
      <form>
        <div className="mb-8 gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
          <FieldSelect
            name="cityName"
            control={control}
            variant="filter"
            label="Город"
            isClearable
            options={transformToSelectOptions<Settlement>(
              cities,
              'objectFullName',
              'fiasId',
              watch('cityName')
            )}
            onInputChange={(newValue: string) => {
              if (newValue.length < 3) {
                return;
              }
              getCities(newValue);
            }}
            className="!border-b border-light-50"
            errorMessage={errors?.cityName?.message}
            clearErrors={clearErrors}
          />
          <FieldSelect
            options={monthOptions}
            name="month"
            control={control}
            label="Месяц"
            className="!border-b border-light-50"
            isClearable
            errorMessage={errors?.month?.message}
            clearErrors={clearErrors}
          />
          <FieldSelect
            options={yearOptions}
            name="year"
            control={control}
            label="Год"
            className="!border-b border-light-50"
            isClearable
            errorMessage={errors?.year?.message}
            clearErrors={clearErrors}
          />
          <FieldDateRangePicker
            name="period"
            control={control}
            label="Период (неделя)"
            minDurationDays={minDurationDays}
            maxDurationDays={maxDurationDays}
            errorMessage={(errors.period as Range & { message: string })?.message}
            clearErrors={clearErrors}
          />
          <FieldSelect
            name="agentOrganizationFullName"
            control={control}
            label="Исполнитель"
            className="!border-b border-light-50"
            isClearable
            placeholder="Введите ФИО или ИНН"
            options={organizations}
            filterOption={customFilterOption}
            errorMessage={errors?.agentOrganizationFullName?.message}
            clearErrors={clearErrors}
            isDisabled={
              clientOrder?.data.status === OrderDetailsStatusEnum.ForRework && isAgentBankManager
            }
          />
          <FieldInput
            name={'paymentAmount'}
            control={control}
            label="Вознаграждение (итоговое)"
            className={classNames(
              'headline-normal border-0 border-b-2 border-solid border-light-50 text-text-100'
            )}
            readOnly
          />
          <FieldInput
            name={'clientOrganizationFee'}
            control={control}
            label="Комиссия с клиента, руб"
            className={classNames(
              'headline-normal border-0 border-b-2 border-solid border-light-50 text-text-100'
            )}
            errorMessage={errors?.paymentAmount?.message}
            readOnly
          />
          <FieldInput
            name={'agentOrganizationFee'}
            control={control}
            label="Комиссия с самозанятого, руб"
            className={classNames(
              'headline-normal border-0 border-b-2 border-solid border-light-50 text-text-100'
            )}
            readOnly
          />
        </div>
        <FieldTextarea
          name="description"
          control={control}
          maxLength={1500}
          label="Описание"
          isClearable
          errorMessage={errors?.description?.message}
        />
        <FieldContracts
          control={control}
          contracts={contractFields}
          contractRemove={contractRemove}
          watch={watch}
          setValue={setValue}
          errors={errors}
          clearErrors={clearErrors}
          clientOrderDetailsFees={clientOrderDetailsFees}
        />
        <div className="mb-6 flex justify-end">
          <Button
            className="callout-bold h-10 py-0 !text-white"
            type="button"
            onClick={contractAppendHandler}
            disabled={isDataLoading}
          >
            ДОБАВИТЬ
            <PlusIcon className="ml-3 -mt-1" />
          </Button>
        </div>

        <div className="flex gap-6">
          <Button
            type="button"
            onClick={() => onSubmit(ClientRequestHandler.SENDFORSIGN)}
            disabled={isDataLoading}
          >
            ОТПРАВИТЬ НА ПОДПИСАНИЕ
          </Button>
          <Button
            variant={ButtonVariant.SUCCESS}
            type="button"
            disabled={isDataLoading}
            onClick={() => onSubmit(ClientRequestHandler.DRAFT)}
          >
            СОХРАНИТЬ ЧЕРНОВИК
          </Button>
        </div>
      </form>
    </section>
  );
};
