import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { FieldInputProps } from './types';
import { Input } from 'ui/redesign/Input';
import { toast } from 'react-toastify';

export const FieldInput: FC<FieldInputProps> = ({
  name,
  control,
  rules,
  label,
  errorMessage,
  onChange,
  clearErrors,
  maxLength,
  type,
  ...other
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const onChangeHandle = (value?: React.ChangeEvent<HTMLInputElement>) => {
          const actualValue = value?.target.value;
          if (
            type === 'number' &&
            actualValue === '0' &&
            (name === 'certificateElectricalSafetyQuantity' ||
              name === 'certificateWorkHeightQuantity')
          ) {
            toast.error('Количество сотрудников не может быть равно 0 и превышать 9999');
            return;
          }
          if (type === 'number' && actualValue && !/^[0-9]+$/.test(actualValue)) {
            return;
          }
          if (maxLength && actualValue && actualValue?.length > maxLength) {
            return;
          } else {
            field.onChange(actualValue);
            onChange && onChange(actualValue);
            clearErrors && clearErrors(name);
          }
        };
        return (
          <Input
            label={label}
            errorMessage={errorMessage}
            value={field.value}
            onChange={onChangeHandle}
            maxLength={maxLength}
            {...other}
          />
        );
      }}
    />
  );
};
