import { FC } from 'react';
import { InfoBlockProps } from './types';
import { IconAttention } from 'assets/icons';
import classNames from 'classnames';
import { InfoBlockVariant, infoBlockVariants } from './constants';

export const InfoBlock: FC<InfoBlockProps> = ({
  text,
  description,
  className,
  variant = InfoBlockVariant.PRIMARY,
}) => {
  return (
    <div className={classNames(className, infoBlockVariants[variant], 'rounded-[10px] px-4 py-5')}>
      <div className="callout-bold sm:body-bold grid grid-cols-info-attention-block items-center gap-2">
        <IconAttention
          className={classNames('text-primary-60', {
            'text-danger': variant === InfoBlockVariant.ERROR,
          })}
        />
        {text}
      </div>
      <div className="callout-normal mt-2">{description}</div>
    </div>
  );
};
