import { ExecutorTypeEnum } from 'enums/executorType';
import { UseFormSetError } from 'react-hook-form';

export const checkName = (
  name: string | null,
  field: string,
  fieldRus: string,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const pattern = /^(?!.*[ \-']{2})(?!.*([А-Яа-яЁё])\1{2})[А-Яа-яЁё][А-Яа-яЁё',\- ]*[А-Яа-яЁё]$/;
  const nameErrorMessage = `${fieldRus} содержать не менее 2 и не более 200 символов`;
  const namePatternErrorMessage = `Допустимые символы для ввода [А-Я а-я ' -] без тройных повторов, минимальная длина 2 символа`;

  if (!name) {
    setError(field, {
      type: 'manual',
      message: nameErrorMessage,
    });
    toastErrors.push(nameErrorMessage);
  } else {
    name = name?.trim();
    if (name.length < 2) {
      setError(field, {
        type: 'manual',
        message: nameErrorMessage,
      });
      toastErrors.push(nameErrorMessage);
    }
    if (!pattern.test(name)) {
      setError(field, {
        type: 'manual',
        message: namePatternErrorMessage,
      });
      toastErrors.push(namePatternErrorMessage);
    }
    if (name.length === 2 && name[0].toLocaleLowerCase() === name[1].toLocaleLowerCase()) {
      setError(field, {
        type: 'manual',
        message: namePatternErrorMessage,
      });
      toastErrors.push(namePatternErrorMessage);
    }
  }
  return toastErrors;
};

export const compareNames = (
  name: string | null,
  ftsName: string | null,
  field: string,
  fieldName: string,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (ftsName === null) return toastErrors;
  const errorMessage = `${fieldName} в ФНС: ${ftsName}`;
  if (name !== ftsName) {
    setError(field, {
      type: 'manual',
      message: errorMessage,
    });
    toastErrors.push(errorMessage);
  }
  return toastErrors;
};

export const checkBirthDate = (
  birthDay: string | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const birthDayErrorMessage = 'Дата рождения должна быть заполнена';
  if (!birthDay) {
    setError('birthDate', {
      type: 'manual',
      message: birthDayErrorMessage,
    });
    toastErrors.push(birthDayErrorMessage);
  }
  return toastErrors;
};

export const checkInn = (
  inn: string | null,
  // actualExecutorType: ExecutorTypeEnum,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  // const setSelfemployedInnError = () => {
  //   const SelfemployedErrorMessage = 'Введите валидный ИНН. ИНН должен состоять из 10 цифр';
  //   setError('inn', {
  //     type: 'manual',
  //     message: SelfemployedErrorMessage,
  //   });
  //   toastErrors.push(SelfemployedErrorMessage);
  // };
  const setIndividualInnError = () => {
    const ipErrorMessage = 'Введите валидный ИНН. ИНН должен состоять из 12 цифр';
    setError('inn', {
      type: 'manual',
      message: ipErrorMessage,
    });
    toastErrors.push(ipErrorMessage);
  };

  if (inn === null) {
    setIndividualInnError();
  } else {
    inn = inn.replace(/_/g, '');
    if (inn.length < 12) {
      setIndividualInnError();
    }
    const innNumbers = inn.split('').map(Number);
    if (
      !(
        innNumbers[10] ===
          ((7 * innNumbers[0] +
            2 * innNumbers[1] +
            4 * innNumbers[2] +
            10 * innNumbers[3] +
            3 * innNumbers[4] +
            5 * innNumbers[5] +
            9 * innNumbers[6] +
            4 * innNumbers[7] +
            6 * innNumbers[8] +
            8 * innNumbers[9]) %
            11) %
            10 &&
        innNumbers[11] ===
          ((3 * innNumbers[0] +
            7 * innNumbers[1] +
            2 * innNumbers[2] +
            4 * innNumbers[3] +
            10 * innNumbers[4] +
            3 * innNumbers[5] +
            5 * innNumbers[6] +
            9 * innNumbers[7] +
            4 * innNumbers[8] +
            6 * innNumbers[9] +
            8 * innNumbers[10]) %
            11) %
            10
      )
    ) {
      setIndividualInnError();
    }
  }
  return toastErrors;
};
