import { AttachOrder, NewsIcon, ServicesIcon, WritingIcon } from 'assets/icons';
import { format } from 'date-fns';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AgentService } from 'services/AgentService';
import { OrderReceiptStatusEnum } from 'services/AgentService/types';
import { FileNew } from 'ui/redesign/FileNew';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { StatusIcon } from '../consts';
import { cancellationReasons, styles } from './consts';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ConfirmReceiptForm } from '../ConfirmReceiptForm';
import { Spinner } from 'ui/Spinner';

export const AgentReceiptDetailsPage: FC = () => {
  const { id: receiptId } = useParams();
  const dispatch = useAppDispatch();
  const { data, isFetching } = useQuery(['getAgentFtsReceipt'], () =>
    AgentService.getAgentFtsReceipt(receiptId ? receiptId : '')
  );

  const receipt = data?.data;

  const handleModal = (id: string) => {
    dispatch(
      modalActions.handleOpenModal({
        content: <ConfirmReceiptForm id={id} refetchPathId="ReceiptDetails" />,
        options: {
          title: 'Подтвердите действие',
          modalClassName: 'w-full',
        },
      })
    );
  };

  if (isFetching) {
    return (
      <PageWrapper title="Чек">
        <Spinner />
      </PageWrapper>
    );
  }

  if (!receipt) {
    return null;
  }

  return (
    <PageWrapper title="Чек">
      <section className="flex flex-col gap-4 py-4 sm:max-w-[950px] sm:gap-6">
        <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
          <div>
            <WritingIcon />
            <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">
              Сведения об операции
            </span>
          </div>
          <div className="flex flex-col justify-between gap-4 sm:flex-row">
            <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
              <p className={styles.label}>Дата оплаты</p>
              {format(new Date(receipt.purchaseTime), 'dd.MM.yyyy HH:mm')}
            </div>
            <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
              <p className={styles.label}>Идентификатор чека</p>
              <p className="pt-[6px]">{receipt.externalId}</p>
            </div>
          </div>
          {receipt.status === OrderReceiptStatusEnum.Canceled && (
            <div className="flex flex-col justify-between gap-4 sm:flex-row">
              <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
                <p className={styles.label}>Дата и время аннулирования чека</p>
                <p className="pt-[6px]">
                  {receipt.cancellationTime &&
                    format(new Date(receipt.cancellationTime), 'dd.MM.yyyy HH:mm')}
                </p>
              </div>
              <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
                <p className={styles.label}>Причина аннулирования чека</p>
                <p className="pt-[6px]">{cancellationReasons[receipt.reasonCancellation]}</p>
              </div>
            </div>
          )}
          {receipt.status === OrderReceiptStatusEnum.NotRegistered && (
            <div className="flex flex-col justify-between gap-4 sm:flex-row">
              <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
                <p className={styles.label}>Причина ошибки</p>
                <p className="pt-[6px]">{receipt.reasonNotFiscalizeIncomeName}</p>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
          <div>
            <NewsIcon />
            <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">Чек</span>
          </div>
          <div className="flex w-full justify-between border-b border-[#E4E4E5] pb-1">
            <p className={styles.label}>Статус</p>
            <StatusIcon status={receipt.status} />
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1">
            <p className={styles.label}>Ссылка на чек</p>
            {(receipt.status === OrderReceiptStatusEnum.Actual ||
              receipt.status === OrderReceiptStatusEnum.Canceled ||
              receipt.status === OrderReceiptStatusEnum.Offline) && (
              <p className="pt-[6px]">
                <a
                  className="cursor-pointer font-montserrat font-semibold text-blue"
                  href={receipt.linkReceiptFts ?? ''}
                >
                  {receipt.linkReceiptFts}
                </a>
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
          <div>
            <ServicesIcon />
            <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">Услуги</span>
          </div>
          <div className="flex flex-col justify-between gap-4">
            {receipt.services.map((service, index) => {
              return (
                <div key={index} className="flex gap-8">
                  <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
                    <p className={styles.label}>Услуга</p>
                    <p className="pt-[6px]">{service.name}</p>
                  </div>
                  <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
                    <p className={styles.label}>Сумма</p>
                    <p className="pt-[6px]">{service.cost}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Итоговая сумма</p>
            <p className="pt-[6px]">{receipt.totalAmount}</p>
          </div>
        </div>

        {(receipt.status === OrderReceiptStatusEnum.Actual ||
          receipt.status === OrderReceiptStatusEnum.Canceled ||
          receipt.status === OrderReceiptStatusEnum.Offline) && (
          <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
            <div className="flex justify-between">
              <div>
                <AttachOrder />
                <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">
                  Вложения
                </span>
              </div>
              {receipt.status === OrderReceiptStatusEnum.Actual && (
                <div
                  className="body-paragraphs-normal cursor-pointer !text-text-50 underline"
                  onClick={() => handleModal(receipt.id)}
                >
                  Аннулировать чек
                </div>
              )}
            </div>
            {receipt.files && receipt.files.length !== 0 && (
              <ul className="flex flex-col gap-2">
                {receipt.files?.map((file) => (
                  <li key={file.readLink} className="w-full max-w-[600px]">
                    <FileNew file={file} isHideDeleteFileButton={true} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </section>
    </PageWrapper>
  );
};
