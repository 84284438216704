import { useQuery } from 'react-query';
import { FtsOrganizationService } from 'services/FtsOrganizationService';
import { useAppDispatch } from './useAppDispatch';
import { profileActions } from 'reduxStore/reducers/ProfileSlice';
import { ProfileSpecialization } from 'reduxStore/reducers/ProfileSlice/types';
import { useEffect } from 'react';

export const useGetFtsProfile = (executorSpecializationIds: ProfileSpecialization[] | null) => {
  const dispatch = useAppDispatch();

  useQuery(['getFtsProfile'], () => FtsOrganizationService.getFtsProfile(), {
    onSuccess: async ({ data }) => {
      dispatch(profileActions.setFtsProfile(data));
    },
    enabled: executorSpecializationIds?.includes(5),
  });

  useEffect(() => {
    !executorSpecializationIds?.includes(5) &&
      dispatch(
        profileActions.setFtsProfile({
          ftsFirstName: null,
          ftsMiddleName: null,
          ftsLastName: null,
          ftsPhoneNumber: null,
          bonusAmount: 0,
          unpaidAmount: 0,
          debtAmount: 0,
          ftsStatus: undefined,
        })
      );
  }, [executorSpecializationIds]);
};