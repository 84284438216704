import { useQuery } from 'react-query';
import { AgentService } from 'services/AgentService';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { Table } from 'ui/redesign/Table';
import { TableCard } from 'ui/redesign/Table/TableCard';
import { TableLoading } from 'ui/redesign/Table/TableLoading';
import { customerRepresentativeResultsTableHeadings } from './consts';
import { useNavigate } from 'react-router-dom';

export const CustomerRepresentativeResultsPage = () => {
  const navigate = useNavigate();
  const { data, isFetching } = useQuery(['getClientOrdersResults'], () =>
    AgentService.getClientOrders(11, false, 999)
  );

  const handleRowClick = (tableRow: TableData): void => {
    navigate(`/client/customerrepresentative/orders/results/${tableRow.id}`);
  };

  return (
    <PageWrapper title="Результаты выплат">
      <section className="flex flex-col gap-6">
        {isFetching ? (
          <TableLoading />
        ) : (
          <>
            {data?.data.totalCount === 0 ? (
              <div className="min-h-[90vh] overflow-hidden rounded-[10px] bg-base shadow-3xl">
                <div className="max-w-[550px] sm:p-4">
                  {' '}
                  Данные по вкладке отсутствуют или не найдены совпадения по поисковому запросу
                </div>
              </div>
            ) : (
              <>
                <TableCard>
                  <Table
                    tableHeading={customerRepresentativeResultsTableHeadings}
                    tableData={(data?.data.items || []) as unknown as TableData[]}
                    onRowClick={handleRowClick}
                  />
                </TableCard>
              </>
            )}
          </>
        )}
      </section>
    </PageWrapper>
  );
};
