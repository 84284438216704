import { useAppDispatch } from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { AlertBannerBlock } from '../AlertBannerBlock';
import { showBannerForAgent } from './consts';
import { AlertBannerDataType, AlertBannerProps } from './types';

export const AlertBanner = ({
  status,
  unpaidAmount,
  debtAmount,
  bonusAmount,
}: AlertBannerProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="mb-6">
      {showBannerForAgent({
        status,
        unpaidAmount,
        debtAmount,
        bonusAmount,
        navigate,
        dispatch,
      }).map((banner: AlertBannerDataType, index: number) => {
        if (!banner.title) {
          return;
        }
        return (
          <AlertBannerBlock
            key={index}
            className={banner.className}
            icon={banner.icon}
            title={banner?.title()}
            description={banner.description && banner.description()}
            buttonName={banner.button}
            onClick={banner.onClick}
          />
        );
      })}
    </div>
  );
};
