import { AxiosError, AxiosResponse } from 'axios';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { AgentService } from 'services/AgentService';
import { ClientPaymentRequestDto } from 'services/AgentService/types';
import { ClientOrderDetailsViewSuccessFormProps } from './types';
import { toast } from 'react-toastify';
import { FieldCheckbox } from 'components/redesign/FieldCheckbox';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { BaseError } from 'types';

export const ClientOrderDetailsViewSuccessForm: FC<ClientOrderDetailsViewSuccessFormProps> = ({
  order,
  setActualOrder,
}) => {
  const dispatch = useAppDispatch();

  const { control, clearErrors, watch, handleSubmit } = useForm<{ isAgree: boolean }>({
    defaultValues: { isAgree: false },
  });

  const { mutateAsync: clientOrderDetailsViewSuccess } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    AxiosError<BaseError>,
    { orderId: string; concurrencyStamp: string }
  >({
    mutationKey: 'clientOrderDetailsViewSuccess',
    mutationFn: ({ orderId, concurrencyStamp }) =>
      AgentService.clientOrderDetailsViewSuccess(orderId, concurrencyStamp),
    onSuccess: ({ data }) => {
      dispatch(modalActions.closeModal());
      setActualOrder(data);
    },
    onError: (data) => {
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        data.isAgree &&
          order.concurrencyStamp &&
          clientOrderDetailsViewSuccess({
            orderId: order.id,
            concurrencyStamp: order.concurrencyStamp,
          });
      })}
    >
      <FieldCheckbox
        name="isAgree"
        control={control}
        clearErrors={clearErrors}
        title="С настоящим отчет-актом согласен"
      />
      <div className="z-10 flex justify-end">
        <Button
          variant={ButtonVariant.GHOST}
          className="mr-5"
          type="button"
          onClick={() => dispatch(modalActions.closeModal())}
        >
          ОТМЕНИТЬ
        </Button>
        <Button variant={ButtonVariant.PRIMARY} disabled={!watch('isAgree')}>
          СОХРАНИТЬ
        </Button>
      </div>
    </form>
  );
};
