import { AxiosError, AxiosResponse } from 'axios';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FC } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ftsNotificationsActions } from 'reduxStore/reducers/FtsNotificationsSlice';
import { getPermissions } from 'reduxStore/reducers/ProfileSlice/selectors/getPermissions';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';
import { AgentService } from 'services/AgentService';
import { MarkManyAsAcknowledgedCommandDto } from 'services/AgentService/types';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { Spinner } from 'ui/Spinner';
import { FieldFtsNotifications } from './FieldFtsNotifications';
import { FtsNotificationsFormValues } from './types';
import { InfoCard } from 'components/InfoCard';
import NoNotifications from 'assets/images/noNotifications.png';
import { BaseError } from 'types';
import { toast } from 'react-toastify';

export const FtsNotificationsPage: FC = () => {
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(getPermissions);
  const queryClient = useQueryClient();

  const { control, setValue, handleSubmit } = useForm<FtsNotificationsFormValues>({
    defaultValues: {
      notifications: [],
    },
  });

  const { isFetching, refetch } = useQuery(
    ['getFtsNotifications'],
    () => AgentService.getFtsNotifications(),
    {
      onSuccess: ({ data }) => {
        queryClient.refetchQueries('getFtsNotificationsCount');
        dispatch(ftsNotificationsActions.setFtsNotifications(data.items ?? []));
        setValue(
          'notifications',
          data.items?.map((item) => ({
            ...item,
            notificationId: item.id,
            isChecked: item.status === 1 ? false : true,
          })) ?? []
        );
      },
      onError: (data: AxiosError<BaseError>) => {
        data.response?.data.errors
          ? Object.values(data.response?.data.errors).forEach((errors) =>
              errors.forEach((message) => toast.error(message))
            )
          : toast.error(data.response?.data.detail);
      },
      enabled: permissions.includes(ProfilePermissions.Agent_PaymentRequest_ReadExecutor),
    }
  );

  const { fields: notifications } = useFieldArray({
    control,
    name: 'notifications',
  });

  const onSubmit: SubmitHandler<FtsNotificationsFormValues> = (data) => {
    const requestData = {
      notificationIds: data.notifications
        .filter((notification) => notification.isChecked)
        .map((notification) => notification.notificationId),
    };
    readFtsNotifications(requestData);
  };

  const { mutateAsync: readFtsNotifications } = useMutation<
    AxiosResponse<'Success'>,
    unknown,
    MarkManyAsAcknowledgedCommandDto
  >({
    mutationKey: 'readFtsNotifications',
    mutationFn: (body) => AgentService.readFtsNotifications(body),
    onSuccess() {
      refetch();
    },
  });

  const noNoticationsData = {
    id: 1,
    img: NoNotifications,
    text: 'Нет ни одного уведомления',
    description: 'Когда уведомления появятся, они будут отображаться в данном разделе',
  };

  return (
    <PageWrapper title="Уведомления ФНС">
      <section className="flex flex-col">
        <div className="flex flex-col">
          <section className="flex flex-col gap-4 py-4 sm:max-w-[950px] sm:gap-6">
            {isFetching ? (
              <Spinner />
            ) : notifications.length ? (
              <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FieldFtsNotifications notifications={notifications} />
                </form>
              </div>
            ) : (
              <div className="flex max-w-[700px] flex-col justify-between gap-4 rounded-[10px] shadow-3xl">
                <InfoCard infoItem={noNoticationsData} />
              </div>
            )}
          </section>
        </div>
      </section>
    </PageWrapper>
  );
};
