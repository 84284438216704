import { FC } from 'react';
import { DocumentFilesBlockProps } from './types';
import { ListIcon } from 'assets/icons';
import { FileNew } from 'ui/redesign/FileNew';
import { format } from 'date-fns';

export const DocumentFileBlock: FC<DocumentFilesBlockProps> = ({ title, file, creationTime }) => {
  return (
    <section className="flex flex-col gap-4 py-4 sm:max-w-[950px] sm:gap-6">
      <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
        <div>
          <ListIcon />
          <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">{title}</span>
        </div>
        <FileNew file={file} isHideDeleteFileButton={true} />
        <div className="mb-1 flex justify-between">
          <div className="text-text-50">Дата последнего формирования</div>
          <div>{format(new Date(creationTime), 'dd.MM.yyyy')}</div>
        </div>
      </div>
    </section>
  );
};
