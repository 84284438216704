import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useFirebase } from 'hooks/useFirebase';
import { useNavigationHistory } from 'navigationProvider';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useRoutes } from 'react-router-dom';
import { profileActions } from 'reduxStore/reducers/ProfileSlice';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';
import { createRouter } from 'routes';
import { ProfileService } from 'services/ProfileService';
import { useOrderRoute } from './hooks/useOrderRoute';
import { userManager } from './utils/settings';
import { AuthProvider } from 'react-oidc-context';
import { AuthProviderRouting } from 'components/AuthProviderRouting';
import { getAccessToken } from 'utils/helpers';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';

function App() {
  const { clearHistory } = useNavigationHistory();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const executorType = useAppSelector(getExecutorType);
  const routing = useRoutes(createRouter(executorType));
  const [isPermissionsLoaded, setIsPermissionsLoaded] = useState(false);
  const queryClient = useQueryClient();

  const { mainUrl } = useOrderRoute();

  const { refetch } = useQuery(['getProfile'], () => ProfileService.getProfile(), {
    onSuccess: async (data) => {
      dispatch(profileActions.setProfile(data.data));
      setIsPermissionsLoaded(true);
    },
    enabled: false,
  });

  const signInHandler = async () => {
    try {
      clearHistory();
      await getPermissions();
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const onSignIn = async () => {
    const timer = setInterval(() => {
      const token = getAccessToken();
      if (token) {
        signInHandler();
        clearInterval(timer);
      }
    }, 300);
  };

  // Получение разрешений пользователя
  const getPermissions = async () => {
    const response = await request<number[]>(
      endpoints.GET_USER_PERMISSIONS.type,
      endpoints.GET_USER_PERMISSIONS.url()
    );
    if (response.status === 200) {
      dispatch(profileActions.setPermissions(response.data));
      if (response.data.includes(ProfilePermissions.Agent_PaymentRequest_ReadExecutor)) {
        queryClient.refetchQueries('getFtsNotificationsCount');
      }
    }
  };

  useFirebase();

  useEffect(() => {
    isPermissionsLoaded && navigate(mainUrl);
  }, [isPermissionsLoaded, mainUrl]);

  return (
    <AuthProvider userManager={userManager} onSigninCallback={onSignIn}>
      <AuthProviderRouting>{routing}</AuthProviderRouting>
    </AuthProvider>
  );
}
export default App;
