import { AxiosResponse } from 'axios';
import { AlertBanner } from 'components/redesign/AlertBanner';
import { Tabs } from 'components/redesign/Tabs';
import { useAppSelector } from 'hooks/useAppSelector';
import { useGetFtsProfile } from 'hooks/useGetFtsProfile';
import { useEffect, useState } from 'react';
import { useQueries } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getExecutorSpecializationIds } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorSpecializationIds';
import { getFtsProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getFtsProfile';
import { AgentService } from 'services/AgentService';
import { GetAgentOrders } from 'services/OrderService/types';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { Table } from 'ui/redesign/Table';
import { TableCard } from 'ui/redesign/Table/TableCard';
import { TableLoading } from 'ui/redesign/Table/TableLoading';
import { Spinner } from 'ui/Spinner';
import { agentOrdersTableHeadings, agentTabs } from './const';
import { AgentOrderTabsEnum } from './types';

export const AgentsOrdersPage = () => {
  const executorSpecializationIds = useAppSelector(getExecutorSpecializationIds);
  useGetFtsProfile(executorSpecializationIds);
  const ftsProfile = useAppSelector(getFtsProfile);

  const navigate = useNavigate();
  const { tab = AgentOrderTabsEnum.inProgress } = useParams<{ tab: AgentOrderTabsEnum }>();
  const [ordersCount, setOrdersCount] = useState<Record<AgentOrderTabsEnum, number>>(
    {} as Record<AgentOrderTabsEnum, number>
  );
  const agentOrderTypes = Object.values(AgentOrderTabsEnum);

  const queryResults = useQueries(
    agentOrderTypes.map((_, tab) => ({
      queryKey: ['data', tab + 1],
      queryFn: () => AgentService.getAgentOrders(tab + 1, 0, 999),
      onSuccess: (data: AxiosResponse<GetAgentOrders>) => {
        setOrdersCount((prevState) => ({
          ...prevState,
          [agentOrderTypes[tab]]: data.data.totalCount,
        }));
      },
    }))
  );

  const isFetching = queryResults.some((query) => query.isFetching);

  const handleRowClick = (tableRow: TableData): void => {
    navigate(`/agent/orders/${tab}/${tableRow.id}`);
  };

  useEffect(() => {
    const index = Object.values(AgentOrderTabsEnum).findIndex(
      (key) => AgentOrderTabsEnum[key] === tab
    );
    queryResults[index].refetch();
  }, [tab]);

  if (!Object.keys(AgentOrderTabsEnum).includes(tab))
    return <Navigate to="/agent/orders/inProgress" />;

  return (
    <>
      {queryResults.map((query, index) => {
        if (agentOrderTypes[index] !== tab) {
          return;
        }
        const id = agentOrderTypes[index];
        const { data } = query;

        return (
          <PageWrapper title="Заявки" key={id}>
            {ftsProfile && (
              <AlertBanner
                status={ftsProfile.ftsStatus}
                unpaidAmount={ftsProfile.unpaidAmount}
                debtAmount={ftsProfile.debtAmount}
                bonusAmount={ftsProfile.bonusAmount}
              />
            )}
            <section className="flex flex-col gap-6">
              {isFetching ? (
                <Spinner />
              ) : (
                <Tabs
                  tabs={agentTabs}
                  ordersCounts={ordersCount}
                  isFetching={isFetching}
                  activeTab={tab}
                />
              )}
              {isFetching ? (
                <TableLoading />
              ) : (
                <>
                  {data?.data.totalCount === 0 ? (
                    <div className="min-h-[90vh] overflow-hidden rounded-[10px] bg-base shadow-3xl">
                      <div className="max-w-[550px] sm:p-4">
                        {' '}
                        Данные по вкладке отсутствуют или не найдены совпадения по поисковому
                        запросу
                      </div>
                    </div>
                  ) : (
                    <>
                      <TableCard>
                        <Table
                          tableHeading={agentOrdersTableHeadings.inProgress}
                          tableData={(data?.data.items || []) as unknown as TableData[]}
                          onRowClick={handleRowClick}
                        />
                      </TableCard>
                    </>
                  )}
                </>
              )}
            </section>
          </PageWrapper>
        );
      })}
    </>
  );
};
