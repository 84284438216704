/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as DropdownArrowIcon } from 'assets/dropdown-arrow.svg';
import { FileDownloadIcon, FilePreviewIcon } from 'assets/icons';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { Modal } from 'ui/Modal';
import { FilePreview } from 'ui/redesign/FilePreview';
import { classNames, fetchFile, handleFileDownload } from 'utils/helpers';

export interface MenuItem {
  title: string;
  action: () => void;
}

interface DropDownProps {
  button: ReactNode;
  buttonMode?: boolean;
  file?: OrderFile;
  menuItems: MenuItem[];
}
export function DropDown({ button, menuItems, buttonMode, file }: DropDownProps) {
  const [currentFile, setCurrentFile] = useState<{ content: string; type: string } | null>(null);
  const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const configButtons = {
    menu: 'relative inline-block text-left max-w-[288px] self-center w-full',
    wrapBtn: 'text-center flex justify-center',
    button: 'btn-dark w-full py-4 body-paragraphs-bold',
    items:
      'origin-top-right absolute bottom-14 mt-2 z-10 left-0 rounded-lg shadow-3xl cursor-pointer focus:outline-none',
    wrapItems: 'overflow-hidden bg-white z-0 relative text-black',
    text: `block px-2 py-2 whitespace-nowrap hover:bg-light-30 text-body-normal`,
    icon: <DropdownArrowIcon className="absolute right-5 top-1/2 -translate-y-1/2" width={13} />,
  };

  const configDefaults = {
    menu: 'inline-block text-left relative',
    wrapBtn: '',
    button: '',
    items:
      'origin-top-right absolute right-4 bottom-2 mt-2 w-32 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
    wrapItems: 'py-1',
    text: `block px-2 py-2 text-sm whitespace-nowrap hover:bg-light-30`,
    icon: null,
  };

  const config = buttonMode ? configButtons : configDefaults;

  const handleFilePreview = async (callback: Promise<AxiosResponse>) => {
    const response = await callback;
    const file = response.data;
    setCurrentFile({
      content: file.content,
      type: file.contentType,
    });
  };

  useEffect(() => {
    currentFile &&
      dispatch(
        modalActions.handleOpenModal({
          content: <FilePreview file={currentFile} />,
          options: {
            title: 'Просмотр файла',
          },
        })
      );
  }, [currentFile]);

  return (
    <>
      {currentFile && fileModalOpen && (
        <Modal
          open={fileModalOpen}
          onClose={() => setFileModalOpen(false)}
          modalClasses="flex flex-col lg:max-w-max h-max p-0"
          label="Просмотр"
        >
          <FilePreview file={currentFile} />
        </Modal>
      )}
      <Menu as="div" className={config.menu}>
        <div className={config.wrapBtn}>
          <Menu.Button className={config.button}>
            {button}
            {config.icon}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-175"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {file ? (
            <Menu.Items className={config.items}>
              <div>
                <Menu.Item>
                  {({ active }) => (
                    <p
                      className={classNames('cursor-pointer text-text-100', config.text)}
                      onClick={() =>
                        file.downloadFileIdHash &&
                        handleFilePreview(fetchFile(file.downloadFileIdHash))
                      }
                    >
                      <FilePreviewIcon className="mr-2 text-text-100" />
                      Просмотр
                    </p>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <p
                      className={classNames('cursor-pointer text-text-100', config.text)}
                      onClick={() =>
                        file.downloadFileIdHash &&
                        handleFileDownload(fetchFile(file.downloadFileIdHash))
                      }
                    >
                      <FileDownloadIcon className="mr-2 cursor-pointer text-primary-60" />
                      Скачать
                    </p>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          ) : (
            <Menu.Items className={config.items}>
              <div className={classNames('py-2', config.wrapItems)}>
                {menuItems.map((item) => (
                  <Menu.Item key={item.title}>
                    {() => (
                      <p
                        className={classNames('py-2 px-3 text-text-100', config.text)}
                        onClick={item.action}
                      >
                        {item.title}
                      </p>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          )}
        </Transition>
      </Menu>
    </>
  );
}
