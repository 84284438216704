import classNames from 'classnames';
import { FC } from 'react';
import { OrderDetailsStatusEnum } from 'services/AgentService/types';
import { OrderTableStatuses } from 'utils/helpers';

export const AgentOrClientOrderStatusForTable: FC<{ status: OrderDetailsStatusEnum }> = ({
  status,
}) => {
  return (
    <span
      className={classNames(
        'block w-max rounded-[4px] px-2 py-[1px] text-sm text-white',
        'bg-' + OrderTableStatuses[status].color
      )}
    >
      {OrderTableStatuses[status].label
        ? OrderTableStatuses[status].label
        : OrderTableStatuses[status].name}
    </span>
  );
};
