import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { useAppSelector } from 'hooks/useAppSelector';
import { FC, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFtsNotifications } from 'reduxStore/reducers/FtsNotificationsSlice/selectors';
import { AgentService } from 'services/AgentService';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { PageWrapper } from 'ui/redesign/PageWrapper';

export const FtsNotificationPage: FC = () => {
  const { id } = useParams();
  const notifications = useAppSelector(getFtsNotifications);
  const notification = notifications.find((notification) => notification.id === id);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: readFtsNotification } = useMutation<
    AxiosResponse<'Success'>,
    unknown,
    string
  >({
    mutationKey: 'readFtsNotification',
    mutationFn: (id) => AgentService.readFtsNotification(id),
    onSuccess() {
      queryClient.invalidateQueries('getFtsNotificationsCount').then(() => {
        queryClient.refetchQueries('getFtsNotificationsCount');
      });
      toast.success('Уведомление ФНС прочитано');
    },
  });

  useEffect(() => {
    notification && notification.status === 1 && readFtsNotification(notification.id);
  }, [notification]);

  const handleGOBack = () => {
    if (history.length <= 1) {
      return;
    }
    navigate(-1);
    removeLastRoute();
    removeLastRoute();
  };

  if (!notification) {
    return null;
  }

  return (
    <PageWrapper title="Уведомления ФНС">
      <section className="flex h-[80vh] flex-col justify-between">
        <div className="flex flex-col">
          <section className="flex flex-col gap-4 py-4 sm:max-w-[950px] sm:gap-6">
            <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
              <p>ФНС России. {format(new Date(notification.creationTime), 'dd.MM.yyyy HH:mm')}</p>
              <p className="font-bold">{notification.title}</p>
              <p>{notification.content}</p>
            </div>
          </section>
        </div>
        <Button variant={ButtonVariant.OUTLINE} onClick={handleGOBack}>
          ВЕРНУТЬСЯ НАЗАД
        </Button>
      </section>
    </PageWrapper>
  );
};
function removeLastRoute() {
  throw new Error('Function not implemented.');
}

