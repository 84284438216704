import { MobileIcon } from 'assets/icons';
import { useAppSelector } from 'hooks/useAppSelector';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { getModerationRequestData } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestData';
import { Step5Fields } from './Step5Fields';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { EExecutorSpecialization } from 'enums/executorSpecialization';

export const Step5 = () => {
  const { status, executorSpecializations } = useAppSelector(getModerationRequestData);
  const navigate = useNavigate();
  const { created } = useAppSelector(getProfile);
  const { pathname } = useLocation();
  const step = pathname.split('step')[1];

  const onSubmit = (event: any) => {
    event.preventDefault();

    const nativeEvent = event?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter as HTMLButtonElement;
    if (submitter.name === 'forward') {
      const nextStep = +step + 1;
      executorSpecializations && !executorSpecializations.includes(EExecutorSpecialization.Agent)
        ? navigate(`/moderation-request/step${nextStep}`)
        : navigate(`/moderation-request/final-step`);
    } else if (submitter.name === 'back') {
      const prevStep = +step - 1;
      prevStep
        ? prevStep && navigate(`/moderation-request/step${prevStep}`)
        : navigate(`/moderation-request`);
    }
  };

  return (
    <FormWrapper title="Контактные данные" status={status} created={created} Icon={MobileIcon}>
      <form onSubmit={onSubmit} className="flex min-h-[65vh] flex-col justify-between">
        <Step5Fields />
        <FormControl />
      </form>
    </FormWrapper>
  );
};
