import { OrderNotificationIcon } from 'assets/icons';
import classNames from 'classnames';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldFtsNotificationsProps } from './types';

export const FieldFtsNotifications: FC<FieldFtsNotificationsProps> = ({ notifications }) => {
  let notificationDateCurrent: string | null = null;
  const navigate = useNavigate();
  return (
    <>
      {notifications.map((notification, index) => {
        const notificationDate = format(new Date(notification.creationTime), 'dd.MM.yyyy');
        const notificationDateElement = notificationDateCurrent !== notificationDate && (
          <div className="mb-1 text-text-50">{notificationDate}</div>
        );
        notificationDateCurrent = notificationDate;

        return (
          <React.Fragment key={index}>
            {notificationDateElement}
            <div
              className={classNames(
                'mb-2 flex items-center justify-start border-b-[1px] border-light-50 pb-4',
                { 'font-bold': !notification.isChecked }
              )}
            >
              <div
                className="mr-[auto] cursor-pointer"
                onClick={() => navigate(`/fts-notifications/${notification.notificationId}`)}
              >
                {notification.title}
              </div>
              <div className="flex flex-col gap-1">
                {format(new Date(notification.creationTime), 'HH:mm')}
                {!notification.isChecked && <OrderNotificationIcon />}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};
