import { FC } from 'react';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { CreateAgentReceiptForm } from './CreateAgentReceiptForm';

export const CreateAgentReceiptPage: FC = () => {
  return (
    <PageWrapper title="Формирование чека">
      <CreateAgentReceiptForm />
    </PageWrapper>
  );
};
