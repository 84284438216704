import { Profile } from 'services/ProfileService/types';
import OrderDetailsForSubOrExPage from './OrderDetailsForSubOrEx';
import { AgentOrderDetailsPage } from './OrderDetailsForAgent';
import {
  LocalizedOrderDetailsBannerTextsEnum,
  LocalizedOrderReceiptStatusNameEnum,
  OrderDetailsBannerTextsEnum,
} from 'services/AgentService/types';
import { AlertBannerBlock } from 'components/redesign/AlertBannerBlock';
import { CanceledIcon, CreatingIcon, ExclamationIcon, OfflineIcon } from 'assets/icons';

export const getOrderDetails = (userProfile: Profile) => {
  if (userProfile.executorType === 1 && userProfile.executorSpecializationIds?.includes(5)) {
    return <AgentOrderDetailsPage />;
  } else {
    return <OrderDetailsForSubOrExPage />;
  }
};

export const getClientOrderDetailsBannerTexts = (
  value: OrderDetailsBannerTextsEnum
): { title: string; description: string } | null => {
  switch (value) {
    case OrderDetailsBannerTextsEnum.UntiedFromTheSite:
      return {
        title: 'Ошибка формирования чека',
        description: 'Причина: Исполнитель отвязался от площадки',
      };

    case OrderDetailsBannerTextsEnum.Deregistered:
      return {
        title: 'Ошибка формирования чека',
        description: 'Причина: Исполнитель снялся с учета в ФНС',
      };

    case OrderDetailsBannerTextsEnum.ProhibitionOfIncomeRegistration:
      return {
        title: 'Ошибка формирования чека',
        description: 'Причина: Налоговым органом наложен запрет на регистрацию дохода исполнителем',
      };

    case OrderDetailsBannerTextsEnum.IncomeLimitReached:
      return {
        title: 'Ошибка формирования чека',
        description: 'Причина: Исполнителем достигнут лимит по доходу в 2,4 млн руб.',
      };

    case OrderDetailsBannerTextsEnum.Other:
      return {
        title: 'Ошибка формирования чека',
        description: 'Причина: Техническая ошибка формирования чека. Обратитесь в тех. поддержку.',
      };

    default:
      return null;
  }
};

export const getAgentOrderDetailsBannerTexts = (
  value: LocalizedOrderDetailsBannerTextsEnum
): { title: string; description: string } | null => {
  switch (value) {
    case LocalizedOrderDetailsBannerTextsEnum.UntiedFromTheSite:
      return {
        title: 'Ошибка формирования чека',
        description: 'Причина: Отсутствует доступ к фискализации доходов от вашего имени',
      };

    case LocalizedOrderDetailsBannerTextsEnum.Deregistered:
      return {
        title: 'Ошибка формирования чека',
        description: 'Причина: Отсутствует статус самозанятости',
      };

    case LocalizedOrderDetailsBannerTextsEnum.ProhibitionOfIncomeRegistration:
      return {
        title: 'Ошибка формирования чека',
        description: 'Причина: Налоговым органом наложен запрет на регистрацию дохода',
      };

    case LocalizedOrderDetailsBannerTextsEnum.IncomeLimitReached:
      return {
        title: 'Ошибка формирования чека',
        description: 'Причина: Вами достигнут лимит по доходу в 2,4 млн руб.',
      };

    case LocalizedOrderDetailsBannerTextsEnum.Other:
      return {
        title: 'Ошибка формирования чека',
        description: 'Причина: Техническая ошибка формирования чека. Обратитесь в тех. поддержку.',
      };

    default:
      return null;
  }
};


const notFiscalizeReasons: { [key: string]: string } = {
  'Самозанятый отвязался от площадки': 'Причина: Исполнитель отвязался от площадки',
  'Самозанятый снялся с учета': 'Причина: Исполнитель снялся с учета в ФНС',
  'Налоговый орган наложил запрет на регистрацию дохода':
    'Причина: Налоговым органом наложен запрет на регистрацию дохода исполнителем',
  'Самозанятый достиг максимального уровня дохода':
    'Причина: Исполнителем достигнут лимит по доходу в 2,4 млн руб.',
  'Техническая ошибка формирования чека':
    'Причина: Техническая ошибка формирования чека. Обратитесь в тех. поддержку.',
};

export const getCustomerRepresentativeBanner = (
  statusName: LocalizedOrderReceiptStatusNameEnum,
  reasonNotFiscalizeIncomeName?: string | null
): JSX.Element | null => {
  switch (statusName) {
    case LocalizedOrderReceiptStatusNameEnum.Canceled:
      return (
        <AlertBannerBlock
          title={<label className="!text-danger">Чек анулирован</label>}
          icon={<CanceledIcon />}
        />
      );
    case LocalizedOrderReceiptStatusNameEnum.New:
      return (
        <AlertBannerBlock
          title={<label className="!text-warning">Чек создается</label>}
          icon={<CreatingIcon className="!text-warning" />}
          className="!bg-attention-10"
        />
      );
    case LocalizedOrderReceiptStatusNameEnum.Actual:
      return (
        <AlertBannerBlock
          title={<label className="!text-success-80">Чек актуален</label>}
          icon={<CreatingIcon className="!text-success-80" />}
          className="!bg-success-10"
        />
      );
    case LocalizedOrderReceiptStatusNameEnum.Offline:
      return (
        <AlertBannerBlock
          title={<label className="!text-warning">Чек оффлайн</label>}
          icon={<OfflineIcon />}
          className="!bg-attention-10"
        />
      );
    case LocalizedOrderReceiptStatusNameEnum.NotRegistered:
      return (
        <AlertBannerBlock
          title={<label className="!text-danger">Ошибка формирования чека</label>}
          description={
            <span>
              {reasonNotFiscalizeIncomeName && notFiscalizeReasons[reasonNotFiscalizeIncomeName]}
            </span>
          }
          icon={<ExclamationIcon className="mt-[2px]" />}
          className="!bg-[#FFEFF0]"
        />
      );
    default:
      return null;
  }
};