import { UseFormSetError } from 'react-hook-form';

export const checkPaymentOperationTime = (
  paymentOperationTime: Date,
  orderCreationTime: Date,
  setError: UseFormSetError<any>,
  toastErrors: string[]
) => {
  const orderCreationTimeUnix = Date.parse(
    `${orderCreationTime.getFullYear()}-${
      orderCreationTime.getMonth() + 1
    }-${orderCreationTime.getDate()}T00:00:00.000Z`
  );

  const paymentOperationTimeUnix = Date.parse(
    `${paymentOperationTime.getFullYear()}-${
      paymentOperationTime.getMonth() + 1
    }-${paymentOperationTime.getDate()}T00:00:00.000Z`
  );

  let errorMessage = '';
  const errorDateExpiredMessage = 'Выставляемая дата не должна быть раньше даты создания заявки';

  if (paymentOperationTimeUnix - orderCreationTimeUnix < 0) {
    errorMessage = errorDateExpiredMessage;
  }

  if (errorMessage) {
    setError('paymentOperationTime', {
      type: 'manual',
      message: errorMessage,
    });
    toastErrors.push(errorMessage);
  }
};
