import { AxiosError, AxiosResponse } from 'axios';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { AgentService } from 'services/AgentService';
import { AgentReceiptDto, CancelReceiptBody, ReceiptError } from 'services/AgentService/types';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { Select } from 'ui/redesign/Select';
import { cancelReceiptOptions } from './consts';
import { CancelReceiptFormProps } from './types';

export const CancelReceiptForm: FC<CancelReceiptFormProps> = ({ id, refetchPathId }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [reasonCancellation, setReasonCancellation] = useState(cancelReceiptOptions[0]);

  const { data: agentOrder } = useQuery(['getAgentFtsReceipt'], () =>
    AgentService.getAgentFtsReceipt(id)
  );

  const { mutateAsync: cancelReceipt } = useMutation<
    AxiosResponse<AgentReceiptDto>,
    AxiosError<ReceiptError>,
    { body: CancelReceiptBody; id: string }
  >({
    mutationKey: 'cancelReceipt',
    mutationFn: ({ body, id }) => AgentService.cancelReceipt(id, body),
    onSuccess: () => {
      dispatch(modalActions.closeModal());
      switch (refetchPathId) {
        case 'ReceiptList':
          queryClient.refetchQueries('getFtsReceiptList');
          break;
        case 'ReceiptDetails':
          queryClient.refetchQueries('getAgentFtsReceipt');
          break;
      }
    },
    onError: (data) => {
      // TODO При получении в ответе ошибки вернуть пользователю алерт (логика обработки ошибок в задаче MAYKOR-4251):
      toast.error(data?.response?.data.detail);
    },
  });

  const cancelHandler = () => {
    dispatch(modalActions.closeModal());
  };

  const confirmHandler = () => {
    const data = {
      body: {
        reasonCancellation: +reasonCancellation.value,
        concurrencyStamp: agentOrder?.data.concurrencyStamp ?? '',
      },
      id: id,
    };
    agentOrder?.data.concurrencyStamp && cancelReceipt(data);
  };

  return (
    <div className="max-h-[500px] w-full overflow-hidden p-4 sm:min-w-[500px] sm:p-0">
      <div className="mt-6">
        <Select
          label="Причина аннулирования"
          options={cancelReceiptOptions}
          onChange={(value) => setReasonCancellation(value as OptionModel)}
          value={reasonCancellation}
          isSearchable={false}
        />
      </div>
      <div className="mt-6 flex flex-col-reverse gap-6 sm:flex-row sm:justify-end">
        <Button
          variant={ButtonVariant.GHOST}
          onClick={cancelHandler}
          className="paragraphs-bold mb-1 w-[130px] border border-solid border-text-50 !text-text-100"
        >
          ОТМЕНА
        </Button>
        <Button className="paragraphs-bold mb-1 w-[210px]" onClick={confirmHandler}>
          АННУЛИРОВАТЬ
        </Button>
      </div>
    </div>
  );
};
