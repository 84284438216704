import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { FieldSelectProps } from './types';
import { Select } from 'ui/redesign/Select';
import { MultiValue, SingleValue } from 'react-select';
import { OptionType } from 'ui/redesign/Select/types';

export const FieldSelect: FC<FieldSelectProps> = ({
  name,
  control,
  rules,
  label,
  errorMessage,
  onChange,
  clearErrors,
  ...other
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const onChangeHandle = (value?: MultiValue<OptionType> | SingleValue<OptionType>) => {
          field.onChange(value);
          onChange && onChange(value as any);
          clearErrors && clearErrors(name);
        };
        return (
          <Select
            label={label}
            errorMessage={errorMessage}
            value={field.value}
            onChange={onChangeHandle}
            {...other}
          />
        );
      }}
    />
  );
};
