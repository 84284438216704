import { AlertBanner } from 'components/redesign/AlertBanner';
import { useAppSelector } from 'hooks/useAppSelector';
import { useGetFtsProfile } from 'hooks/useGetFtsProfile';
import { useState } from 'react';
import { getExecutorSpecializationIds } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorSpecializationIds';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';
import { getFtsProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getFtsProfile';
import TabGroup from 'ui/TabGroup';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { ExecutorReceipts } from './ExecutorReceipts';

export const Receipts = () => {
  const executorType = useAppSelector(getExecutorType);
  const ftsProfile = useAppSelector(getFtsProfile);
  const executorSpecializationIds = useAppSelector(getExecutorSpecializationIds);

  useGetFtsProfile(executorSpecializationIds);

  //const tabItems = [{ title: 'Чеки по периодам' }, { title: 'Чеки по сумме' }];
  const tabItems = [{ title: 'Чеки по сумме' }];
  // const moderationTabContent = [
  //   <StyledRecieptsByPeriod key="tab1" />,
  //   <ExecutorReceipts key="tab2" />,
  // ];
  const moderationTabContent = [<ExecutorReceipts key="tab1" />];
  const [selectedIndex, setSelectedIndex] = useState(0);

  return !executorType || executorType === 1 ? (
    <PageWrapper title="Чеки" isGoBack={false}>
      {ftsProfile && (
        <AlertBanner
          status={ftsProfile.ftsStatus}
          unpaidAmount={ftsProfile.unpaidAmount}
          debtAmount={ftsProfile.debtAmount}
          bonusAmount={ftsProfile.bonusAmount}
        />
      )}
      <TabGroup
        tabItems={tabItems}
        tabPanelsContent={moderationTabContent}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
    </PageWrapper>
  ) : (
    <PageWrapper title="Чеки">
      {ftsProfile && (
        <AlertBanner
          status={ftsProfile.ftsStatus}
          unpaidAmount={ftsProfile.unpaidAmount}
          debtAmount={ftsProfile.debtAmount}
          bonusAmount={ftsProfile.bonusAmount}
        />
      )}
      <ExecutorReceipts />
    </PageWrapper>
  );
};
