import { request } from 'api';
import { FtsOrganizationProfileDto, FtsStatusEnum } from './types';

export const FtsOrganizationService = {
  bind() {
    return request<{ ftsStatus: FtsStatusEnum }>('patch', '/api/FtsOrganization/Binding/Bind');
  },
  getFtsProfile() {
    return request<FtsOrganizationProfileDto>('get', '/api/FtsOrganization/FtsProfile');
  },
};
