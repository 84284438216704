import { AlertBanner } from 'components/redesign/AlertBanner';
import { useAppSelector } from 'hooks/useAppSelector';
import { useGetFtsProfile } from 'hooks/useGetFtsProfile';
import { FC } from 'react';
import { getExecutorSpecializationIds } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorSpecializationIds';
import { getFtsProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getFtsProfile';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { Slider } from './Slider';
import './styles.css';

const Help: FC = () => {
  const ftsProfile = useAppSelector(getFtsProfile);
  const executorSpecializationIds = useAppSelector(getExecutorSpecializationIds);

  useGetFtsProfile(executorSpecializationIds);

  return (
    <PageWrapper title="Добро пожаловать">
      {ftsProfile && (
        <AlertBanner
          status={ftsProfile.ftsStatus}
          unpaidAmount={ftsProfile.unpaidAmount}
          debtAmount={ftsProfile.debtAmount}
          bonusAmount={ftsProfile.bonusAmount}
        />
      )}
      <Slider />
    </PageWrapper>
  );
};

export default Help;
