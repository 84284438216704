import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ChevronIcon } from 'assets/icons';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Input } from '../Input';
import { Option } from './Option';
import { CustomSelectProps } from './types';
import { ValidationError } from '../ValidationError';

export const CustomSelect = ({
  value,
  options,
  name,
  label,
  onChange,
  errorMessage,
  disabled,
}: CustomSelectProps) => {
  const actualValue = useMemo(() => value ?? [], [value]) as number[];
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (actualValue) {
      const _names: string[] = [];
      actualValue.forEach((item) => {
        _names.push(getItem(item)!.label);
      });
      setSelectedNames(_names);
    }
  }, [actualValue]);

  const handleOnClick = () => setIsOpen((prev) => !prev);

  const handleConfirmClick = () => {
    setIsOpen(false);
  };

  const getItem = (id: number) => options.find((item) => item.value === id);

  const handleOnChange = (id: number) => {
    let _selectedValues = actualValue;
    let names = selectedNames;

    const hasValue = actualValue.find((value) => value === id);

    if (hasValue) {
      _selectedValues = _selectedValues.filter((value) => value !== id);
      names = names.filter((value) => value !== getItem(hasValue)!.label);
    } else {
      // Получаем массив выбранных значений
      _selectedValues = [..._selectedValues, id];
      // Получаем "значение" для отображения в поле
      names = [...names, getItem(id)!.label];
    }
    onChange && onChange(_selectedValues);
    setSelectedNames(names);
  };

  return (
    <div className="relative">
      {label && <div className="body-normal !text-text-50">{label}</div>}
      <div onClick={handleOnClick} className={`relative ${disabled ? 'pointer-events-none' : ''}`}>
        <Input
          name={name}
          value={selectedNames.join(', ') || ''}
          className={classNames(
            'headline-normal cursor-pointer truncate border-0 border-b-2 border-solid border-light-50 py-1.5 pr-8',
            {
              'text-text-50': disabled,
              'border-primary-60': isOpen,
              'border-danger': errorMessage,
            }
          )}
          readOnly
          disabled={disabled}
          isOpen={isOpen}
          icons={[
            {
              icon: (
                <CloseIcon
                  className={classNames('mb-1 -ml-12 h-3 w-3 text-text-50')}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange && onChange(null);
                    setSelectedNames([]);
                  }}
                />
              ),
            },
            {
              icon: (
                <ChevronIcon
                  className={classNames('mb-1 -ml-5 fill-text-50', {
                    'rotate-180': isOpen,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOnClick();
                  }}
                />
              ),
            },
          ]}
        />
      </div>
      {errorMessage && <ValidationError errorMessage={errorMessage} />}
      {isOpen && (
        <>
          <div className="absolute top-[68px] z-30 mt-1 w-full overflow-hidden rounded-lg bg-white shadow-xl ">
            {options.map(({ value, label, disabled }) => {
              return (
                <Option
                  key={value}
                  id={value}
                  label={label}
                  value={value}
                  onChange={(id) => handleOnChange(id)}
                  active={actualValue.includes(value)}
                  disabled={disabled}
                />
              );
            })}
          </div>
          <div className="fixed top-0 left-0 z-10 h-full w-full" onClick={handleConfirmClick} />
        </>
      )}
    </div>
  );
};
