import { AttachOrder, MobileIcon, SettingsIcon, TelephoneIcon, UserIcon } from 'assets/icons';

export interface ProfileLink {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  link: string;
}

export const profileLinks: ProfileLink[] = [
  { link: '/profile/private', title: 'Личные данные', Icon: UserIcon },
  { link: '/profile/contacts', title: 'Контактные данные', Icon: MobileIcon },
  { link: '/profile/documents', title: 'Документы', Icon: AttachOrder },
  { link: '/profile/settings', title: 'Настройки', Icon: SettingsIcon },
  { link: '/profile/support', title: 'Техническая поддержка', Icon: TelephoneIcon },
];
