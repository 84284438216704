import { FileMetadataDto } from "types";
import { ClientPaymentRequestReceiptDto } from 'types/orders';

export interface GetClientOrders {
  items: ClientOrder[] | null;
  totalCount: number;
}

export interface ClientOrder {
  id: string;
  creationTime: string;
  creatorId: string;
  creatorFullName: string | null;
  number: number;
  status: number;
  statusName: string | null;
  statusUpdateTime: string;
  cityFiasId: string | null;
  cityName: string | null;
  agentOrganizationId: string;
  agentOrganizationFullName: string | null;
  ftsStatusName: string | null;
}

export interface GetOrganizationList {
  totalCount: number;
  items: Organization[] | null;
}

export interface ClientPaymentRequestCalculateFeeQueryDto {
  agentOrganizationFee: number;
  clientOrganizationFee: number;
  paymentAmount: number;
}

export interface ClientOrderDetailsFeesRequestBody {
  creditParameters: CreditParameters[] | null;
}

export interface CreditParameters {
  creditAmount: number;
  rewardPercent: number;
}

export interface Organization {
  id: string;
  fullName: string | null;
  tin: string | null;
  ftsStatus: string | null;
}

export interface GetProductNames {
  totalCount: number;
  items: Product[] | null;
}

export interface ClientPaymentRequestDto {
  id: string;
  number: number;
  creationTime: string;
  status: number;
  statusUpdateTime: string;
  month: number;
  year: number;
  cityFiasId: string | null;
  cityName: string | null;
  periodStartDate: string;
  periodEndDate: string;
  paymentAmount: number;
  description: string | null;
  rejectReasonComment: string | null;
  concurrencyStamp: string | null;
  managerFullName: string | null;
  accreditationEmployeeFullName: string | null;
  agentOrganizationId: string;
  agentOrganizationFullName: string | null;
  ftsStatusName: string | null;
  contracts: ClientContractDto[] | null;
  files: FileMetadataDto[] | null;
  actions: number[] | null;
  clientOrganizationFee: number;
  agentOrganizationFee: number;
  receipt: ClientPaymentRequestReceiptDto | null;
}

export interface ClientPaymentRequestCreateCommandDto {
  cityFiasId: string;
  month: number;
  year: number;
  periodStartDate: string | null;
  periodEndDate: string | null;
  agentOrganizationId: string;
  description: string;
  contracts: ClientContractCreateCommandDto[];
}

export interface ClientPaymentRequestUpdateCommandDto
  extends Omit<ClientPaymentRequestCreateCommandDto, 'contracts'> {
  concurrencyStamp: string;
  contracts: ClientContractUpdateCommandDto[];
}

export interface ClientPaymentRequestSendForSignCommandDto
  extends Omit<ClientPaymentRequestCreateCommandDto, 'contracts'> {
  concurrencyStamp: string;
  contracts: ClientContractSendForSignCommandDto[];
}

export interface ClientPaymentRequestSendForSignAfterReworkCommandDto
  extends Omit<ClientPaymentRequestCreateCommandDto, 'contracts'> {
  concurrencyStamp: string;
  contracts: ClientContractSendForSignAfterReworkCommandDto[];
}

export interface ClientContractDto {
  id: string;
  number: string;
  clientFullName: string;
  product: Product;
  creditDate: string;
  creditAmount: number;
  rewardPercent: number;
  rewardAmount: number;
}

export interface ClientContractCreateCommandDto {
  number: string;
  clientFullName: string;
  productId: string;
  creditDate: string | null;
  creditAmount: number;
  rewardPercent: number;
}

export interface ClientContractUpdateCommandDto extends ClientContractCreateCommandDto {
  id: string;
}

export type ClientContractSendForSignCommandDto = ClientContractUpdateCommandDto;

export type ClientContractSendForSignAfterReworkCommandDto = ClientContractUpdateCommandDto;

export interface Product {
  id: string;
  name: string;
}

export interface File {
  fileType: number;
  name: string;
  contentType: string;
  size: number;
  readLink: string;
  deleteLink: string;
}

export enum OrderDetailsStatusEnum {
  // Черновик.
  Draft = 1,
  // На рассмотрении.
  UnderConsideration = 2,
  // На проверке.
  OnCheck = 3,
  // На согласовании.
  ToBeAgreed = 4,
  // На доработке.
  ForRework = 5,
  // Отклонена.
  Rejected = 6,
  // Согласована.
  Agreed = 7,
  // Оплачена.
  Payed = 8,
  // На оплате.
  OnPayment = 9,
  // Ошибка.
  Error = 10,
  // Исполнена.
  Done = 11,
  // Отменена.
  Canceled = 12,
  // Доход не фискализирован
  NotFiscalize = 13,
}

export enum OrderReceiptStatusEnum {
  // Создается
  New = 1,
  // Актуален
  Actual,
  // Оффлайн
  Offline,
  // Аннулирован
  Canceled,
  // Доход не зарегистрирован
  NotRegistered,
}

export enum OrderReceiptStatusNameEnum {
  // Создается
  New = 'New',
  // Актуален
  Actual = 'Actual',
  // Оффлайн
  Offline = 'Offline',
  // Аннулирован
  Canceled = 'Canceled',
  // Доход не зарегистрирован
  NotRegistered = 'NotRegistered',
}

export enum LocalizedOrderReceiptStatusNameEnum {
  // Создается
  New = 'Создается',
  // Актуален
  Actual = 'Актуален',
  // Оффлайн
  Offline = 'Оффлайн',
  // Аннулирован
  Canceled = 'Аннулирован',
  // Доход не зарегистрирован
  NotRegistered = 'Доход не зарегистрирован',
}

export enum OrderDetailsBannerTextsEnum {
  UntiedFromTheSite = 'UntiedFromTheSite',
  Deregistered = 'Deregistered',
  ProhibitionOfIncomeRegistration = 'ProhibitionOfIncomeRegistration',
  IncomeLimitReached = 'IncomeLimitReached',
  Other = 'Other',
}

export enum LocalizedOrderDetailsBannerTextsEnum {
  UntiedFromTheSite = 'Самозанятый отвязался от площадки',
  Deregistered = 'Самозанятый снялся с учета',
  ProhibitionOfIncomeRegistration = 'Налоговый орган наложил запрет на регистрацию дохода',
  IncomeLimitReached = 'Самозанятый достиг максимального уровня дохода',
  Other = 'Техническая ошибка формирования чека',
}

export enum OrderDetailsActionEnum {
  /// Создание заявки на оплату (сотрудник).
  Create = 1,

  /// Обновление заявки на оплату (сотрудник). Статус не меняется.
  Update,

  /// Отправить на подписание сотруднику (сотрудник).
  SendToSignByEmployee,

  /// Взять в работу (сотрудник).
  TakeForVerification,

  /// Подписать (сотрудник).
  SignByEmployee,

  /// Отклонить (сотрудник). Отправить на доработку.
  RefuseByEmployee,

  /// Подписать (исполнитель).
  SignByExecutor,

  /// Отклонить (исполнитель). Отправить на доработку.
  RefuseByExecutor,

  /// Отправить на оплату (сотрудник).
  SendToPay,

  /// Отменить (сотрудник).
  CancelByEmployee,

  /// Создать чек для заявки
  CreateReceipt,

  /// Отправить на подписани после переработки.
  SendToSignAfterRework,

  /// Оплатить (сотрудинк)
  Pay,

  /// Переназначить на себя
  ReassignForVerification,
}

export interface ReferenceDto {
  creationTime: string;
  type: number;
  year: number;
  file: FileMetadataDto;
}

export interface FtsNotificationPagedResultDto {
  items: NotificationDto[] | null;
  totalCount: number;
  totalUnreadCount: number;
}

export interface NotificationDto {
  id: string;
  title: string | null;
  content: string | null;
  status: number;
  creationTime: string;
}

export interface MarkManyAsAcknowledgedCommandDto {
  notificationIds: string[] | null;
}

/** TODO Расширить модель по необходимости */
export interface ReceiptDto {
  id: string;
  statusName: string | null;
}

export interface ReceiptForListDto {
  items: ItemsForReceiptListDto[];
  totalCount: number;
}

export interface ItemsForReceiptListDto {
  id: string;
  externalId: string | null;
  purchaseTime: string;
  status: OrderReceiptStatusEnum;
  totalAmount: number;
  reasonNotFiscalizeIncomeName: string | null;
  cancellationTime: string | null;
  reasonCancellation: ReasonCancellation;
  services: ServiceDto[];
}

export interface ServiceDto {
  name: string | null;
  cost: number;
}

export enum ReasonCancellation {
  'One' = 1,
  'Two',
  'Three',
}

export interface CancelReceiptBody {
  reasonCancellation: number;
  concurrencyStamp: string;
}

export interface AgentReceiptDto {
  id: string;
  externalId: string | null;
  purchaseTime: string;
  status: number;
  totalAmount: number;
  linkReceiptFts: string | null;
  reasonNotFiscalizeIncomeName: string | null;
  cancellationTime: string | null;
  reasonCancellation: number;
  concurrencyStamp: string | null;
  services: ServiceDto[];
  files: FileMetadataDto[] | null;
}

export interface ReceiptError {
  type: string;
  title: string;
  status: number;
  detail: string;
  traceId: string;
}
export interface ReceiptCreateCommandDto {
  purchaseTime: string;
  buyerTin: string | null;
  buyerName: string | null;
  sourceIncome: number;
  services: ServiceCreateCommandDto[] | null;
}

export interface ServiceCreateCommandDto {
  name: string | null;
  cost: number;
}

export interface BalanceDto {
  amount: number;
  lastDateAmountUpdate: Date;
}

export interface ClientPayResultDto {
  isSuccess: boolean;
  error: string[] | null;
  info: string[] | null;
}