import React from 'react';
import { PageWrapper } from 'ui/redesign/PageWrapper';

export const UserAgreementPage = () => {
  return (
    <PageWrapper title="Пользовательское соглашение" isGoBack={false}>
      <div className="flex flex-col gap-6">
        <p>
          Сторонами настоящего Пользовательского соглашения (далее - Соглашение) являются Общество с
          ограниченной ответственностью «ЭмКор» (ООО «Эмкор») в лице Генерального директора
          Журавлевой Евгении Сергеевны, действующей на основании Устава, именуемое в дальнейшем{' '}
          <b>«Администрация сервиса»</b>, и присоединившийся к настоящему Соглашению и обладающий
          соответствующими полномочиями Пользователь, которым может являться физическое лицо,
          применяющее специальный налоговый режим - налог на профессиональный доход,
          зарегистрированного в соответствии с законодательством РФ (далее - <b>«Пользователь»</b>).
        </p>
        <p>
          <b>1. Общие положения</b>
          <br />
          1.1. Соглашение определяет общие правила пользования электронной площадки, размещенной в
          информационно-телекоммуникационной сети Интернет по адресу:{' '}
          <a href="https://m-pro.online" target="blanc" className="text-primary-60">
            https://m-pro.online
          </a>{' '}
          на котором функционирует принадлежащая Администрации Сервиса электронная площадка «M-Pro»
          (далее – Сервис или Платформа).
          <br />
          1.2. В настоящем документе и вытекающих или связанных с ним отношениях Сторон применяются
          следующие термины и определения:
          <br />
          <ol className="list-inside list-[lower-alpha]">
            <li className="pl-2">
              <b>Сервис (или Платформа)</b> – совокупность функциональных возможностей электронной
              площадки, размещенного в нем Контента, доступ к которым предоставляется Пользователю в
              информационных целях. Платформа позволяет осуществлять взаимодействие Администрацией
              Сервиса и Пользователей, включая обмен информацией и совершение отдельных действий в
              рамках использования Платформы.
            </li>
            <li className="pl-2">
              <b>Контент</b> – любые материалы, к которым можно получить доступ с использованием
              Сервиса.
            </li>
            <li className="pl-2">
              <b>Мобильное приложение</b> – предназначенная для установки и использования на
              мобильном устройстве программа для ЭВМ, позволяющая пользоваться Сервисом.
            </li>
            <li className="pl-2">
              <b>Пользователь</b> – лицо, заключившее настоящее Соглашение с Администрацией Сервиса
              в своем или чужом интересе в соответствии с требованиями действующего законодательства
              и настоящего Соглашения и присоединившееся к Платформе.
            </li>
            <li className="pl-2">
              <b>Личный кабинет</b> – персональный раздел электронной площадки, к которой
              Пользователь получает доступ после прохождения регистрации и/или авторизации. Личный
              кабинет предназначен для просмотра и управления доступными для Пользователя
              функциональными возможностями Сервиса.
            </li>
            <li className="pl-2">
              <b>Код доступа</b> - заданная Пользователем и известная только ему последовательность
              цифровых символов, используемая как идентификатор Пользователя при входе в электронную
              площадку.
            </li>
            <li className="pl-2">
              <b>Оферта</b> - размещенный Договор на оказание услуг между Администрацией сервиса и
              Пользователем, опубликованный в сети Интернет в Личном кабинете Пользователя.
            </li>
            <li className="pl-2">
              <b>Простая электронная подпись (ПЭП)</b> - электронной подписью признается электронная
              подпись, которая посредством использования верных идентификаторов при авторизации
              Пользователя на Платформе и подтверждения одноразовым СМС-кодом (в случаях, когда
              Платформа требует такое подтверждение), направленным на абонентский номер телефона
              Пользователя (ключ электронной подписи), подтверждает факт формирования электронной
              подписи непосредственно Пользователем.
            </li>
          </ol>
          1.3. В настоящем Соглашении могут быть использованы иные термины и определения, не
          указанные в п.1.2. Соглашения. В этом случае толкование такого термина производится в
          соответствии с текстом Соглашения. В случае отсутствия однозначного толкования термина или
          определения в тексте Соглашения следует руководствоваться его толкованием, определенным: в
          первую очередь – указанными в нем Обязательными документами, во вторую очередь –
          законодательством Российской Федерации, и в последующем — обычаями делового оборота и
          научной доктриной.
          <br />
          1.4. Использование Сервиса любым способом и в любой форме создает договор на условиях
          настоящего Соглашения и указанных в нем обязательных для Сторон документов в соответствии
          с положениями ст.437 и ст. 438 Гражданского кодекса Российской Федерации.
          <br />
          1.5. Используя Сервис вы подтверждаете, что:
          <br />
          <ol className="list-inside list-[lower-alpha]">
            <li className="pl-2">
              Ознакомились с условиями настоящего Соглашения и указанных в нем Обязательных
              документов в полном объеме до начала использования Сервиса.
            </li>
            <li className="pl-2">
              Принимаете все условия настоящего Соглашения и указанных в нем Обязательных документов
              в полном объеме без каких-либо изъятий и ограничений с вашей стороны и обязуетесь их
              соблюдать или прекратить использование Сервиса, если вы не согласны с условиями
              настоящего Соглашения и указанных в нем Обязательных документов или не имеете права на
              заключение договора на их основе, вам следует незамедлительно прекратить любое
              использование Сервиса.
            </li>
            <li className="pl-2">
              Соглашение (в том числе любая из его частей) и/ или указанные в нем Обязательные
              документы могут быть изменены Администрацией Сервиса без какого-либо специального
              уведомления. Новая редакция Соглашения и/или указанных в нем Обязательных документов
              вступает в силу с момента размещения на электронной площадке либо доведения до
              сведения Пользователя в иной удобной форме, если иное не предусмотрено новой редакцией
              Соглашения и/или указанных в нем обязательных для Сторон документов.
            </li>
          </ol>
        </p>
        <p>
          <b>2. Порядок регистрации</b>
          <br />
          2.1. Соглашение предоставляет Пользователю возможность зарегистрироваться и получить
          доступ к информационным и технологическим ресурсам Сервиса, а также к предлагаемым услугам
          и сервисам Платформы, связанных с консультированием Клиентов, по вопросам подготовки и
          передачи на заключение всех необходимых документов для приобретения определённых услуг у
          партнеров Заказчика, формирование досье/анкеты Клиента, осуществление сбора сведений и
          документов, определенных Заказчиком и иным сопутствующим услугам (сервисам) Платформы.
          Администрация Сервиса предлагает Пользователям доступ к широкому спектру онлайновых услуг
          и сервисов Платформы. Для того чтобы воспользоваться услугами и сервисами Платформы,
          необходимо иметь компьютер и доступ в Интернет. Все вопросы приобретения прав доступа в
          Интернет, покупки и наладки соответствующего оборудования и программных продуктов решаются
          Пользователями самостоятельно и не подпадают под действие настоящего Пользовательского
          соглашения.
          <br />
          2.2. Пользователь может получить за денежное вознаграждение дополнительные возможности
          (модули) для использования информационных и технологических ресурсов Сервиса. Порядок
          получения дополнительных возможностей за денежное вознаграждение определяется
          Администрацией Сервиса в иных документах, не являющихся частями настоящего
          Пользовательского соглашения.
          <br />
          2.3. В отношении функционирования и развития Сервиса Администрация сервиса руководствуется
          законодательством Российской Федерации, настоящим Соглашением и иными специальными
          документами, которые разработаны или могут быть разработаны и приняты Администрацией
          сервиса в целях регламентации предоставления Пользователям отдельных услуг и сервисов
          Платформы. Предусмотренные настоящим Соглашением правила пользования Сервиса являются
          обязательными для Пользователя и Администрации. Помимо настоящего Соглашения к отношениям
          между Пользователем и Администрацией сервиса относятся все специальные документы,
          регулирующие предоставление отдельных услуг и сервисов Платформы и размещенные в открытом
          доступе в соответствующих разделах Сервиса, в том числе Регламент работы Платформы.
          Соответствующими договорами на Стороны могут быть наложены дополнительные обязательства,
          связанные с использованием услуг и сервисов Платформы.
          <br />
          2.4. Регистрация Пользователя на Платформе является бесплатной, добровольной и
          производится по адресу в сети Интернет
          <a href="https://m-pro.online" target="blanc" className="text-primary-60">
            (https://m-pro.online)
          </a>{' '}
          или через Мобильное приложение на неопределенный срок, однако Администрация Сервиса
          оставляет за собой право удалить учетную запись Пользователя в случае неактивности в
          течении 365 дней с момента последнего входа на Платформу.
          <br />
          2.5. Для регистрации на Платформе Пользователь обязан предоставить достоверную и полную
          информацию о себе, заполнив регистрационную форму учетной записи. Пользователь несет
          ответственность за достоверность, актуальность, полноту и соответствие законодательству
          Российской Федерации предоставленной при регистрации информации и ее чистоту от претензий
          третьих лиц. Если Пользователь предоставляет неверную информацию или у Администрации
          Сервиса есть основания полагать, что предоставленная Пользователем информация неполна или
          недостоверна, Администрация сервиса имеет право по своему усмотрению заблокировать либо
          удалить учетную запись Пользователя и отказать Пользователю в использовании своих сервисов
          (либо их отдельных функций). Пользователь вправе зарегистрировать не более одного «личного
          кабинета» в Сервисе, в случае наличия более одного «личного кабинета», Администрация
          сервиса оставляет за собой право ограничить доступ Пользователю к Платформе. При создании
          и администрировании «личного кабинета» Пользователь подтверждает, что действует на
          законных основаниях (например, на основании доверенности), обладает всеми необходимыми
          правами и не нарушает своими действиями законные права и интересы третьих лиц, а также
          действующее законодательство Российской Федерации, в том числе законодательство о защите
          конкуренции, о защите интеллектуальной собственности. Пользователь несет ответственность
          за все действия с использованием его электронного адреса, логина (имени пользователя) и
          пароля. Пользователь имеет право пользоваться услугами и сервисами Платформы только при
          помощи собственного адреса электронной почты, логина и пароля. Выбранные Пользователем
          логин и пароль являются необходимой и достаточной информацией для доступа Пользователя на
          Платформу Пользователь самостоятельно несет ответственность за безопасность (устойчивость
          к угадыванию) выбранного им пароля, а также самостоятельно обеспечивает конфиденциальность
          своего пароля. Пользователь самостоятельно несет ответственность за все действия (а также
          их последствия) в рамках или с использованием сервисов Платформы под учетной записью
          Пользователя, включая случаи добровольной передачи Пользователем данных для доступа к
          учетной записи Пользователя третьим лицам на любых условиях (в том числе по договорам или
          соглашениям). При этом все действия в рамках или с использованием сервисов Платформы под
          учетной записью Пользователя считаются произведенными самим Пользователем. Пользователь
          обязан немедленно изменить данные для авторизации в Сервисе, если у него есть причины
          подозревать, что эти данные были раскрыты или могут быть использованы третьими лицами.
          Пользователь соглашается с тем, что он обязан немедленно уведомить Администрацию сервиса о
          любом случае неавторизованного (не разрешенного Пользователем) доступа с его логином и
          паролем и/или о любом нарушении безопасности, а также с тем, что Пользователь
          самостоятельно осуществляет резервное копирование важной для Пользователя и хранящейся в
          его «личном кабинете» информации, самостоятельно осуществляет завершение работы под своим
          паролем (кнопка «Выход») по окончании каждой сессии работы с сервисами Платформы.
          Администрация сервиса не отвечает за возможную потерю или порчу данных, которая может
          произойти из-за нарушения Пользователем положений данного пункта Пользовательского
          соглашения.
          <br />
          2.6. Перед началом оказания услуг Пользователь, ознакомившись с Офертой и настоящим
          Соглашением, обязан достоверно заполнить регистрационную форму на Платформе и в Личном
          кабинете. Указанию (заполнению) подлежат все требуемые данные, включая ФИО, паспортные
          данные, адрес регистрации и фактического проживания, платежные реквизиты, СНИЛС, ИНН,
          сведения об опыте работы, контактный телефон, электронную почту и т.д. Пользователь обязан
          также загрузить сканированные копии соответствующих документов. Администрация сервиса
          вправе запросить, а Пользователь обязан предоставить дополнительную информацию (сведения,
          данные) о Пользователе, необходимую для оказания услуг по настоящему Договору. После
          заполнения регистрационной формы и предоставления необходимых документов Пользователь
          обязан ознакомиться с размещенной в Личном кабинете Офертой и настоящим Соглашением, в
          случае своего согласия с ее условиями, сделать отметку о согласии путем клика на поле
          приема условий Оферты и настоящего Соглашения в Личном кабинете. Акцептуя настоящую Оферту
          и настоящее Соглашение, Исполнитель также автоматически подтверждает свое согласие со
          всеми регламентами (правилами) технического и иного характера, размещенными на Платформе,
          и обязуется их соблюдать.
          <br />
          2.7. Администрация сервиса проверяет данные регистрации Пользователя в течение 5 рабочих
          дней с момента их полного предоставления. При отсутствии или недостоверности необходимых
          данных или опыте квалификации, не соответствующем требованиям Администрации сервиса вправе
          в предоставлении доступа к Сервису.
          <br />
          2.8. Для целей идентификации Пользователя ПЭПом в процессе регистрации на Платформе
          Пользователь обязан сделать и направить Администрации сервиса свое фото с четко различимым
          лицом. На указанном изображении должен одновременно содержаться документ, удостоверяющий
          личность Пользователя, в развернутом виде, с четко различимыми данными и фотографией.
        </p>
        <p>
          <b>3. Гарантии Пользователя</b>
          <br />
          Принимая условия настоящего Соглашения, вы подтверждаете и гарантируете, что:
          <br /> 3.1. Вы обладаете всеми необходимыми правами и полномочиями для заключения Договора
          на использование Сервиса и его исполнения;
          <br /> 3.2. Использование Сервиса будет осуществляться вами исключительно для целей,
          разрешенных настоящим Соглашением с соблюдением его положений, а равно требований
          применимого права и общепринятой практики;
          <br /> 3.3. Вы не будете совершать каких-либо действий, которые вступают в противоречие
          или препятствуют предоставлению Сервиса или работе соответствующего оборудования, сетей,
          или программного обеспечения, с помощью которых предоставляется Сервис.
          <br /> 3.4. Пользователь обязуется предоставить достоверную и полную информацию о себе при
          заполнении данных при регистрации на Платформе. В случае предоставления Пользователем
          недостоверной информации или в том случае, если у Администрации Сервиса Мобильного
          приложения есть основания полагать, что предоставленная Пользователем информация неполная
          или недостоверная, а также в соответствии с внутренней политикой Администрации Сервиса
          вправе заблокировать личный кабинет Пользователя или ограничить доступ к определенным
          действиям на Платформе или отказать Пользователю в использовании/частичном использовании
          функционала Мобильного приложения.
        </p>
        <p>
          <b>4. Ограничения</b>
          <br />
          Соглашаясь с условиями настоящего Соглашения, вы понимаете и признаете, что:
          <br /> 4.1. К отношениям Сторон по предоставлению Сервиса не подлежат применению положения
          законодательства о защите прав потребителей.
          <br /> 4.2. Сервис предоставляется на условиях «как есть», в связи с чем вам не
          представляются какие-либо гарантии, что Сервис будет соответствовать вашим требованиям;
          услуги будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые
          могут быть получены с использованием Сервиса, будут точными и надежными; качество
          какого-либо продукта, услуги и информации, полученных с использованием Сервиса, будет
          соответствовать вашим ожиданиям; все ошибки в программном обеспечении Сервиса будут
          исправлены.
          <br /> 4.3. Поскольку Сервис находится на стадии постоянного дополнения и обновления новых
          функциональных возможностей, форма и характер предоставляемых услуг могут время от времени
          меняться без вашего предварительного уведомления. Администрация сервиса вправе по
          собственному усмотрению прекратить (временно или окончательно) предоставление услуг (или
          каких-либо отдельных функций в рамках услуг) всем Пользователям вообще или вам, в
          частности, без предварительного уведомления.
          <br /> 4.4. Вы не имеете права самостоятельно или с привлечением третьих лиц:
          <ul className="list-inside list-disc">
            <li className="pl-2">
              копировать (воспроизводить) в любой форме и способом входящие в состав Платформы
              программы для ЭВМ и базы данных, включая любые их элементы и информационные материалы,
              без получения предварительного письменного согласия их владельца;
            </li>
            <li className="pl-2">
              вскрывать технологию, эмулировать, декомпилировать, дизассемблировать, дешифровать, и
              производить иные аналогичные действия с Платформой или мобильным приложением;
            </li>
            <li className="pl-2">
              создавать программные продукты и/или сервисы с использованием Сервиса без получения
              предварительного разрешения Администрации Сервиса.
            </li>
          </ul>
          4.5. Пользователям запрещается использование Мобильного приложения и/или предоставляемого
          на его основе Сервиса для:
          <ul className="list-inside list-disc">
            <li className="pl-2">размещения и/или распространения контрафактных материалов;</li>
            <li className="pl-2">
              размещения и/или распространения материалов порнографического характера, а равно
              пропаганды порнографии и детской эротики, и рекламы интимных услуг;
            </li>
            <li className="pl-2">
              распространения любой иной запрещенной информации, включая материалы, экстремистского
              характера, а также направленных на ущемление прав и свобод человека по признакам
              расовой и национальной принадлежности, вероисповедания, языка, и пола, подстрекающие к
              совершению насильственных действий в отношении человека, либо к бесчеловечному
              обращению с животными, призывающие к совершению иных противоправных действий, в том
              числе разъясняющие порядок изготовления и применения оружия, наркотических средств и
              их прекурсов, и т.д.;
            </li>
            <li className="pl-2">
              распространения недостоверной информации, порочащей честь достоинство или деловую
              репутацию третьих лиц;
            </li>
            <li className="pl-2">
              преимущественного или исключительного размещения, или распространения ссылок на другие
              сайты;
            </li>
            <li className="pl-2">
              совершения иных противоправных действий, включая получение несанкционированного
              доступа к конфиденциальной информации, ее распространение, неправомерный доступ к
              компьютерной информации, использование и распространение вредоносных компьютерных
              программ, нарушение правил эксплуатации средств хранения, обработки или передачи
              компьютерной информации и информационно-телекоммуникационных сетей, организации
              азартных игр, проведения лотерей и иных стимулирующих мероприятий в нарушение
              действующего законодательства.
            </li>
          </ul>
          4.7. При обнаружении ошибок в работе Сервиса сообщите об этом Администрации сервиса по
          адресу, указанному в реквизитах или отдельно в Мобильном приложении для службы поддержки.
          <br /> 4.8. В случае обнаружения нарушения ваших прав и/или интересов в связи с
          предоставлением Сервиса, вам следует сообщить об этом Администрации сервиса. Для этого
          необходимо направить Администрации сервиса письменное уведомление с подробным изложением
          обстоятельств нарушения.
          <br /> 4.9. Вы несете исключительную ответственность за любое нарушение обязательств,
          установленных настоящим Соглашением и/или применимым правом, а также за все последствия
          таких нарушений (включая любые убытки или ущерб, которые может понести Администрация
          Сервиса и иные третьи лица).
          <br /> 4.10. Администрация сервиса оставляет за собой право по своему усмотрению разрешать
          или запрещать размещение, редактировать, ограничивать доступ или удалять любой Контент, в
          том числе, отзывы, жалобы, комментарии Пользователей на Платформе, а также блокировать
          доступ к Сервису либо иным образом ограничивать (прекращать) предоставление Пользователю
          Сервиса.
          <br /> 4.11. В случае многократного или грубого нарушения условий настоящего Соглашения,
          включая указанные в нем Обязательные документы, и/или требований законодательства,
          Администрация Сервиса оставляет за собой право заблокировать доступ к Сервису либо иным
          образом ограничить (прекратить) предоставление Пользователю услуг с использованием
          Сервиса.
          <br /> 4.12. В случае привлечения Администрации сервиса к ответственности или наложения на
          него взыскания в связи с допущенными вами нарушениями прав и/или интересов третьих лиц, а
          равно установленных законодательством запретов или ограничений, Пользователь обязуется в
          полном объеме возместить убытки.
          <br /> 4.13. Администрация сервиса устраняется от какой бы то ни было ответственности в
          связи с допущенными вами нарушениями, а также причинением вам вреда или убытков при
          указанных выше обстоятельствах.
        </p>
        <p>
          <b>5. Уведомления</b>
          <br /> 5.1. Пользователь соглашается получать от Администрации сервиса на указанный при
          работе с Сервисом электронный адрес, а также через Мобильное приложение
          (push-уведомления), СМС-сообщения, информационные электронные сообщения (далее —
          «нотификаторы») с сообщениями о важных событиях, происходящих в рамках Сервиса или в связи
          с ним.
          <br /> 5.2. Администрация сервиса вправе использовать нотификаторы в рамках
          информационного обслуживания для информирования Пользователя об изменениях и новых
          возможностях Сервиса и/или об изменении Соглашения или указанных в нем Обязательных
          документов, а также в связи с заключением и исполнением договоров, либо проведением
          маркетинговых акций, в том числе в рекламных целях (при наличии согласия пользователя).
        </p>
        <p>
          <b>6. Соглашение об использовании простой электронной подписи</b>
          <br /> 6.1. Во взаимоотношениях между Администрацией сервиса и Пользователем могут
          использоваться электронные документы, удостоверенные простой электронной подписью.
          <br /> 6.2. По соглашению Сторон электронные документы, подписанные простой электронной
          подписью, признаются равнозначными документам на бумажных носителях, подписанным
          собственноручной подписью.
          <br /> 6.3. Администрация сервиса определяет Пользователя, которому соответствует простая
          электронная подпись, по используемым Пользователем идентификаторам. 6.4. Любые действия,
          совершенные с использованием простой электронной подписи определенного Пользователя,
          считаются совершенными таким Пользователем.
          <br /> 6.5. Пользователь обязуется соблюдать конфиденциальность ключа электронной подписи.
          В частности, Пользователь не имеет права передавать свои идентификаторы или предоставлять
          доступ на Платформу третьим лицам, и несет полную ответственность за их сохранность и
          индивидуальное использование, самостоятельно выбирая способ их хранения и ограничения к
          ним доступа.
          <br /> 6.6. В случае несанкционированного доступа к Платформе, идентификаторам, их утраты
          или раскрытия третьим лицам Пользователь обязан незамедлительно сообщить об этом
          Администрации сервиса путем направления электронного письма на электронную почту.
        </p>
        <p>
          <b>7. Прочие условия</b>
          <br /> 7.1. Зарегистрированный Пользователь самостоятельно определяет условия и порядок
          использования функциональных возможностей Сервиса, которые, однако, ни при каких условиях
          не могут противоречить настоящему Соглашению.
          <br /> 7.2. Применимое право. Настоящее Соглашение, порядок его заключения и исполнения, а
          также вопросы, не урегулированные настоящим Соглашением, регулируются действующим
          законодательством Российской Федерации.
          <br />
          7.3. Все споры по Соглашению или в связи с ним подлежат рассмотрению в суде по месту
          нахождения Администрации сервиса в соответствии с действующим процессуальным правом
          Российской Федерации.
          <br /> 7.4. Настоящее Соглашение может быть изменено или прекращено Администрация сервиса
          в одностороннем порядке без предварительного уведомления Пользователя и без выплаты
          какой-либо компенсации в связи с этим.
          <br /> 7.5. Действующая редакция настоящего Соглашения на Платформе и доступна в сети
          Интернет по адресу{' '}
          <a href="https://m-pro.online" target="blanc" className="text-primary-60">
            https://m-pro.online
          </a>
        </p>
        <p>
          <b>Наименование:</b> Общество с ограниченной ответственностью «ЭмКор» (ООО «ЭмКор»)
          <br />
          <b>ОГРН:</b> 1057748872741
          <br />
          <b>ИНН:</b> 7718565056
          <br />
          <b>КПП:</b> 773001001
          <br />
          <b>Юридический адрес:</b> 121309, г. Москва, вн. тер. г. муниципальный округ Филевский
          Парк, ул. Барклая, д. 13, стр. 2, этаж 1, ком. 21
        </p>
        <p className="mb-4 text-center">Действующая редакция от 04.06.2024 года</p>
      </div>
    </PageWrapper>
  );
};
