import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface NavigationHistoryContextType {
  history: string[];
  removeLastRoute: () => void;
  clearHistory: () => void;
}

const NavigationHistoryContext = createContext<NavigationHistoryContextType | undefined>(undefined);

interface NavigationHistoryProviderProps {
  children: ReactNode;
}

export const NavigationHistoryProvider: React.FC<NavigationHistoryProviderProps> = ({
  children,
}) => {
  const [history, setHistory] = useState<string[]>(() => {
    const savedHistory = sessionStorage.getItem('navigationHistory');
    return savedHistory ? JSON.parse(savedHistory) : [];
  });
  const location = useLocation();

  useEffect(() => {
    setHistory((prevHistory) => {
      const currentPathname = location.pathname;
      if (currentPathname !== '/' && currentPathname !== prevHistory[prevHistory.length - 1]) {
        const newHistory = [...prevHistory, location.pathname];
        sessionStorage.setItem('navigationHistory', JSON.stringify(newHistory));
        return newHistory;
      }
      return prevHistory;
    });
  }, [location]);

  const removeLastRoute = () => {
    setHistory((prevHistory) => {
      const newHistory = prevHistory.slice(0, -1);
      sessionStorage.setItem('navigationHistory', JSON.stringify(newHistory));
      return newHistory;
    });
  };

  const clearStorageItems = () => {
    setHistory([]);
    sessionStorage.removeItem('navigationHistory');
    sessionStorage.removeItem('ftsProfile');
  };

  return (
    <NavigationHistoryContext.Provider
      value={{ history, removeLastRoute, clearHistory: clearStorageItems }}
    >
      {children}
    </NavigationHistoryContext.Provider>
  );
};

export const useNavigationHistory = (): NavigationHistoryContextType => {
  const context = useContext(NavigationHistoryContext);
  if (context === undefined) {
    throw new Error('useNavigationHistory must be used within a NavigationHistoryProvider');
  }
  return context;
};
