import { OrderDetailsStatusEnum } from 'services/AgentService/types';

export interface ClientOrderRequest {
  items: [
    {
      id: string;
      number: number;
      creationTime: string;
      status: OrderDetailsStatusEnum;
      month: number;
      year: number;
      paymentAmount: number;
    }
  ];
  totalCount: number;
}

export enum ClientOrderTabsEnum {
  'all' = 'all',
  'inProgress' = 'inProgress',
  'active' = 'active',
  'paid' = 'paid',
  'cancelled' = 'cancelled',
}
