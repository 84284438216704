import { AxiosError, AxiosResponse } from 'axios';
import classNames from 'classnames';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FC, useCallback } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { getPermissions } from 'reduxStore/reducers/ProfileSlice/selectors/getPermissions';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';
import { AgentService } from 'services/AgentService';
import {
  ClientPaymentRequestDto,
  OrderDetailsActionEnum,
  OrderDetailsStatusEnum,
} from 'services/AgentService/types';
import { DropDown, MenuItem } from 'ui/DropDown';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { ClientOrderDetailsViewRejectForm } from './ClientOrderDetailsViewRejectForm';
import { ClientOrderDetailsViewSuccessForm } from './ClientOrderDetailsViewSuccessForm';
import { PayForm } from './PayForm';
import { ClientOrderDetailsViewActionsProps } from './types';
import { ClientOrderDetailsViewConfirmPaymentForm } from './ClientOrderDetailsViewConfirmPaymentForm';
import { BaseError } from 'types';

export const ClientOrderDetailsViewActions: FC<ClientOrderDetailsViewActionsProps> = ({
  order,
  setActualOrder,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tab } = useParams();
  const permissions = useAppSelector(getPermissions);

  const isAgentCustomersRepresentative = permissions.includes(
    ProfilePermissions.Agent_Customers_Representative
  );

  const isAgentAccreditaionEmployee = permissions.includes(
    ProfilePermissions.Agent_Accreditaion_employee
  );

  // Действия представителя клиента
  const AgentCustomersRepresentativeActions: MenuItem[] = [];
  isAgentCustomersRepresentative &&
    order.actions?.includes(OrderDetailsActionEnum.RefuseByEmployee) &&
    AgentCustomersRepresentativeActions.push({
      title: 'Вернуть на доработку',
      action: () => handleModal('reject'),
    });
  isAgentCustomersRepresentative &&
    order.actions?.includes(OrderDetailsActionEnum.Pay) &&
    AgentCustomersRepresentativeActions.push({
      title: 'Выплатить',
      action: () => handlePayModal(),
    });

  const handleModal = useCallback(
    (action: 'success' | 'reject' | 'сonfirmPayment') => {
      dispatch(
        modalActions.handleOpenModal({
          content:
            action === 'success' ? (
              <ClientOrderDetailsViewSuccessForm order={order} setActualOrder={setActualOrder} />
            ) : action === 'reject' ? (
              <ClientOrderDetailsViewRejectForm order={order} setActualOrder={setActualOrder} />
            ) : (
              <ClientOrderDetailsViewConfirmPaymentForm
                order={order}
                setActualOrder={setActualOrder}
              />
            ),
          options: {
            title:
              action === 'success'
                ? 'Подписание акта выполненных работ'
                : action === 'сonfirmPayment'
                ? 'Подтвердить выплату'
                : isAgentCustomersRepresentative
                ? 'Укажите причину возврата на доработку'
                : 'Укажите причину отказа',
            contentClassName: classNames('md:min-w-[650px] text-text-100', {
              'md:min-h-[650px]': action === 'сonfirmPayment',
            }),
          },
        })
      );
    },
    [dispatch, order, setActualOrder, isAgentCustomersRepresentative]
  );

  const handlePayModal = useCallback(() => {
    dispatch(
      modalActions.handleOpenModal({
        content: <PayForm order={order} />,
        options: {
          title: 'Нажимая «Оплатить», вы подтверждаете списание средств',
          contentClassName: classNames('md:min-w-[650px] text-text-100'),
        },
      })
    );
  }, [dispatch, order]);

  const { mutateAsync: clientOrderDetailsViewTakeForVerification } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    unknown,
    { orderId: string; concurrencyStamp: string }
  >({
    mutationKey: 'clientOrderDetailsViewTakeForVerification',
    mutationFn: ({ orderId, concurrencyStamp }) =>
      AgentService.clientOrderDetailsViewTakeForVerification(orderId, concurrencyStamp),
    onSuccess: ({ data }) => {
      setActualOrder(data);
    },
    onError: () => {
      toast.error('Операция взятия в работу не выполнена, повторите попытку');
    },
  });

  const { mutateAsync: reassignForVerification } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    AxiosError<BaseError>,
    { orderId: string; concurrencyStamp: string }
  >({
    mutationKey: 'reassignForVerification',
    mutationFn: ({ orderId, concurrencyStamp }) =>
      AgentService.reassignForVerification(orderId, concurrencyStamp),
    onSuccess: ({ data }) => {
      setActualOrder(data);
      toast.success('Заявка успешно переназначена.');
    },
    onError: (data) => {
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  const { mutateAsync: clientOrderDetailsViewSendToPayment } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    unknown,
    { orderId: string; concurrencyStamp: string }
  >({
    mutationKey: 'clientOrderDetailsViewSendToPayment',
    mutationFn: ({ orderId, concurrencyStamp }) =>
      AgentService.clientOrderDetailsViewSendToPayment(orderId, concurrencyStamp),
    onSuccess: ({ data }) => {
      setActualOrder(data);
    },
    onError: () => {
      toast.error('Операция отправления на оплату не выполнена, повторите попытку');
    },
  });

  const { mutateAsync: clientOrderDetailsViewCancel } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    unknown,
    { orderId: string; concurrencyStamp: string }
  >({
    mutationKey: 'clientOrderDetailsViewCancel',
    mutationFn: ({ orderId, concurrencyStamp }) =>
      AgentService.clientOrderDetailsViewCancel(orderId, concurrencyStamp),
    onSuccess: () => {
      navigate(-1);
    },
    onError: () => {
      toast.error('Операция отмены заявки не выполнена, повторите попытку');
    },
  });

  return (
    <>
      {!isAgentCustomersRepresentative ? (
        <div className="z-10 flex items-center justify-start gap-6">
          {order.actions?.includes(OrderDetailsActionEnum.RefuseByEmployee) && (
            <Button
              variant={isAgentAccreditaionEmployee ? ButtonVariant.PRIMARY : ButtonVariant.OUTLINE}
              onClick={() => handleModal('reject')}
            >
              {isAgentAccreditaionEmployee ? 'ВЕРНУТЬ НА ДОРАБОТКУ' : 'ОТКЛОНИТЬ'}
            </Button>
          )}
          {order.actions?.includes(OrderDetailsActionEnum.SignByEmployee) && (
            <Button variant={ButtonVariant.SUCCESS} onClick={() => handleModal('success')}>
              {isAgentAccreditaionEmployee ? 'ПОДПИСАТЬ' : 'ПОДТВЕРДИТЬ'}
            </Button>
          )}
          {order.actions?.includes(OrderDetailsActionEnum.TakeForVerification) && (
            <Button
              variant={ButtonVariant.SUCCESS}
              onClick={() =>
                order.concurrencyStamp &&
                clientOrderDetailsViewTakeForVerification({
                  orderId: order.id,
                  concurrencyStamp: order.concurrencyStamp,
                })
              }
            >
              ВЗЯТЬ В РАБОТУ
            </Button>
          )}
          {order.actions?.includes(OrderDetailsActionEnum.ReassignForVerification) && (
            <Button
              variant={ButtonVariant.SUCCESS}
              onClick={() =>
                order.concurrencyStamp &&
                reassignForVerification({
                  orderId: order.id,
                  concurrencyStamp: order.concurrencyStamp,
                })
              }
            >
              ПЕРЕНАЗНАЧИТЬ НА СЕБЯ
            </Button>
          )}
          {order.actions?.includes(OrderDetailsActionEnum.SendToPay) && (
            <Button
              variant={ButtonVariant.SUCCESS}
              onClick={() =>
                order.concurrencyStamp &&
                clientOrderDetailsViewSendToPayment({
                  orderId: order.id,
                  concurrencyStamp: order.concurrencyStamp,
                })
              }
            >
              ОТПРАВИТЬ НА ОПЛАТУ
            </Button>
          )}
          {order.actions?.includes(OrderDetailsActionEnum.Update) && (
            <Button
              variant={ButtonVariant.PRIMARY}
              onClick={() => navigate(`/client/orders/${tab}/${order.id}/update`)}
            >
              РЕДАКТИРОВАТЬ
            </Button>
          )}
          {order.actions?.includes(OrderDetailsActionEnum.CancelByEmployee) && (
            <Button
              variant={ButtonVariant.OUTLINE}
              onClick={() =>
                order.concurrencyStamp &&
                clientOrderDetailsViewCancel({
                  orderId: order.id,
                  concurrencyStamp: order.concurrencyStamp,
                })
              }
            >
              ОТМЕНИТЬ ЗАЯВКУ
            </Button>
          )}
        </div>
      ) : AgentCustomersRepresentativeActions.length ? (
        <div className="my-0 w-full max-w-[290px] rounded-[10px] bg-primary-60 text-white sm:max-w-[180px]">
          <DropDown buttonMode button="ДЕЙСТВИЕ" menuItems={AgentCustomersRepresentativeActions} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
