import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Skeleton } from '../../../ui/redesign/Loaders/Skeleton';
import { OrdersTabsProps } from './types';

export const Tabs = <T extends string>({
  tabs,
  ordersCounts,
  isFetching,
  activeTab,
}: OrdersTabsProps<T>) => {
  return (
    <section className="flex w-full items-center justify-around border-light-50 sm:justify-start sm:gap-5 sm:border-b-[2px] sm:pb-[10px]">
      {tabs.map((tab) => {
        const isTabActive = tab.orderType === activeTab;
        return (
          <Link
            to={tab.link}
            key={tab.orderType}
            className={classNames(
              'callout-medium sm:headline-medium relative flex items-center gap-1 whitespace-nowrap px-[5px] transition-colors duration-300 sm:gap-[6px]',
              {
                '!text-primary-80': isTabActive,
                '!font-[400] text-text-50': !isTabActive,
              }
            )}
          >
            {tab.name}{' '}
            {isFetching ? (
              <Skeleton className="h-[22px] w-[22px] rounded-[50%]" />
            ) : (
              <>
                {ordersCounts[tab.orderType] > 0 && (
                  <span
                    className={classNames(
                      'callout-medium sm:headline-medium flex items-center justify-center rounded-[100px] bg-text-50 px-[6px] py-[1px] text-base !text-white transition-colors duration-300',
                      {
                        'bg-primary-80': isTabActive,
                      }
                    )}
                  >
                    {ordersCounts[tab.orderType]}
                  </span>
                )}
              </>
            )}
            {isTabActive && (
              <motion.div
                className="absolute left-0 bottom-[-12px] h-[2px] w-full rounded-[10px] bg-primary-60"
                layoutId="underline"
              />
            )}
          </Link>
        );
      })}
    </section>
  );
};
