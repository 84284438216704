import { createSlice } from '@reduxjs/toolkit';
import { FtsNotificationsState, SetFtsNotificationsAction } from './types';

const initialState: FtsNotificationsState = {
  items: [],
};

export const ftsNotificationsSlice = createSlice({
  name: 'ftsNotifications',
  initialState,
  reducers: {
    setFtsNotifications: (state, action: SetFtsNotificationsAction) => {
      state.items = action.payload;
    },
  },
});

export const { reducer: ftsNotificationsReducer, actions: ftsNotificationsActions } =
  ftsNotificationsSlice;
