import { FileMetadataDto } from 'types';

export const OrderActions: Record<number, { id: number; label: string }> = {
  7: {
    id: 7,
    label: 'Взять в работу',
  },
  12: {
    id: 12,
    label: 'Выполнена',
  },
  13: {
    id: 13,
    label: 'Отменена',
  },
  14: {
    id: 14,
    label: 'Отказаться',
  },
  22: {
    id: 22,
    label: 'Отмена заявки',
  },
};

export interface ExecutorPaymentRequestDto {
  id: string;
  number: number;
  creationTime: string;
  managerFullName: string | null;
  status: number;
  year: number;
  month: number;
  periodStartDate: string;
  periodEndDate: string;
  paymentAmount: number;
  concurrencyStamp: string | null;
  files: FileMetadataDto[] | null;
  actions: number[] | null;
  agentOrganizationFee: number;
  receipt: ExecutorPaymentRequestReceiptDto | null;
}

export interface ExecutorPaymentRequestReceiptDto {
  id: string;
  linkReceiptFts: string | null;
  statusName: string | null;
  reasonNotFiscalizeIncomeName: string | null;
  reasonCancellation: number;
  files: FileMetadataDto[] | null;
}

export type ClientPaymentRequestReceiptDto = ExecutorPaymentRequestReceiptDto;

export interface ExecutorPaymentRequestRejectCommandDto {
  rejectReasonComment: string | null;
  concurrencyStamp: string | null;
}

export type ClientPaymentRequestRejectCommandDto = ExecutorPaymentRequestRejectCommandDto;

export interface ExecutorPaymentRequestRejectCommandError {
  errors: {
    RejectReasonComment: [string];
  };
}
