import { Product } from 'services/AgentService/types';

export const contractTableColumns: TableHeading[] = [
  {
    attributeName: 'number',
    name: 'Номер договора',
    renderColumn: (column) => column as string,
  },
  {
    attributeName: 'clientFullName',
    name: 'ФИО Клиента',
    renderColumn: (column) => column as string,
  },
  {
    attributeName: 'product',
    name: 'Наименование услуги/работы',
    renderColumn: (column) => (column as Product)?.name,
  },
  {
    attributeName: 'creditDate',
    name: 'Дата',
    renderColumn: (column) => column as string,
  },
  {
    attributeName: 'creditAmount',
    name: 'Сумма по услуге',
    renderColumn: (column) => column as string,
  },
  {
    attributeName: 'rewardPercent',
    name: 'Вознаграждение',
    renderColumn: (column) => column as string,
  },
  {
    attributeName: 'rewardAmount',
    name: 'Вознаграждение, руб',
    renderColumn: (column) => column as string,
  },
];
