import { AgentOrClientOrderStatusForTable } from 'components/AgentOrClientOrderStatusForTable';
import { Tab } from 'components/redesign/Tabs/types';
import { OrderDetailsStatusEnum } from 'services/AgentService/types';
import { formatAmount, getDateWithTimezone } from 'utils/helpers';
import { CustomerRepresentativeOrderTabsEnum } from './types';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';
import { ROUBLE_SIGN } from 'utils/settings';

export const customerRepresentativeTabs: Tab<CustomerRepresentativeOrderTabsEnum>[] = [
  {
    link: '/client/customerrepresentative/orders/work/awaiting',
    orderType: CustomerRepresentativeOrderTabsEnum.awaiting,
    name: 'Ожидающие оплаты ',
  },
  {
    link: '/client/customerrepresentative/orders/work/active',
    orderType: CustomerRepresentativeOrderTabsEnum.active,
    name: 'Требующие действий',
  },
  {
    link: '/client/customerrepresentative/orders/work/unconfirmed',
    orderType: CustomerRepresentativeOrderTabsEnum.unconfirmed,
    name: 'С неподтверждёнными оплатами ',
  },
  {
    link: '/client/customerrepresentative/orders/work/blocked',
    orderType: CustomerRepresentativeOrderTabsEnum.blocked,
    name: 'С заблокированными пользователями ',
  },
  {
    link: '/client/customerrepresentative/orders/work/canceled',
    orderType: CustomerRepresentativeOrderTabsEnum.canceled,
    name: 'Отменённые заявки',
  },
];

const repeatingTableHeadings: TableHeading[] = [
  {
    attributeName: 'number',
    name: 'Номер заявки',
    renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  },
  {
    attributeName: 'creationTime',
    name: 'Дата создания',
    renderColumn: (column) => (
      <span className="whitespace-nowrap">
        {getDateWithTimezone(String(column), undefined, 'dd.MM.yyyy HH:mm')}
      </span>
    ),
  },
  {
    attributeName: 'paymentAmount',
    name: 'Сумма выплаты',
    renderColumn: (column) => (
      <span className="whitespace-nowrap">
        {formatAmount(+column, { minimumFractionDigits: 2 })}&nbsp;{ROUBLE_SIGN}
      </span>
    ),
  },
  {
    attributeName: 'statusUpdateTime',
    name: 'Дата перехода в статус',
    renderColumn: (column) => (
      <span className="whitespace-nowrap">
        {getDateWithTimezone(String(column), undefined, 'dd.MM.yyyy HH:mm')}
      </span>
    ),
  },
  {
    attributeName: 'cityName',
    name: 'Населенный пункт',
    renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  },
  {
    attributeName: 'agentOrganizationFullName',
    name: 'Исполнитель',
    renderColumn: (column, rowData) => {
      const blocked = rowData.ftsStatusName !== 'Является самозанятым';
      return (
        <>
          <span
            className={classNames('whitespace-nowrap', {
              'text-danger': blocked,
            })}
            data-tooltip-id="ftsStatusName-tooltip"
            data-tooltip-content={String(rowData.ftsStatusName)}
          >
            {blocked && '! '}
            {column as string}
          </span>
          <Tooltip id="ftsStatusName-tooltip" place="bottom" style={{ zIndex: 9999 }} />
        </>
      );
    },
  },
];

const awaitingOrdersTableHeadings: TableHeading[] = [
  //  TODO чекбоксы скрыты до дальнейшей реализации АПИ (Рычков А)

  // {
  //   attributeName: 'checkBox',
  //   name: '',
  //   renderColumn: (column, rowData) => <Checkbox checked onClick={() => console.log(rowData)} />,
  // },
  ...repeatingTableHeadings,
];

const activeOrdersTableHeadings: TableHeading[] = [
  //  TODO чекбоксы скрыты до дальнейшей реализации АПИ (Рычков А)

  // {
  //   attributeName: 'checkBox',
  //   name: '',
  //   renderColumn: (column) => <Checkbox checked />,
  // },
  ...repeatingTableHeadings,
  {
    attributeName: 'status',
    name: 'Статус',
    renderColumn: (column) => (
      <AgentOrClientOrderStatusForTable status={column as OrderDetailsStatusEnum} />
    ),
  },
];

const unconfirmedOrdersTableHeadings: TableHeading[] = [
  //  TODO чекбоксы скрыты до дальнейшей реализации АПИ (Рычков А)

  // {
  //   attributeName: 'checkBox',
  //   name: '',
  //   renderColumn: (column, rowData) => <Checkbox checked />,
  // },
  ...repeatingTableHeadings,
  {
    attributeName: 'status',
    name: 'Статус чека',
    renderColumn: (column) => (
      <AgentOrClientOrderStatusForTable status={column as OrderDetailsStatusEnum} />
    ),
  },
];

const blockedOrdersTableHeadings: TableHeading[] = [
  //  TODO чекбоксы скрыты до дальнейшей реализации АПИ (Рычков А)

  // {
  //   attributeName: 'checkBox',
  //   name: '',
  //   renderColumn: (column) => <Checkbox checked />,
  // },
  ...repeatingTableHeadings,
  {
    // attributeName: 'status',
    attributeName: 'status',
    name: 'Причина блокировки',
    renderColumn: (column) => (
      <AgentOrClientOrderStatusForTable status={column as OrderDetailsStatusEnum} />
    ),
  },
];

const canceledOrdersTableHeadings: TableHeading[] = [...repeatingTableHeadings];

const paidOrdersTableHeadings: TableHeading[] = [...repeatingTableHeadings];

export const customerRepresentativeWorkTableHeadings = {
  [CustomerRepresentativeOrderTabsEnum.awaiting]: awaitingOrdersTableHeadings,
  [CustomerRepresentativeOrderTabsEnum.active]: activeOrdersTableHeadings,
  [CustomerRepresentativeOrderTabsEnum.unconfirmed]: unconfirmedOrdersTableHeadings,
  [CustomerRepresentativeOrderTabsEnum.blocked]: blockedOrdersTableHeadings,
  [CustomerRepresentativeOrderTabsEnum.canceled]: canceledOrdersTableHeadings,
  // [CustomerRepresentativeOrderTabsEnum.paid]: paidOrdersTableHeadings,
};
