import classNames from 'classnames';
import { FC } from 'react';
import { AlertBannerBlockProps } from './types';
import { IconArrowLeft } from 'assets/icons';

export const AlertBannerBlock: FC<AlertBannerBlockProps> = ({
  className,
  icon,
  title,
  description,
  buttonName,
  onClick,
}) => {
  return (
    <section
      className={classNames(
        'mb-6 flex w-full flex-col gap-2 rounded-[10px] bg-pink-10 p-4 shadow-3xl',
        className
      )}
    >
      <div className="flex gap-4">
        {icon}
        <div>
          <p className="headline-bold">{title}</p>
          {description && <p className="body-normal mt-2 text-text-100">{description}</p>}
        </div>
      </div>

      {buttonName && (
        <div className="flex flex-row-reverse">
          <button className="body-bold flex items-center gap-2 !text-primary-60" onClick={onClick}>
            {buttonName} <IconArrowLeft className="rotate-180" />
          </button>
        </div>
      )}
    </section>
  );
};
