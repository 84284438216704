import { FC } from 'react';
import {
  ClientOrderDetailsViewConfirmPaymentFormProps,
  ClientOrderDetailsViewConfirmPaymentFormValues,
  ConfirmPaymentReceiptError,
} from './types';
import { useForm } from 'react-hook-form';
import { FieldDatePicker } from 'components/redesign/FieldDatePicker';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { checkPaymentOperationTime } from './consts';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ClientPaymentRequestDto } from 'services/AgentService/types';
import { AgentService } from 'services/AgentService';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { BaseError } from 'types';

export const ClientOrderDetailsViewConfirmPaymentForm: FC<
  ClientOrderDetailsViewConfirmPaymentFormProps
> = ({ order, setActualOrder }) => {
  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<ClientOrderDetailsViewConfirmPaymentFormValues>();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { mutateAsync: confirmPaymentReceipt } = useMutation<
    AxiosResponse<ClientPaymentRequestDto>,
    AxiosError<BaseError>,
    { id: string; concurrencyStamp: string; paymentOperationTime: string }
  >({
    mutationKey: 'confirmPaymentReceipt',
    mutationFn: ({ id, concurrencyStamp, paymentOperationTime }) =>
      AgentService.confirmPaymentReceipt(id, concurrencyStamp, paymentOperationTime),
    onSuccess: ({ data }) => {
      toast.success('Подтверждение выплаты прошло успешно');
      dispatch(modalActions.closeModal());
      setActualOrder(data);
      queryClient.refetchQueries('getClientOrder');
    },
    onError: (data) => {
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        clearErrors();
        const toastErrors: string[] = [];

        checkPaymentOperationTime(
          data.paymentOperationTime ?? new Date(),
          new Date(order.creationTime),
          setError,
          toastErrors
        );

        if (toastErrors.length) {
          return;
        }

        order.concurrencyStamp &&
          data.paymentOperationTime &&
          confirmPaymentReceipt({
            id: order.id,
            concurrencyStamp: order.concurrencyStamp ?? '',
            paymentOperationTime: format(data.paymentOperationTime, 'yyyy-MM-dd'),
          });
      })}
    >
      <FieldDatePicker
        name="paymentOperationTime"
        control={control}
        label="Дата"
        errorMessage={errors.paymentOperationTime?.message}
        clearErrors={clearErrors}
      />
      <div className="z-10 mt-5 flex justify-end">
        <Button
          variant={ButtonVariant.GHOST}
          className="mr-5"
          type="button"
          onClick={() => dispatch(modalActions.closeModal())}
        >
          ОТМЕНИТЬ
        </Button>
        <Button variant={ButtonVariant.PRIMARY} disabled={!watch('paymentOperationTime')}>
          СОХРАНИТЬ
        </Button>
      </div>
    </form>
  );
};
