export const cancelReceiptOptions = [
  {
    value: '2',
    label: 'Сформирован ошибочно',
  },
  {
    value: '1',
    label: 'Возврат средств',
  },
];
