import { FC, useState } from 'react';
import { CustomInputProps } from './types';
import classNames from 'classnames';
import { ValidationError } from '../ValidationError';

export const CustomInput: FC<CustomInputProps> = ({
  value = '',
  onChange,
  label,
  maxValue,
  decimal,
  errorMessage,
}) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const [formatError, setFormatError] = useState<string | null>(null);

  const validateAndFormatInput = (input: string): string | null => {
    const regex = new RegExp(`^\\d{0,${input.split('.')[0].length}}(?:\\.\\d{0,${decimal}})?$`);

    if (input.split('.').length > 2) {
      return null;
    }

    const numericValue = parseFloat(input.replace(/ /g, ''));
    if (!isNaN(numericValue) && numericValue > maxValue) {
      return null;
    }

    if (input.endsWith('.')) {
      const [integer] = input.split('.');
      return integer?.length <= maxValue.toString().length ? input : null;
    }

    if (!regex.test(input)) {
      return null;
    }

    const [integerPart, decimalPart = ''] = input.split('.');
    if (integerPart.length > 8) {
      return null;
    }
    const formattedDecimal = decimalPart.slice(0, decimal);
    return decimalPart ? `${integerPart}.${formattedDecimal}` : integerPart;
  };

  const formatWithSpaces = (value: string): string => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\s/g, '');
    const formattedValue = validateAndFormatInput(newValue);
    if (formattedValue !== null) {
      setFormatError(null);
      setInputValue(formattedValue);
      onChange?.(formattedValue);
    } else {
      setFormatError('Неверный формат ввода');
    }
  };

  const handleBlur = () => {
    if (!inputValue) return;
    const [integer, decimal = ''] = inputValue.split('.');
    const formattedInteger = formatWithSpaces(integer);
    const formattedValue =
      decimal.length < 2
        ? `${formattedInteger}.${decimal.padEnd(2, '0')}`
        : `${formattedInteger}.${decimal}`;
    setInputValue(formattedValue);
    onChange?.(formattedValue);
  };

  return (
    <div>
      <div className="flex grow flex-col justify-end pb-1">
        {label && <div className="body-normal mb-1 !text-text-50">{label}</div>}
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          className={classNames(
            'headline-normal border-0 border-b-2 border-solid border-light-50 !text-text-100 focus:border-primary-60',
            {
              '!border-danger': errorMessage,
            }
          )}
        />
      </div>
      {errorMessage && <ValidationError errorMessage={errorMessage} />}
      {formatError && <ValidationError errorMessage={formatError} />}
    </div>
  );
};
