import { useAppDispatch } from 'hooks/useAppDispatch';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { CancelReceiptForm } from '../CancelReceiptForm';
import { ConfirmReceiptFormProps } from './types';
import { FC } from 'react';

export const ConfirmReceiptForm: FC<ConfirmReceiptFormProps> = ({ id, refetchPathId }) => {
  const dispatch = useAppDispatch();

  const cancelHandler = () => {
    dispatch(modalActions.closeModal());
  };

  const confirmHandler = () => {
    dispatch(modalActions.closeModal());
    dispatch(
      modalActions.handleOpenModal({
        content: <CancelReceiptForm id={id} refetchPathId={refetchPathId} />,
        options: {
          title: 'Выберите причину',
          modalClassName: 'w-full',
        },
      })
    );
  };

  return (
    <div className="w-full p-4 sm:min-w-[500px] sm:p-0">
      <p className="headline-normal text-text-100">Вы действительно хотите аннулировать чек?</p>
      <div className="mt-6 flex flex-col-reverse gap-6 sm:flex-row sm:justify-end">
        <Button
          variant={ButtonVariant.GHOST}
          onClick={cancelHandler}
          className="paragraphs-bold mb-1 w-[130px] border border-solid border-text-50 !text-text-100"
        >
          ОТМЕНА
        </Button>
        <Button className="paragraphs-bold mb-1 w-[190px]" onClick={confirmHandler}>
          ПОДТВЕРДИТЬ
        </Button>
      </div>
    </div>
  );
};
