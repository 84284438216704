import { AgentOrClientOrderStatusForTable } from 'components/AgentOrClientOrderStatusForTable';
import { OrderDetailsStatusEnum } from 'services/AgentService/types';
import { getDateWithTimezone } from 'utils/helpers';

export const customerRepresentativeResultsTableHeadings: TableHeading[] = [
  {
    attributeName: 'number',
    name: 'Номер заявки',
    renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  },
  {
    attributeName: 'creationTime',
    name: 'Дата создания',
    renderColumn: (column) => (
      <span className="whitespace-nowrap">
        {getDateWithTimezone(String(column), undefined, 'dd.MM.yyyy HH:mm')}
      </span>
    ),
  },
  // TODO появится в будущем, после реализации MVP

  // {
  //   attributeName: 'creationTime',
  //   name: 'Выплачено/В процессе/Ошибки',
  //   renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  // },
  {
    attributeName: 'cityName',
    name: 'Населенный пункт',
    renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  },
  {
    attributeName: 'agentOrganizationFullName',
    name: 'Исполнитель',
    renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  },
  {
    attributeName: 'status',
    name: 'Статус',
    renderColumn: (column) => {
      return <AgentOrClientOrderStatusForTable status={column as OrderDetailsStatusEnum} />;
    },
  },
];
