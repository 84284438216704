import { Tabs } from 'components/redesign/Tabs';
import { useQueries } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AgentService } from 'services/AgentService';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { Table } from 'ui/redesign/Table';
import { TableCard } from 'ui/redesign/Table/TableCard';
import { TableLoading } from 'ui/redesign/Table/TableLoading';
import { Spinner } from 'ui/Spinner';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { GetClientOrders } from 'services/AgentService/types';
import { customerRepresentativeTabs, customerRepresentativeWorkTableHeadings } from './consts';
import { CustomerRepresentativeOrderTabsEnum } from './types';
import { BalanceInformerViewOrUpdate } from './BalanceInformerViewOrUpdate';

export const CustomerRepresentativeWorkPage = () => {
  const customerRepresentativeOrderTypes = Object.values(CustomerRepresentativeOrderTabsEnum);
  const navigate = useNavigate();
  const { tab = CustomerRepresentativeOrderTabsEnum.awaiting } = useParams<{
    tab: CustomerRepresentativeOrderTabsEnum;
  }>();
  const [ordersCount, setOrdersCount] = useState<
    Record<CustomerRepresentativeOrderTabsEnum, number>
  >({} as Record<CustomerRepresentativeOrderTabsEnum, number>);

  const queryResults = useQueries(
    customerRepresentativeOrderTypes.map((_, tab) => ({
      queryKey: ['getClientOrdersWork', tab + 6],
      queryFn: () => AgentService.getClientOrders(tab + 6, false, 999),
      onSuccess: (data: AxiosResponse<GetClientOrders>) => {
        setOrdersCount((prevState) => ({
          ...prevState,
          [customerRepresentativeOrderTypes[tab]]: data.data.totalCount,
        }));
      },
    }))
  );

  const isFetching = queryResults.some((query) => query.isFetching);

  const handleRowClick = (tableRow: TableData): void => {
    navigate(`/client/customerrepresentative/orders/work/${tab}/${tableRow.id}`);
  };

  useEffect(() => {
    const index = Object.values(CustomerRepresentativeOrderTabsEnum).findIndex(
      (key) => CustomerRepresentativeOrderTabsEnum[key] === tab
    );
    queryResults[index].refetch();
  }, [tab]);

  if (!Object.keys(CustomerRepresentativeOrderTabsEnum).includes(tab))
    return <Navigate to="/client/orders/awaiting" />;

  return (
    <>
      {queryResults.map((query, index) => {
        if (customerRepresentativeOrderTypes[index] !== tab) {
          return;
        }
        const id = customerRepresentativeOrderTypes[index];
        const { data } = query;
        // TODO блок с балансом скрыт до дальнейшей реализации АПИ (Рычков А)

        // const dataWithCheckbox =
        //   query?.data?.data.items &&
        //   query.data.data.items.map((item) => {
        //     return { ...item, checkBox: false };
        //   });
        return (
          <PageWrapper title="Работа с заявками" key={id}>
            {/* TODO блок с балансом скрыт до дальнейшей реализации АПИ (Рычков А) */}

            {/* <div className="flex flex-row gap-5">
              <div className="mb-9 flex max-w-[650px] flex-row items-center justify-between gap-4 rounded-[10px] p-4 shadow-3xl">
                <div className="flex flex-row items-center gap-4">
                <CashIcon />
                <div>
                  <p className="headline-normal">
                    Баланс: <span className="headline-bold"> 921 432,00 ₽</span>
                  </p>
                  <p className="headline-normal">
                    Время обновления: <span className="headline-bold">11.11.2024, 15:02</span>
                  </p>
                </div>
              </div>
                <CustomerRepresentativeActionBtn />
              </div>
              <div className="mb-9 flex max-w-[650px] flex-col rounded-[10px] p-4 shadow-3xl">
                <p className="headline-normal">
                  Общая сумма вознаграждения Исполнителей:{' '}
                  <span className="headline-bold">327 942,00 ₽</span>
                </p>
                <p className="headline-normal">
                  Общая сумма комиссий: <span className="headline-bold">5 738,96 ₽</span>
                </p>
                <p className="headline-normal">
                  Общая сумма к пополнению: <span className="headline-bold">333 680,99 ₽</span>
                </p>
              </div>
            </div> */}
            <BalanceInformerViewOrUpdate />
            <section className="flex flex-col gap-6">
              {isFetching ? (
                <Spinner />
              ) : (
                <Tabs
                  tabs={customerRepresentativeTabs}
                  ordersCounts={ordersCount}
                  isFetching={isFetching}
                  activeTab={tab}
                />
              )}
              {isFetching ? (
                <TableLoading />
              ) : (
                <>
                  {data?.data.totalCount === 0 ? (
                    <div className="min-h-[90vh] overflow-hidden rounded-[10px] bg-base shadow-3xl">
                      <div className="max-w-[550px] sm:p-4">
                        Данные по вкладке отсутствуют или не найдены совпадения по поисковому
                        запросу
                      </div>
                    </div>
                  ) : (
                    <>
                      <TableCard>
                        <Table
                          tableHeading={customerRepresentativeWorkTableHeadings[tab]}
                          tableData={(data?.data.items || []) as unknown as TableData[]}
                          onRowClick={handleRowClick}
                        />
                      </TableCard>
                    </>
                  )}
                </>
              )}
            </section>
          </PageWrapper>
        );
      })}
    </>
  );
};
