import { OptionType } from 'ui/redesign/Select/types';

export interface CreateAgentReceiptFormValues {
  purchaseTime: Date | undefined;
  buyerTin: string | undefined;
  buyerName: string | undefined;
  sourceIncome: OptionType<number> | undefined;
  services: CreateAgentReceiptFormValuesService[] | undefined;
  summary: string | undefined;
}

export interface CreateAgentReceiptFormValuesService {
  name: string | undefined;
  cost: string | undefined;
}

export enum RedirectCreateFtsReceiptErrors {
  RECEIPT_ALREADY_IN_PROGRESS = 'Чек создается',
  FNS_TIMEOUT = 'Чек сформирован Оффлайн',
  TECHNICAL_ERROR = 'Техническая ошибка формирования чека',
  REQUEST_VALIDATION_ERROR = 'Не возможно зарегистрировать доход. Достигнут максимальный уровень дохода',
  TAXPAYER_UNBOUND = 'Отсутствует привязка к М-про. Для осуществления операции пройдите процедуру привязки вашего ЛК к площадке М-про',
  TAXPAYER_UNREGISTERED = 'Вы не состоите на учете в качестве самозанятого. Для осуществления операции необходимо являться Самозанятым',
  INCOME_REGISTRATION_TAXPAYER_RESTRICTED = 'Не возможно зарегистрировать доход. Налоговый орган наложил запрет на регистрацию дохода',
}