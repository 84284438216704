import {
  AttachOrder,
  CommentIcon,
  ExclamationIcon,
  IconLocation,
  ListIcon,
  LocationIcon,
  WritingIcon,
} from 'assets/icons';
import classNames from 'classnames';
import { AgentOrClientOrderStatusForDetail } from 'components/AgentOrClientOrderStatusForDetail';
import { AlertBannerBlock } from 'components/redesign/AlertBannerBlock';
import { format } from 'date-fns';
import { useAppSelector } from 'hooks/useAppSelector';
import { styles } from 'pages/OrderDetails/OrderDetailsForSubOrEx/components/consts';
import {
  getClientOrderDetailsBannerTexts,
  getCustomerRepresentativeBanner,
} from 'pages/OrderDetails/utils';
import { FC, useState } from 'react';
import { getPermissions } from 'reduxStore/reducers/ProfileSlice/selectors/getPermissions';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';
import {
  LocalizedOrderReceiptStatusNameEnum,
  OrderDetailsBannerTextsEnum,
  OrderDetailsStatusEnum,
  OrderReceiptStatusNameEnum,
} from 'services/AgentService/types';
import { FileNew } from 'ui/redesign/FileNew';
import { Table } from 'ui/redesign/Table';
import { TableCard } from 'ui/redesign/Table/TableCard';
import { Textarea } from 'ui/redesign/Textarea';
import { ClientOrderDetailsViewActions } from './ClientOrderDetailsViewActions';
import { contractTableColumns } from './const';
import { ClientOrderDetailsViewTabDataProps } from './types';

export const ClientOrderDetailsViewTabData: FC<ClientOrderDetailsViewTabDataProps> = ({
  order,
}) => {
  const permissions = useAppSelector(getPermissions);
  const [actualOrder, setActualOrder] = useState(order);

  const isAgentCustomersRepresentative = permissions.includes(
    ProfilePermissions.Agent_Customers_Representative
  );
  const { title, description } =
    getClientOrderDetailsBannerTexts(
      actualOrder.receipt?.reasonNotFiscalizeIncomeName as OrderDetailsBannerTextsEnum
    ) ?? {};

  return (
    <section className="flex flex-col gap-4 py-4 sm:max-w-[950px] sm:gap-6">
      {isAgentCustomersRepresentative &&
        actualOrder.receipt?.statusName &&
        getCustomerRepresentativeBanner(
          actualOrder.receipt?.statusName as LocalizedOrderReceiptStatusNameEnum,
          actualOrder.receipt?.reasonNotFiscalizeIncomeName
        )}
      {(actualOrder.status === OrderDetailsStatusEnum.ForRework ||
        actualOrder.status === OrderDetailsStatusEnum.Rejected) && (
        <AlertBannerBlock
          title={
            <>
              <ExclamationIcon className="-mt-1" />
              <label className="ml-2 !text-danger">Заявка отклонена</label>
            </>
          }
          description={<label>Причина: {actualOrder.rejectReasonComment}</label>}
        />
      )}
      <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
        <div>
          <WritingIcon />
          <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">
            Основная информация
          </span>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Заявка</p>
            <p className="pt-[6px]">№ {actualOrder.number}</p>
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Дата создания</p>
            <p className="pt-[6px]">
              {format(new Date(actualOrder.creationTime), 'dd.MM.yyyy HH:mm')} (UTC
              {format(new Date(actualOrder.creationTime), 'XXX')})
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Статус</p>
            <AgentOrClientOrderStatusForDetail status={actualOrder.status} />
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Дата перехода в состояние</p>
            <p className="pt-[6px]">
              {format(new Date(actualOrder.statusUpdateTime), 'dd.MM.yyyy HH:mm')} (UTC
              {format(new Date(actualOrder.statusUpdateTime), 'XXX')})
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Менеджер</p>
            <p className="pt-[6px]">{actualOrder.managerFullName}</p>
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Сотрудник аккредитации</p>
            <p className="pt-[6px]">{actualOrder.accreditationEmployeeFullName ?? '-'}</p>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Исполнитель</p>
            <p className="pt-[6px]">{actualOrder.agentOrganizationFullName}</p>
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Правовая форма исполнителя</p>
            <p className="pt-[6px]">{actualOrder.ftsStatusName}</p>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Отчетный период</p>
            <p className="pt-[6px]">
              {format(new Date(actualOrder.periodStartDate), 'dd.MM.yyyy') +
                '-' +
                format(new Date(actualOrder.periodEndDate), 'dd.MM.yyyy')}
            </p>
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Вознаграждение, руб.</p>
            <h2 className="headline-bold pt-[6px]">{actualOrder.paymentAmount}</h2>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Комиссия с самозанятого, руб.</p>
            <h2 className="headline-bold h-6 pt-[6px]">{actualOrder.agentOrganizationFee}</h2>
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Комиссия с клиента, руб.</p>
            <h2 className="headline-bold h-6 pt-[6px]">{actualOrder.clientOrganizationFee}</h2>
          </div>
        </div>
        {actualOrder.receipt?.linkReceiptFts && (
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Ссылка на чек</p>
            <a href={actualOrder.receipt.linkReceiptFts} target="_blank" rel="noreferrer">
              {actualOrder.receipt.linkReceiptFts}
            </a>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
        <div className="flex items-center">
          <LocationIcon />
          <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">
            Населенный пункт
          </span>
        </div>
        <div className="ml-2 flex items-center gap-2">
          <div className="h-5 w-5">
            <IconLocation className={classNames('h-5 w-5 fill-[#9FA0A7]')} />
          </div>
          <p className={classNames('mt-2', styles.value)}>{actualOrder.cityName}</p>
        </div>
      </div>
      <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
        <div>
          <ListIcon />
          <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">Договоры</span>
        </div>
        <TableCard>
          <Table
            tableHeading={contractTableColumns}
            tableData={(actualOrder.contracts || []) as unknown as TableData[]}
          />
        </TableCard>
      </div>
      <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
        <div>
          <CommentIcon />
          <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">Комментарий</span>
        </div>
        <div className="w-full pb-1">
          <Textarea
            className="!text-text-100"
            label="Описание"
            value={actualOrder.description ?? ''}
            disabled
          />
        </div>
      </div>
      <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
        <div>
          <AttachOrder />
          <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">Вложения</span>
        </div>
        {actualOrder.files?.length !== 0 && (
          <ul className="flex flex-col gap-2">
            {actualOrder.files?.map((file) => (
              <li key={file.readLink} className="w-full max-w-[700px]">
                <FileNew file={file} isHideDeleteFileButton={true} />
              </li>
            ))}
          </ul>
        )}
      </div>
      <ClientOrderDetailsViewActions order={actualOrder} setActualOrder={setActualOrder} />
    </section>
  );
};
