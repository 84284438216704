import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';

export const AuthProviderRouting: FC<PropsWithChildren<object>> = ({ children }) => {
  const oidcAuth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const location = useLocation();
  const isUserAagreementPage = location.pathname.includes('user-agreement');

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !oidcAuth.isAuthenticated &&
      !oidcAuth.activeNavigator &&
      !oidcAuth.isLoading &&
      !hasTriedSignin &&
      !isUserAagreementPage
    ) {
      oidcAuth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [oidcAuth, hasTriedSignin, isUserAagreementPage]);

  return <>{children}</>;
};
