import { FtsOrganizationService } from 'services/FtsOrganizationService';
import { AlertBannerDataType, ShowBannerForAgentParams } from './types';
import { profileActions } from 'reduxStore/reducers/ProfileSlice';
import AlertBannertImg from 'assets/images/alert-banner.png';

export const showBannerForAgent = ({
  status,
  unpaidAmount,
  debtAmount,
  bonusAmount,
  navigate,
  dispatch,
}: ShowBannerForAgentParams): AlertBannerDataType[] => {
  const defaultImg = <img src={AlertBannertImg} alt="" className="h-20 w-20" />;
  const amountBanner = unpaidAmount
    ? {
        title: () => (
          <>
            <p className="!text-danger">
              Налог к оплате:{' '}
              {String(unpaidAmount.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽
            </p>
            <p className="body-normal mt-1 !text-danger">
              <span className="!text-text-100">В том числе просроченно: </span>
              {debtAmount
                ? String(debtAmount.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                : 0}{' '}
              ₽
            </p>
          </>
        ),
        description: () => (
          <p>
            Уважаемый пользователь, своевременно производите оплату налогов, во избежание просрочек
            и начисления пеней.
          </p>
        ),
      }
    : {};

  const bonusAmountBanner = bonusAmount
    ? {
        title: () => (
          <p className="!text-primary-80">
            Баланс бонусного счета:{' '}
            {String(bonusAmount.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽
          </p>
        ),
        description: () => <p>Ваш бонус на оплату налога</p>,
        className: '!bg-[#E8DEF8]',
      }
    : {};
  switch (status) {
    case 1:
      return [
        unpaidAmount ? amountBanner : {},
        bonusAmount ? bonusAmountBanner : {},
        {
          title: () => (
            <p>
              Разрешения в ФНС: <span className="!text-danger">Не предоставлены</span>
            </p>
          ),
          description: () => (
            <>
              <p className="mb-4">
                Уважаемый пользователь, для продолжения работы на площадке требуется предоставить
                разрешения в личном кабинете ФНС.
              </p>
              <p>
                После предоставления разрешений Вам будет подключена опция автоматической
                фискализации чеков. Теперь Вам не нужно будет пробивать чеки вручную - после
                выполнения заявки мы сами сформируем чек и передадим его клиенту.
              </p>
            </>
          ),
          button: 'Подключить',
          onClick: async () =>
            await FtsOrganizationService.bind()
              .then((response) => {
                dispatch(profileActions.setFtsStatusForAgent(response.data.ftsStatus));
              })
              .catch((error) => console.error('Произошла ошибка при вызове метода', error)),
          icon: defaultImg,
        },
      ];
    case 2:
      return [
        unpaidAmount ? amountBanner : {},
        bonusAmount ? bonusAmountBanner : {},
        {
          title: () => (
            <p>
              Разрешения в ФНС: <span className="!text-danger">Ожидает подтверждения</span>
            </p>
          ),
          description: () => (
            <p>Если вы предоставили разрешения в приложении «Мой налог», нажмите на кнопку ниже</p>
          ),
          button: 'Подтвердить подключение',
          onClick: async () =>
            await FtsOrganizationService.bind()
              .then((response) => {
                dispatch(profileActions.setFtsStatusForAgent(response.data.ftsStatus));
              })
              .catch((error) => console.error('Произошла ошибка при вызове метода', error)),
          icon: defaultImg,
        },
      ];
    case 3:
      return [
        unpaidAmount ? amountBanner : {},
        bonusAmount ? bonusAmountBanner : {},
        {
          title: () => (
            <p>
              Разрешения в ФНС: <span className="!text-[#D26629]">Проверка годового лимита</span>
            </p>
          ),
          description: () => (
            <p>
              Уважаемый пользователь, осуществляется проверка годового лимита доходов. После
              получения информации от ФНС будет предоставлен доступ к работе с площадкой.
            </p>
          ),
          icon: defaultImg,
          className: 'bg-[#FFF6D9]',
        },
      ];
    case 4:
      return [
        unpaidAmount ? amountBanner : {},
        bonusAmount ? bonusAmountBanner : {},
        {
          title: () => <p className="!text-danger">Превышение годового лимита</p>,
          description: () => (
            <p>
              Уважаемый пользователь, для продолжения работы необходимо удовлетворять требованиям по
              годовому доходу.
            </p>
          ),
        },
      ];
    case 5:
      return [
        unpaidAmount ? amountBanner : {},
        bonusAmount ? bonusAmountBanner : {},
        {
          title: () => (
            <p>
              Статус Самозанятости: <span className="!text-danger">Отсутствует</span>
            </p>
          ),
          description: () => (
            <p>
              Уважаемый пользователь, для продолжения работы на площадке как самозанятый необходимо
              получить соответствующий налоговый статус.{' '}
              <a
                className="cursor-pointer font-montserrat font-semibold text-blue"
                href={'https://m-core.pro/self-employed.html'}
                target="blank"
              >
                Инструкция по получению статуса.
              </a>
            </p>
          ),
          button: 'Подключить',
          onClick: async () =>
            await FtsOrganizationService.bind()
              .then((response) => {
                dispatch(profileActions.setFtsStatusForAgent(response.data.ftsStatus));
              })
              .catch((error) => console.error('Произошла ошибка при вызове метода', error)),
          icon: defaultImg,
        },
      ];
    case 6:
      return [unpaidAmount ? amountBanner : {}, bonusAmount ? bonusAmountBanner : {}];
    case 7:
      return [
        unpaidAmount ? amountBanner : {},
        bonusAmount ? bonusAmountBanner : {},
        {
          title: () => <p className="!text-danger">Обнаружено несоответствие номера телефона</p>,
          description: () => (
            <p>
              Уважаемый пользователь, номер телефона в ЛК ФНС отличается от указанного в профиле.
              Обновите номер телефона в профиле.
            </p>
          ),

          button: 'Перейти в профиль',
          onClick: () => navigate('/profile/contacts'),
          icon: defaultImg,
        },
        {
          title: () => <p className="!text-danger">Обнаружено несоответствие ФИО в ЛК и в ФНС</p>,
          description: () => (
            <p>
              Уважаемый пользователь, для продолжения работы на площадке требуется актуализировать
              свои данные.
            </p>
          ),
          button: 'Перейти в анкету на модерацию',
          onClick: () => navigate('/moderation-request'),
          icon: defaultImg,
        },
      ];
    case 8:
      return [
        unpaidAmount ? amountBanner : {},
        bonusAmount ? bonusAmountBanner : {},
        {
          title: () => <p className="!text-danger">Обнаружено несоответствие ФИО в ЛК и в ФНС</p>,
          description: () => (
            <p>
              Уважаемый пользователь, для продолжения работы на площадке требуется актуализировать
              свои данные.
            </p>
          ),
          button: 'Перейти в анкету на модерацию',
          onClick: () => navigate('/moderation-request'),
          icon: defaultImg,
        },
      ];
    case 9:
      return [
        unpaidAmount ? amountBanner : {},
        bonusAmount ? bonusAmountBanner : {},
        {
          title: () => <p className="!text-danger">Обнаружено несоответствие номера телефона</p>,
          description: () => (
            <p>
              Уважаемый пользователь, номер телефона в ЛК ФНС отличается от указанного в профиле.
              Обновите номер телефона в профиле.
            </p>
          ),
          button: 'Перейти в профиль',
          onClick: () => navigate('/profile/contacts'),
          icon: defaultImg,
        },
      ];
    case 10:
      return [unpaidAmount ? amountBanner : {}, bonusAmount ? bonusAmountBanner : {}];
    case 11:
      return [
        unpaidAmount ? amountBanner : {},
        bonusAmount ? bonusAmountBanner : {},
        {
          title: () => (
            <p>
              Статус в ФНС: <span className="!text-[#D26629]">Обработка запроса</span>
            </p>
          ),
          description: () => (
            <p>Уважаемый пользователь, запрос в обработке. Ожидание ответа от ФНС. </p>
          ),
          icon: defaultImg,
          className: 'bg-[#FFF6D9]',
        },
      ];
    case 12:
      return [
        unpaidAmount ? amountBanner : {},
        bonusAmount ? bonusAmountBanner : {},
        {
          title: () => (
            <p>
              Статус в ФНС: <span className="!text-danger">Возникла непредвиденная ошибка</span>
            </p>
          ),
          description: () => (
            <p>
              Уважаемый пользователь, при обработке запроса возникла непредвиденная ошибка.
              Обратитесь в техническую поддержку.
            </p>
          ),
          icon: defaultImg,
        },
      ];
    default:
      return [];
  }
};
