import { FileCommonIcon, FileDeleteIcon, FileDownloadIcon, FilePreviewIcon } from 'assets/icons';
import useMediaQuery from 'hooks/useMediaQuery';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { FileDescriptorService } from 'services/FileDescriptorService';
import { handleFileDownloadNew, viewFileSize } from 'utils/helpers';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { FileNewProps } from './types';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { FilePreview } from '../FilePreview';

export const FileNew: FC<FileNewProps> = ({
  file,
  disabled = false,
  isHideDeleteFileButton = false,
  isHidePreviewFileButton = false,
  isHideDownloadFileButton = false,
}) => {
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  const dispatch = useAppDispatch();

  // Просмотр файлов
  const { refetch: fetchSingleFile } = useQuery(
    ['fetchSingleFile'],
    () => FileDescriptorService.downloadSingleFile(file.readLink ? file.readLink : ''),
    {
      enabled: false,
      onSuccess: (res) => {
        dispatch(
          modalActions.handleOpenModal({
            content: (
              <FilePreview
                file={{
                  content: res.data.content ? res.data.content : '',
                  type: res.data.contentType ? res.data.contentType : '',
                }}
              />
            ),
            options: {
              title: 'Просмотр',
              contentClassName: 'flex w-screen sm:w-auto items-center justify-center',
            },
          })
        );
      },
    }
  );

  // Скачивание файлов
  const { refetch: downloadSingleFile } = useQuery(
    [`downloadSingleFile${file.readLink}`],
    () => FileDescriptorService.downloadSingleFile(file.readLink ? file.readLink : ''),
    {
      enabled: false,
      onSuccess: (res) => {
        handleFileDownloadNew(res.data);
      },
    }
  );

  return (
    <div className="mb-1 flex items-center justify-between rounded-[0.625rem] border border-light-50 p-3 last-of-type:mb-0">
      <div className="mr-2 self-center">
        <FileCommonIcon />
      </div>
      <div className="callout-normal flex grow flex-col text-ellipsis break-all !text-text-100 sm:text-xs md:text-base">
        <span>{file.name}</span>
        <span className="footnote-normal text-text-50">
          {file.size ? viewFileSize(file.size) : ''}
        </span>
      </div>
      <div className="flex items-center gap-3">
        {!isHidePreviewFileButton && !isSmall && (
          <button
            type="button"
            className="flex h-5 w-5 items-center justify-center"
            onClick={() => fetchSingleFile()}
          >
            <FilePreviewIcon />
          </button>
        )}
        {!isHideDownloadFileButton && (
          <button
            type="button"
            className="flex h-5 w-5 cursor-pointer items-center justify-center"
            onClick={() => downloadSingleFile()}
          >
            <FileDownloadIcon className="text-primary-60" />
          </button>
        )}
        {!isHideDeleteFileButton && (
          <button
            type="button"
            className="flex h-5 w-5 items-center justify-center disabled:cursor-not-allowed"
            disabled={disabled}
          >
            <FileDeleteIcon />
          </button>
        )}
      </div>
    </div>
  );
};
