import classNames from 'classnames';
import { FC } from 'react';
import { OrderDetailsStatusEnum } from 'services/AgentService/types';
import { OrderTableStatuses } from 'utils/helpers';

export const AgentOrClientOrderStatusForDetail: FC<{ status: OrderDetailsStatusEnum }> = ({
  status,
}) => {
  return (
    <h2
      className={classNames('headline-bold pt-[6px]', '!text-' + OrderTableStatuses[status].color)}
    >
      {OrderTableStatuses[status].label
        ? OrderTableStatuses[status].label
        : OrderTableStatuses[status].name}
    </h2>
  );
};
