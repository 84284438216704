import { FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AgentService } from 'services/AgentService';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import TabGroup from 'ui/TabGroup';
import { OrderDetailsSkeleton } from '../OrderDetailsForSubOrEx/OrderDetailsSkeleton';
import { OrderDetailsForAgentTab } from './OrderDetailsForAgentTab';

export const AgentOrderDetailsPage: FC = () => {
  const { id: orderId } = useParams();
  const { isLoading, isFetching, data } = useQuery(['getAgentOrder'], () =>
    AgentService.getAgentOrder(orderId ? orderId : '')
  );
  const order = data?.data;

  const tabItems = [
    {
      title: 'Данные по заявке',
      permissions: true,
    },
  ];
  const tabContent = [order ? <OrderDetailsForAgentTab order={order} key="1" /> : null];

  if (isLoading || isFetching) {
    return <OrderDetailsSkeleton />;
  }

  return (
    <PageWrapper title={`Заявка № ${order?.number ?? ''}`}>
      <section className="flex flex-col">
        <div className="flex flex-col">
          <TabGroup tabItems={tabItems} tabPanelsContent={tabContent} />
        </div>
      </section>
    </PageWrapper>
  );
};
