import { CanceledIcon, CheckApproovedIcon, CreatingIcon, OfflineIcon } from 'assets/icons';
import { FC } from 'react';
import { StatusIconProps } from './types';

export const StatusIcon: FC<StatusIconProps> = ({ status }) => {
  switch (status) {
    case 1:
      return (
        <div className="flex items-center gap-2">
          <span className="sm:headline-normal headline-normal !text-text-50">Создается</span>
          <div className="h-5 w-5">
          <CreatingIcon className="self-center !text-text-50" />
          </div>
        </div>
      );
    case 2:
      return (
        <div className="flex items-center gap-2">
          <span className="sm:headline-normal headline-normal !text-success-60">
            Зарегистрирован в ФНС
          </span>
          <div className="h-5 w-5">
          <CheckApproovedIcon className="self-center" />
          </div>
        </div>
      );
    case 3:
      return (
        <div className="flex items-center gap-2">
          <span className="headline-normal !text-success-60">Оффлайн</span>
          <div className="h-5 w-5">
          <OfflineIcon className="self-center" />
          </div>
        </div>
      );
    case 4:
      return (
        <div className="flex items-center gap-2">
          <span className="sm:headline-normal headline-normal !text-danger">Аннулирован</span>
          <div className="h-5 w-5">

          <CanceledIcon className="self-center" />
          </div>
        </div>
      );
    case 5:
      return (
        <div className="flex items-center gap-2">
          <span className="sm:headline-normal headline-normal !text-danger">
            Ошибка при формировании чека
          </span>
          <div className="h-5 w-5">
            <CanceledIcon className="self-center !text-danger" />
          </div>
        </div>
      );
    default:
      return null;
  }
};
