import { Range } from 'react-date-range';
import { OptionType } from 'ui/redesign/Select/types';

export interface ClientOrderCreateForm {
  cityName: OptionType;
  month: OptionType;
  year: OptionType;
  agentOrganizationFullName: OptionType;
  paymentAmount: string | undefined;
  contracts: ContractForm[];
  description: string;
  period: Range;
  concurrencyStamp: string;
  clientOrganizationFee: string | undefined;
  agentOrganizationFee: string | undefined;
}

export interface ContractForm {
  id?: string;
  number: string | undefined;
  clientFullName: string | undefined;
  product: OptionType;
  creditDate: string | undefined;
  creditAmount: string | number | undefined;
  rewardPercent: string | number | undefined;
  rewardAmount: string | number | undefined;
}

export enum ClientRequestHandler {
  DRAFT = 'DRAFT',
  SENDFORSIGN = 'SENDFORSIGN',
}
