import axios, { AxiosError } from 'axios';
import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import YaMap from 'components/Map';
import { DropDown } from 'ui/DropDown';
import { Modal } from 'ui/Modal';
import { Spinner } from 'ui/Spinner';
import { FinalStatusForm } from './OrderTab/FinalStatusForm';
import { RefuseStatusForm } from './OrderTab/RefuseStatusForm';

import { request } from 'api';
import { endpoints } from 'api/endpoints';
import {
  AttachOrder,
  Education2Icon,
  History2Icon,
  IconCombinedShape,
  IconLightning,
  IconLocation,
  IconTime,
  RateIcon,
  WritingIcon,
} from 'assets/icons';
import classNames from 'classnames';
import { ExecutorTypeEnum } from 'enums/executorType';
import { OrderStatusesEnum } from 'enums/orderStatusesEnum';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useOrderRoute } from 'hooks/useOrderRoute';
import { useTimeRemaining } from 'hooks/useTimeRemaining';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';
import { getProfileID } from 'reduxStore/reducers/ProfileSlice/selectors/getProfileID';
import { getTimezone } from 'reduxStore/reducers/ProfileSlice/selectors/getTimezone';
import { FileService } from 'services/FileService';
import {
  ErrorResponse,
  Order,
  OrderBecomeExecutorData,
  OrderFinishedByExecutorData,
  OrderRefusedByExecutorData,
} from 'types';
import { Customers } from 'types/customers';
import { OrderActions } from 'types/orders';
import { FinalStatusFormType, actionToStatusMap } from 'types/status';
import { Button } from 'ui/redesign/Button';
import { File } from 'ui/redesign/File';
import { FilePreview } from 'ui/redesign/FilePreview';
import { FilePreviewType } from 'ui/redesign/FilePreview/types';
import {
  errorRequestToastHandler,
  fetchFile,
  formatOrderRemainingTime,
  getDateWithTimezone,
  handleFileDownload,
} from 'utils/helpers';
import { formatNumberHelper } from 'utils/helpers/formatNumber.helper';
import { ROUBLE_SIGN } from 'utils/settings';
import { styles } from './consts';
import { ForbiddenErrorResponse } from './types';

interface Actions {
  title: string;
  action: () => void;
}

export const OrderDetailsTab = ({
  order,
  refetch,
  isLoading,
}: {
  order: Order;
  refetch: () => void;
  isLoading: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [mapModalOpen, setMapModalOpen] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<number | null>(null);
  const [currentFile, setCurrentFile] = useState<FilePreviewType | null>(null);
  const [actions, setActions] = useState<Actions[]>([]);
  const dispatch = useAppDispatch();

  const timeZone = useAppSelector(getTimezone);
  const userId = useAppSelector(getProfileID);
  const executorType = useAppSelector(getExecutorType);

  const navigate = useNavigate();
  const { mainUrl } = useOrderRoute();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSerialNumbers = (order: Order) => {
    const res: string[] = [];
    order.services.forEach((service) => {
      if (service.serialNumbers.length > 0) {
        service.serialNumbers.forEach((serialNumber) => {
          res.push(serialNumber.name);
        });
      }
    });
    return res.join(', ');
  };

  const serialNumbers = getSerialNumbers(order);

  // Получаем возможные переходы по статусам
  useEffect(() => {
    const menuList: Actions[] = order?.actions.reduce((acc: Actions[], value) => {
      const item = OrderActions[value];
      const status = actionToStatusMap[item?.id];
      acc.push({
        title: item?.label,
        action: () => {
          handleModalOpen();
          setNextStatus(status);
        },
      });
      return acc;
    }, []);
    const actualMenuList = menuList.filter((item) => item.title);
    setActions(actualMenuList);
  }, [order]);

  useEffect(() => {
    if (nextStatus) {
      handleModalOpen();
    }
    return () => {
      handleModalClose();
    };
  }, [nextStatus]);

  const RemainingTime = memo(() => {
    const { remainingTime } = useTimeRemaining({
      targetDate: order.targetDate,
      timeZone,
    });
    return (
      <div className="flex items-center gap-2">
        <span className="body-normal">
          {remainingTime ?? formatOrderRemainingTime(order.targetDate, timeZone)}
        </span>
      </div>
    );
  });
  RemainingTime.displayName = 'RemainingTime';

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const { mutateAsync: previewFile } = useMutation({
    mutationFn: (hash: string) => {
      return FileService.fetchFile(hash);
    },
    onSuccess: (response) => {
      setCurrentFile({
        content: response.data.content,
        type: response.data.contentType,
      });
    },
  });

  useEffect(() => {
    currentFile &&
      dispatch(
        modalActions.handleOpenModal({
          content: <FilePreview file={currentFile} />,
          options: {
            title: 'Просмотр',
            contentClassName: 'flex w-screen sm:w-auto justify-center',
            withCloseButton: true,
            withBackButton: false,
          },
        })
      );
  }, [currentFile]);

  const changeStatus = async (
    requestBody: OrderFinishedByExecutorData | OrderRefusedByExecutorData,
    type: 'completed' | 'cancel' | 'refuse'
  ) => {
    let endpoint;
    switch (type) {
      case 'completed':
        endpoint = endpoints.COMPLETED_BY_EXECUTOR;
        break;
      case 'cancel':
        endpoint = endpoints.CANCELED_BY_EXECUTOR;
        break;
      case 'refuse':
        endpoint = endpoints.REFUSED_BY_EXECUTOR;
        break;
    }
    try {
      const response = await request(endpoint.type, endpoint.url(requestBody.mainRequestId), {
        data: requestBody,
      });
      if (response.status === 200) {
        navigate(mainUrl);
      } else {
        toast.error('else');
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<ForbiddenErrorResponse, any>;
        toast.error(axiosError.response?.data.detail);
      }
    }
  };

  const changeToRefuseStatusHandler = async () => {
    try {
      if (order) {
        const requestBody: OrderRefusedByExecutorData = {
          mainRequestId: order.id,
          rowVersion: order.rowVersion,
        };
        await changeStatus(requestBody, 'refuse');
      }
      return;
    } catch (error) {
      errorRequestToastHandler(error as AxiosError<ErrorResponse>);
    }
  };

  const changeToFinalStatusHandler = async (
    formData: FinalStatusFormType,
    handlerType: 'completed' | 'cancel'
  ) => {
    try {
      if (order.worksInfo) {
        const { totalCost, additionalPayment, services } = order.worksInfo;
        const requestBody: OrderFinishedByExecutorData = {
          id: order.id,
          description: formData.description,
          totalCost,
          additionalPayment,
          services,
          workActFiles: formData.orderWorkActFiles,
          additionalFiles: formData.additionalFiles,
          rowVersion: order.rowVersion,
          mainRequestId: order.id,
          executorType: order.worksInfo.executorType,
        };
        await changeStatus(requestBody, handlerType);
      }
      return;
    } catch (error: any) {
      for (let i = 0; i < error?.response?.data.errors?.Files?.length; i++) {
        toast.error(error?.response?.data.errors?.Files[i]);
      }
    }
  };

  const getInWorkHandle = () => {
    order?.actions[0] === 7 && becomeExecutorHandler();
    order?.actions[0] === 29 && becomeExecutorByCancelledOrder();
  };

  const becomeExecutorHandler = async () => {
    const orderId = order.id;
    const requestBody: OrderBecomeExecutorData = {
      userId,
      rowVersion: order.rowVersion,
      mainRequestId: orderId,
    };
    try {
      const response = await request(
        endpoints.BECOME_EXECUTOR.type,
        endpoints.BECOME_EXECUTOR.url(order.id),
        {
          data: requestBody,
        }
      );
      // Заново получаем данные после смены статуса
      if (response.status === 200) {
        toast.success('Заявка успешно взята в работу');
        await refetch();
      }
    } catch (error) {
      toast.error(
        (error as AxiosError<ForbiddenErrorResponse>)?.response?.data?.detail ||
          'Произошла ошибка, обратитесь к администратору'
      );
    }
  };

  const becomeExecutorByCancelledOrder = async () => {
    const data: OrderBecomeExecutorData = {
      userId,
      rowVersion: order.rowVersion,
      mainRequestId: order.id,
    };
    try {
      await request('post', `api/v2/Outsource/MainRequests/${order.id}/TakeFromFair`, {
        data,
      });
      toast.success('Заявка успешно взята в работу');
      refetch();
    } catch (error) {
      toast.error(
        (error as AxiosError<ForbiddenErrorResponse>)?.response?.data?.detail ||
          'Произошла ошибка, обратитесь к администратору'
      );
    }
  };

  const ModalContent = () => {
    switch (nextStatus) {
      case 4:
        return (
          <RefuseStatusForm
            order={order}
            newStatus={4}
            onClose={handleModalClose}
            onStatusChange={changeToRefuseStatusHandler}
          />
        );
      case 6:
        return (
          <FinalStatusForm
            order={order}
            newStatus={nextStatus}
            onClose={handleModalClose}
            onStatusChange={(e: FinalStatusFormType) => changeToFinalStatusHandler(e, 'completed')}
          />
        );
      case 7:
        return (
          <FinalStatusForm
            order={order}
            newStatus={nextStatus}
            onClose={handleModalClose}
            onStatusChange={(e: FinalStatusFormType) => changeToFinalStatusHandler(e, 'cancel')}
          />
        );
      default:
        return null;
    }
  };

  const Logo = (clientId: any) => {
    const index = Customers.findIndex((customer) => customer.id === clientId.clientId);
    if (index > -1) {
      const A = Customers[index].logo;
      return (
        <A className="min-w-10 min-h-10 flex h-10 w-10 items-center justify-center rounded-[50%] border-[1px] border-light-100" />
      );
    }
    return null;
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        label="Изменение статуса"
        modalClasses={classNames('w-full lg:min-w-[630px]', {
          '-mt-8 sm:mt-16 lg:mt-24 !pt-10 !px-4 sm:p-8 lg:max-w-[300px]': nextStatus === 4, // ОТКАЗ
          '-mt-8 sm:-mt-2 !px-4 sm:p-8': nextStatus === 7 || nextStatus === 6, // ОТМЕНА & ВЫПОЛНЕНА
        })}
      >
        <ModalContent />
      </Modal>
      <Modal
        open={mapModalOpen}
        onClose={() => setMapModalOpen(false)}
        modalClasses="w-full lg:min-w-[350px]"
      >
        <YaMap order={order} />
      </Modal>
      <section className="flex flex-col gap-4 py-4 sm:max-w-[950px] sm:gap-6">
        <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
          <div>
            <WritingIcon />
            <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">
              Основная информация
            </span>
          </div>
          {
            <div className="border-b border-[#E4E4E5] pb-2">
              <p className={styles.label}>Название объекта</p>
              <p className={styles.value}>{order?.serviceObjectName || 'Неизвестно'}</p>
            </div>
          }
          <div className="flex flex-col justify-between gap-4 sm:flex-row">
            <div className="flex w-full items-center gap-2 border-b border-[#E4E4E5] pb-2 sm:max-w-[430px]">
              <Logo clientId={order?.clientId} />
              <div>
                <p className={styles.label}>Заказчик</p>
                <h2 className="body-bold sm:headline-bold break-all sm:mt-1">
                  {order?.franchiseName || 'Неизвестно'}
                </h2>
              </div>
            </div>
            <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
              <p className={styles.label}>Заявка</p>
              <h2 className="headline-bold pt-[6px] text-primary-60">№{order?.customId}</h2>
            </div>
          </div>
          <div className="flex flex-col justify-between gap-4 sm:flex-row">
            {executorType !== ExecutorTypeEnum.DRITS && (
              <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
                <p className={styles.label}>Стоимость</p>
                <p className="body-bold sm:headline-bold">
                  {order?.status === 1
                    ? formatNumberHelper(order?.estimatedCost || 0, 2)
                    : formatNumberHelper(order?.worksInfo?.assignmentCost || 0, 2)}
                  {ROUBLE_SIGN}
                </p>
              </div>
            )}
            <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
              <p className={styles.label}>Серийные номера</p>
              <p className="body-normal sm:headline-normal">
                {serialNumbers ? serialNumbers : 'Отсутствуют'}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2 sm:pt-2">
            <IconLightning className="h-5 w-5 text-[#FF992C]" />
            <p className="headline-medium sm:headline-bold">
              Срок исполнения:{` `}
              {getDateWithTimezone(order?.targetDate, timeZone)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <IconCombinedShape />
            <RemainingTime />
          </div>
        </div>

        <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
          <div className="flex">
            <div>
              <History2Icon />
            </div>
            <div className="mx-3.5 flex items-center">
              <span className="body-bold sm:title2-bold flex-1 sm:flex-none">
                Контактные данные и территория работы
              </span>
            </div>
          </div>
          <div className="ml-2 flex items-center gap-2">
            <div className="h-5 w-5">
              <IconTime />
            </div>
            <p className={styles.value}>
              {order?.serviceObjectWorkingHours || 'Расписание отсутствует'}
            </p>
          </div>
          <div className="ml-2 flex flex-col items-start gap-2">
            <div className="flex items-center gap-2">
              <div className="h-5 w-5">
                <IconLocation
                  className={classNames('h-5 w-5 cursor-pointer fill-[#9FA0A7] hover:fill-danger')}
                  onClick={() => setShowMap(!showMap)}
                />
              </div>
              <p className="headline-bold">{order?.serviceObjectAddress || 'Неизвестно'}</p>
            </div>
            {showMap && <YaMap order={order} />}
          </div>
        </div>

        <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
          <div>
            <RateIcon />
            <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">Обязанности</span>
          </div>
          <div className="w-full">
            <p className={styles.label}>Описание</p>
            <p className={styles.value}>{order?.description || 'Без описания'}</p>
          </div>
        </div>
        {order?.instructions?.length > 0 && (
          <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
            <div>
              <Education2Icon />
              <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">
                Инструкции
              </span>
            </div>
            {order?.instructions?.length > 0 && (
              <ul className="flex flex-col gap-2">
                {order.instructions?.map((item, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={`/api/v1/File/Download/${item.file.downloadFileIdHash}`}
                        rel="noreferrer noopener"
                        className="cursor-pointer text-sm text-primary-60 hover:text-primary-100"
                        onClick={(e) => {
                          e.preventDefault();
                          handleFileDownload(fetchFile(item.file.downloadFileIdHash));
                        }}
                      >
                        {item.name}
                      </a>
                    </li>
                  );
                })}
                {(order as any).workInfoTemplates.map((item: any) => {
                  return (
                    <li key={item.name}>
                      <a
                        rel="noreferrer noopener"
                        href={`/api/v1/File/Download/${item.file.downloadFileIdHash}`}
                        className="cursor-pointer text-sm text-primary-60 hover:text-primary-100"
                        onClick={(e) => {
                          e.preventDefault();
                          handleFileDownload(fetchFile(item.file.downloadFileIdHash));
                        }}
                      >
                        {item.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        )}
        {order?.files?.length > 0 && (
          <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
            <div>
              <AttachOrder />
              <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">Вложения</span>
            </div>
            {order.files.length !== 0 && (
              <ul className="flex flex-col gap-2">
                {order?.files?.map((file) => (
                  <li key={file.downloadFileIdHash} className="w-full max-w-[600px]">
                    <File
                      file={file}
                      onDownload={() => handleFileDownload(fetchFile(file.downloadFileIdHash))}
                      onPreview={() => previewFile(file.downloadFileIdHash)}
                      isHideDeleteFileButton={true}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        <div className="z-10 flex items-center justify-start">
          {order?.actions?.length && (order?.actions[0] === 7 || order?.actions[0] === 29) ? (
            <Button onClick={getInWorkHandle} className="w-full max-w-[640px] sm:max-w-[360px]">
              ВЗЯТЬ В РАБОТУ
            </Button>
          ) : null}
          {order.status !== OrderStatusesEnum.ExecutorNotAssigned &&
            order.status !== OrderStatusesEnum.Confirmed &&
            order.status !== OrderStatusesEnum.Awaiting &&
            (actions.length > 1 ||
              (actions.length === 1 &&
                order?.actions?.length &&
                order?.actions[0] !== 7 &&
                order?.actions[0] !== 29)) && (
              <div className="my-0 w-full max-w-[290px] rounded-[10px] bg-primary-60 text-white sm:max-w-[154px]">
                <DropDown buttonMode button="СТАТУС" menuItems={actions} />
              </div>
            )}
        </div>
      </section>
    </>
  );
};
