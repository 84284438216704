import { FieldCheckbox } from 'components/redesign/FieldCheckbox';
import { SpecSelect } from 'components/redesign/SpecSelect';
import { FC, useCallback } from 'react';
import { downloadStaticFile, handleFileDownload } from 'utils/helpers';
import { StaticFiles } from 'utils/settings';
import { specsData } from './consts';
import { Step4Props } from './types';
import { EExecutorSpecialization } from 'enums/executorSpecialization';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { ModerationRequestStatusId } from 'enums/moderationRequestStatus';
import { CitiesSelect } from 'components/redesign/CititesSelect';
import { ProfileSpecialization } from 'reduxStore/reducers/ProfileSlice/types';
import { toast } from 'react-toastify';

export const Step4Fields: FC<Step4Props> = ({
  control,
  watch,
  errors,
  setValue,
  isFormDisabled,
  parentId,
  clearErrors,
  executorType,
  status = 0,
  id = 0,
  getValues,
}) => {
  const isSecondaryModerationForAgent = Boolean(
    id &&
      status !== ModerationRequestStatusId.Draft &&
      status !== ModerationRequestStatusId.ElaborationRequested &&
      [ModerationRequestStatusId.TrainingCompleted, ModerationRequestStatusId.Imported].includes(
        status
      ) &&
      getValues('executorSpecializations')?.includes(EExecutorSpecialization.Agent)
  );

  const dispatch = useAppDispatch();

  const changeExecutorSpecializationsHandle = useCallback(
    (value: EExecutorSpecialization[] | null) => {
      if (!value || !value.length || value?.includes(EExecutorSpecialization.Agent)) {
        dispatch(
          moderationRequestActions.setEducations({
            educations: [],
            workExperiences: [],
          })
        );
        dispatch(
          moderationRequestActions.setCertificates({
            certificateElectricalSafety: [],
            certificateWorkHeight: [],
          })
        );
        setValue('primaryEducation', []);
        setValue('secondaryEducation', []);
        setValue('workExperiences', []);
        setValue('certificateElectricalSafety', []);
        setValue('certificateWorkHeight', []);
      }
    },
    []
  );

  const downloadHandler = () => {
    if (!getValues('executorSpecializations')?.length) {
      toast.error('Выберите область специализации');
      return;
    }
    getValues('executorSpecializations').includes(ProfileSpecialization.Agent)
      ? handleFileDownload(downloadStaticFile(StaticFiles.Agent_Contract_Offer))
      : handleFileDownload(downloadStaticFile(StaticFiles.SelfEmployed_Contract_Offer));
  };

  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      {parentId === 0 && (
        <CitiesSelect
          isMulti
          control={control}
          label="Города обслуживания"
          name="serviceCities"
          isFormDisabled={isFormDisabled}
          isClearable
          errorMessage={errors?.serviceCities?.message}
          clearErrors={clearErrors}
        />
      )}
      {parentId === 0 && (
        <SpecSelect
          name="executorSpecializations"
          control={control}
          label="Область специализации"
          options={specsData}
          watch={watch}
          disabled={isFormDisabled || isSecondaryModerationForAgent}
          errorMessage={errors?.executorSpecializations?.message}
          clearErrors={clearErrors}
          executorType={executorType}
          onChange={changeExecutorSpecializationsHandle}
        />
      )}
      <div>
        {parentId > 0 && (
          <SpecSelect
            name="executorSpecializations"
            control={control}
            label="Область специализации"
            options={specsData}
            watch={watch}
            disabled={
              isFormDisabled ||
              getValues('executorSpecializations')?.includes(EExecutorSpecialization.Agent)
            }
            errorMessage={errors?.executorSpecializations?.message}
            clearErrors={clearErrors}
            executorType={executorType}
          />
        )}
        <div className="mt-6 mb-5 flex">
          <FieldCheckbox
            name="acceptsOffer"
            control={control}
            errorMessage={(errors as any)?.acceptsOffer?.message}
            title="Я принимаю оферту"
            disabled={isFormDisabled}
            clearErrors={clearErrors}
          />
        </div>
        <p>
          <span
            className="footnote-medium sm:callout-normal cursor-pointer !text-primary-60"
            onClick={downloadHandler}
          >
            Ссылка на скачивание оферты
          </span>
        </p>
      </div>
    </div>
  );
};
