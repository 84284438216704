import { ConfirmEmailIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { FieldInput } from 'components/redesign/FieldInput';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useTimer } from 'hooks/useTimer';
import { FC, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { ProfileService } from 'services/ProfileService';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { InfoBlock } from 'ui/redesign/InfoBlock';
import { CodeRequestTimeout } from '../CodeRequestTimeout';
import { smsTimeout } from './constats';
import { ChangeEmailForm, ConfirmEmailFormProps } from './types';
import { InfoBlockVariant } from 'ui/redesign/InfoBlock/constants';

export const ConfirmEmailForm: FC<ConfirmEmailFormProps> = ({ email, userId }) => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isDirty },
  } = useForm<ChangeEmailForm>({
    mode: 'onChange',
    defaultValues: {
      email: email,
      code: '',
      userId,
    },
  });
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const queryResultRef = useRef<ConfirmResponse | null>(null);
  const [timer, resetTimer] = useTimer(smsTimeout);

  const { refetch } = useQuery(
    ['sendEmailConfirmCode'],
    () => ProfileService.sendEmailConfirmCode(),
    {
      onSuccess: (data) => {
        queryResultRef.current = data.data;
        resetTimer(smsTimeout);
      },
      onError: (error: AxiosError<ConfirmResponse>) => {
        if (error?.response?.data) {
          queryResultRef.current = error.response.data;
        }
      },
    }
  );
  const { mutateAsync: confirmEmail, isLoading } = useMutation(
    ['sendConfirmCode'],
    () => ProfileService.confirmEmail({ email, code: getValues('code'), userId }),
    {
      onSuccess: (data) => {
        if (data.data.isSuccess) {
          queryClient.refetchQueries('getProfile');
          dispatch(modalActions.closeModal());
        } else {
          queryResultRef.current = data.data;
          setValue('code', '');
        }
      },
    }
  );

  const sendHandler = async () => {
    await refetch();
  };

  const confirmHandler: SubmitHandler<ChangeEmailForm> = async () => {
    await confirmEmail();
  };

  return (
    <div className="p-4 sm:p-0">
      <form onSubmit={handleSubmit(confirmHandler)}>
        <div className="my-4 text-center">
          <ConfirmEmailIcon />
        </div>
        <div className="my-4 h-24 text-text-100">
          {queryResultRef.current?.isSuccess ? (
            <InfoBlock
              text={queryResultRef.current.message.split('.')[0]}
              description={queryResultRef.current.message.split('.')[1]}
              className="flex h-24 flex-col"
            />
          ) : (
            queryResultRef.current && (
              <InfoBlock
                text={queryResultRef.current.message.split('.')[0]}
                description={queryResultRef.current.message.split('.')[1]}
                variant={InfoBlockVariant.ERROR}
                className="flex h-24"
              />
            )
          )}
        </div>
        <FieldInput name="email" control={control} label="Электронная почта" disabled />
        <div className="mt-4">
          <FieldInput type="number" name="code" control={control} label="Код из письма" />
          {timer > 0 && <CodeRequestTimeout timer={timer} />}
        </div>
        <div className="mt-6 flex flex-col-reverse sm:flex-row sm:justify-end">
          <Button
            onClick={sendHandler}
            disabled={timer > 0}
            variant={ButtonVariant.GHOST}
            type="button"
            className="w-full max-w-[640px] sm:w-[270px]"
          >
            ОТПРАВИТЬ ПОВТОРНО
          </Button>
          <Button
            className="w-full max-w-[640px] sm:w-[250px]"
            type="submit"
            disabled={!isDirty || isLoading}
          >
            ПОДТВЕРДИТЬ КОД
          </Button>
        </div>
      </form>
    </div>
  );
};
