import { TimeSpan } from './Timespan';
import { ESpsRequestActionType } from 'enums/requestActionType';
import { EExecutorSpecialization } from 'enums/executorSpecialization';
import { ECitizenship } from 'enums/citizenship';
import { ChatUserGroup } from 'enums/chatUserGroup';
import { OrderTabEnum } from 'enums/orderTab';
import { OrderStatusEnum } from 'enums/order';
import { OrderFilterEnum } from 'enums/orderFilter';
import { ExecutorTypeEnum } from 'enums/executorType';
import { OrderActionType } from 'enums/orderActionType';
import { OrganizationType } from 'enums/organizationType';
import { EServiceAreaType } from 'enums/serviceAreaType';
import { ESubcontractorModerationRequestStatusId } from 'enums/subcontractorModerationRequestStatus';
import { SubcontractorModeration } from 'reduxStore/reducers/SubcontractorModerationSlice/types';

export type DateUTC = string;
export type UserID = number;

interface Rate {
  id: number;
  basePrice: number;
  additionalPrice: number;
  isActive: boolean;
  serviceTypeId: number;
  calculationMethod: number;
}

interface City {
  id: number;
  name: string;
  regionId: number;
  region: string;
  macroregionId: number;
  macroregion: string;
  rate: number;
  timeZone: number;
  fullName: string;
  managersContacts: ManagersContacts;
}

interface CityShort {
  id: number;
  name: string;
}

interface Settlement {
  federalDistrictId: number;
  fiasId: string;
  objectFullName: string;
  objectWithType: string;
  rate: number;
  regionFiasId: string;
  regionWithType: string;
  timeZone: number;
}

interface SettlementShort {
  fiasId: string;
  objectFullName: string;
}

interface ServiceObject {
  id: number;
  name: string;
  address: Address;
  cityId: number;
  project: number;
  workingHours: string;
}

interface WorkType {
  id: number;
  specialization: number;
  name: string;
}

interface OrderType {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  lastChanged: string;
  executorSlaTicks: number;
  activeRate: Rate;
  executorSla: TimeSpan;
  workType: WorkType;
  status: number;
}

interface Equipment {
  id: number;
  name: string;
  isActive: boolean;
  lastChanged: string;
  status: number;
}

interface User {
  id: UserID;
  created: string;
  email: string;
  userName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  fullName: string;
  inn: string | null;
  citiesFiasIds: number[];
  timeZone: number;
  emailConfirmed?: boolean;
  phoneNumberConfirmed?: boolean;
  executorType: ExecutorTypeEnum;
  executorSpecializationIds: EExecutorSpecialization[];
  fullNameWithInn?: string;
}

interface OrderFile {
  fileType: number;
  fileName: string;
  downloadFileIdHash: string;
  deletedFileIdHash: string;
  isTemp: boolean;
  length: number;
}

interface OrderFileContent {
  content: string;
  contentType: string;
  fileName: string;
}

type SerialNumberId = number;

interface WorkInfoService {
  id: number;
  serviceOfRequestId: number;
  processedSerialNumbers: SerialNumberId[];
  quantity: number;
}

interface WorkInfo {
  id: number;
  description: string;
  totalCost: number;
  additionalPayment: number;
  services: WorkInfoService[];
  executorType: ExecutorTypeEnum;
  additionalFiles: OrderFile[];
  workActFiles: OrderFile[];
  assignmentCost: number;
}

interface SerialNumber {
  id: SerialNumberId;
  created: DateUTC;
  name: string;
}

interface Service {
  id: number;
  created: DateUTC;
  serviceCategory: string;
  clientServiceType: string;
  quantity: number;
  serialNumbers: SerialNumber[];
  targetDate: DateUTC;
}

interface Order {
  id: number;
  rowVersion: string;
  customId: string;
  name: string;
  clientId: number;
  clientName: string;
  franchiseId: number;
  franchiseName: string;
  estimatedCost: number;
  targetDate: DateUTC;
  description: string;
  status: number;
  statusName: string;
  progressMarkerName: string;
  costUnit: string;
  serviceObjectName: string;
  serviceObjectAddress: string;
  serviceObjectWorkingHours: string;
  serviceObjectGeoLat: string;
  serviceObjectGeoLon: string;
  files: OrderFile[];
  services: Service[];
  worksInfo: WorkInfo | null;
  workInfoTemplates: WorkInfoTemplates[] | null;
  serialNumbers: string[];
  notification: OrderNotification;
  instructions: Instruction[];
  actions: number[]; //OrderActions;
}

interface WorkInfoTemplates {
  id: number;
  name: string;
  file: Omit<OrderFile, 'length'>;
}

interface Instruction {
  clientServiceTypeId: number;
  name: string;
  file: OrderFile;
}

interface FilterOrder {
  city: string;
  clientSystemId: any;
  created: string;
  createdLocal: string;
  dispatcher: string;
  dispatcherRemainingTime: TimeSpan;
  dispatcherSla: TimeSpan;
  dispatcherSlaTicks: number;
  dispatcherTargetDate: string;
  executor: string;
  executorRemainingTime: TimeSpan;
  executorSla: TimeSpan;
  executorSlaTicks: number;
  executorTargetDate: string;
  getUtcOffset: string;
  id: number;
  name: string;
  projectId: number;
  projectName: string;
  slaType: number;
  slaTypeId: number;
  status: number;
  statusString: string;
  timeZone: number;
}

interface CommonOrderDialogParameters {
  previousStatus: OrderStatusEnum;
  currentStatus: OrderStatusEnum;
  newStatus: OrderStatusEnum;
  dialogFormParameters: OrderDialogFormParameters;
}

interface OrderDialogFormParameters {
  isDeferredDateEnabled: boolean;
  isDeferredDateNotEditable: boolean;
  isDescriptionEnabled: boolean;
  isDescriptionNotEditable: boolean;
  isUsedSpsEnabled: boolean;
  isUsedSpsNotEditable: boolean;
  isFilesEnabled: boolean;
  isFilesNotEditable: boolean;
  isOrderWorkEnabled: boolean;
  isOrderWorkNotEditable: boolean;
}

interface OrderAction {
  actionType: OrderActionType;
  description: string | null;
}

interface OrderDispatcherStatusAction {
  actionType: OrderActionType;
  description: string | null;
  dialogParameters: CommonOrderDialogParameters;
}

interface OrderExecutorStatusAction {
  actionType: OrderActionType;
  description: string;
  status: OrderStatusEnum;
}

interface OrderActions {
  additionalActions: OrderAction[];
  plainActions: OrderAction[];
  executorStatusActions: OrderExecutorStatusAction[];
  dispatcherStatusActions: OrderDispatcherStatusAction[];
}

interface ExtendedFilter {
  pageNumber: number;
  pageSize: number;
  searchParameters: {
    cities: City[];
    clientSystemId: string | null;
    completionDateTimeEnd: Date | null | string;
    completionDateTimeStart: Date | null | string;
    creationDateTimeEnd: Date | null | string;
    creationDateTimeStart: Date | null | string;
    distanceTraveledFrom: number;
    distanceTraveledTo: number;
    elapsedTimeTicksBegin: number;
    elapsedTimeTicksEnd: number;
    executorInns: string[];
    individualSlaIsEnabled: boolean;
    macroregions: any[];
    myOrdersIsEnabled: boolean;
    orderEquipmentQuantityFrom: number;
    orderEquipmentQuantityTo: number;
    orderEquipments: Equipment[];
    orderNumbers: number[];
    projects: EntityShort[];
    regions: any[];
    serviceObjects: ServiceObject[];
    serviceTypes: any[];
    statuses: number[];
    targetDateTimeEnd: Date | null | string;
    targetDateTimeStart: Date | null | string;
    workTypes: any[];
  };
  tabName: string;
  tableStatusSettings: number[];
}

interface ExtendedFilterResponse {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  pageSize: number;
  pagedList: FilterOrder[];
  totalCount: number;
  totalPages: number;
}

interface Client {
  id: number;
  bank: string;
  organizationName: string;
  type: number;
  bik: string;
  firmName: string;
  inn: string;
  juridicalAddress: string;
  kpp: string;
  ogrn: string;
  ogrnip: string;
}

interface EntityShort {
  id: number;
  shortName: string;
}

interface Project {
  id: number;
  name: string;
  clientId: number;
  client: Client;
}

interface OrderByFilterRequest {
  pageNumber: number;
  pageSize: number;
  tab: number;
  searchParameters: {
    creationDateTimeStart: string | null;
    creationDateTimeEnd: string | null;
    clientIds: number[];
    cityFiasIds: number[];
    // cities: any[];
    customId: string;
    areDependentObjectsIncluded: boolean | null;
  };
}

interface OrderByFilterResponse {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
  pagedList: OrderForListCard[];
}

interface OrderForListCard {
  id: number;
  name: string;
  created: DateUTC; // '2022-07-14T10:04:24.580Z'
  customId: string;
  clientId: number;
  clientName: string;
  targetDate: DateUTC; //'2022-07-14T10:04:24.580Z'
  address: string;
  cityName: string;
  status: number;
  totalCost: number;
  estimatedCost: number;
  newChatMessages: boolean;
  distanceInMeters?: number;
  isExactDistance?: boolean;
}

interface OrderNotification {
  hasOrderChatNotification: boolean;
  hasSpsChatNotification: boolean;
  isOrderChatViewedByExecutor: boolean;
  isViewedByDispatcher: boolean;
  spsChatLastMessageGroup: ChatUserGroup;
  orderChatLastMessageGroup: ChatUserGroup;
}

interface OrderForListCardSettings {
  tabs: OrderCardTabSettings[];
  filters: OrderFilterEnum[];
}

interface OrderCardTabSettings {
  tab: OrderTabEnum[];
  orderStatusFilter: OrderStatusEnum[];
  ordersCount: number;
  isVisibleCounter: boolean;
}

// ЗИП
interface SPS {
  id: number;
  projectId: number;
  project: number;
  name: string;
  isActive: boolean;
  lastChanged: string; // @todo было null
}

interface ManagersContacts {
  id: number;
  cityId: number;
  directorContacts: string;
  administratorContacts: string;
}

interface Warehouse {
  id: number;
  guid: string;
  name: string;
  cities: City[] | null;
}

interface DeliveryAddressWithContacts {
  id: number;
  address: string | null;
  recipientId: number;
  fullName: string | null;
  contacts: string | null;
}

interface DeliveryAddress {
  id: number;
  address: string | null;
  recipientId: number;
}

interface Nomenclature {
  id: number;
  guid: string;
  name: string;
  vendorCode: string;
  unit: string;
  unitCodeOK: string | null;
}

interface SpsItem {
  id: number;
  spsRequestId: number;
  statusId: number;
  status: number;
  statusNameForUser: string | null;
  writeOffDocNumber: string | null;
  nomenclature: Nomenclature | null;
  description: string | null;
  quantity: number;
  amountUsed: number;
  remainder: number;
  spsSourceTypeId: number | null;
  spsSourceType: number | null;
  warehouse: Warehouse | null;
  trackNumber: string | null;
}

interface CreateSpsRequest {
  id: number;
  rowVersion: string | null;
  statusId: number;
  status: number;
  statusNameForUser: string | null;
  deliveryType: number;
  recipient: User | null;
  spsDispatcher: User | null;
  logist: User | null;
  warehouse: Warehouse | null;
  deliveryAddress: DeliveryAddress | null;
  deliveryRecipientFullName: string | null;
  deliveryRecipientContacts: string | null;
  notNeededReason: string | null;
  approveItems: SpsItem[];
  createItems: SpsItem[];
  stateUI: number;
  orderNumber1C: string | null;
  orderId: number;
  actions: ESpsRequestActionType[];
}

interface EditSpsRequest {
  spsRequestDto: CreateSpsRequest;
  action: ESpsRequestActionType;
}

interface SpsRequest {
  id: number;
  rowVersion: string;
  statusId: number;
  status: number;
  statusNameForUser: string;
  deliveryType: number;
  recipient: User;
  recipientName: string;
  spsDispatcher: User;
  spsDispatcherName: string;
  logist: User;
  logistName: string;
  warehouse: Warehouse;
  deliveryAddress: DeliveryAddress;
  deliveryRecipientFullName: string;
  deliveryRecipientContacts: string;
  notNeededReason: string;
  approveItems: SpsItem[];
  createItems: SpsItem[];
  stateUI: number;
  orderNumber1C: string;
  actions: ESpsRequestActionType[];
}

interface Address {
  standartedAddressLine: string;
  postalCode: string;
  federalDistrict: {
    name: string;
    countryId: number;
    country: string | null;
    id: number;
    created: Date | string;
  };
  region: {
    name: string;
    federalDistrictId: number;
    fiasId: string;
    kladrId: string;
    isoCode: string;
    regionWithType: string;
    type: string;
    typeFull: string;
    regionType: number;
    id: number;
    created: Date | string;
  };
  regionArea: string | null;
  city: {
    name: string;
    fiasId: string;
    kladrId: string;
    cityWithType: string;
    type: string;
    typeFull: string;
    regionAreaId: string | null;
    regionId: number;
    federalDistrictId: number;
    timeZone: number;
    rate: number;
    regionType: number;
    id: number;
    created: Date | string;
  };
  settlement: string | null;
  streetObject: {
    name: string;
    fiasId: string;
    kladrId: string;
    streetWithType: string;
    type: string;
    typeFull: string;
    id: number;
    created: Date | string;
  };
  houseFiasId: string;
  houseKladrId: string;
  houseType: string;
  houseFullType: string;
  house: string;
  blockType: string | null;
  blockTypeFull: string | null;
  block: string | null;
  entrance: string | null;
  floor: string | null;
  flatFiasId: string | null;
  flatType: string | null;
  flatTypeFull: string | null;
  flat: string | null;
  fiasId: string;
  fiasCode: string;
  kladrId: string;
  okato: string;
  oktmo: string;
  taxOffice: string;
  taxOfficeLegal: string;
  geoLat: string;
  geoLon: string;
  beltwayDistance: string | null;
  metroAddresses: [
    {
      distance: number;
      line: string;
      name: string;
    },
    {
      distance: number;
      line: string;
      name: string;
    },
    {
      distance: number;
      line: string;
      name: string;
    }
  ];
  dadataHash: number;
  cityArea: string | null;
  cityDistrict: string;
  cityDistrictFiasId: string | null;
  cityDistrictKladrId: string | null;
  cityDistrictType: string;
  cityDistrictTypeFull: string;
  cityDistrictWithType: string;
  street: string;
  streetFiasId: string;
  streetKladrId: string;
  streetType: string;
  streetTypeFull: string;
  streetWithType: string;
  beltwayHitId: string | null;
  beltwayHit: string | null;
  capitalMarkerId: number;
  capitalMarker: {
    id: number;
    code: number;
    description: string;
  };
  fiasActualityStateId: number;
  fiasActualityState: {
    id: number;
    code: number;
    description: string;
  };
  fiasLevelId: number;
  fiasLevel: {
    id: number;
    code: number;
    description: string;
  };
  qcId: number;
  qc: {
    id: number;
    code: number;
    description: string;
    needManualCheck: false;
  };
  qcCompleteId: number;
  qcComplete: {
    id: number;
    code: number;
    suitableMailing: string;
    description: string;
  };
  qcGeoId: number;
  qcGeo: {
    id: number;
    code: number;
    description: string;
  };
  qcHouseId: number;
  qcHouse: {
    id: number;
    code: number;
    qcGeoId: number;
    deliveryProbability: string;
    description: string;
  };
  id: number;
  created: Date | string;
}

interface ErrorResponse {
  detail: string;
  status: number;
  title: string;
  type: string;
}

interface WorkExperience {
  id: number;
  description: string;
  files: OrderFile[];
}

interface Education {
  id: number | null;
  description: string;
  isPrimary: boolean;
  files: OrderFile[];
}

interface Passport {
  series: string | null;
  number: string | null;
  dateOfIssue: string | null;
  issuedBy: string | null;
  unitCode: string | null;
  files: OrderFile[];
  registrationAddress: string | null;
  birthPlace: string | null;
}

interface IndividualCertificate {
  ogrnip: string | null;
  individualName: string | null;
  files: OrderFile[];
}

interface SelfemployedCertificate {
  files: OrderFile[];
}

interface ModerationRequest {
  id: number;
  rowVersion?: string;
  moderatedUserId: number;
  recruiterId: number;
  inn: string | null;
  bik: string | null;
  bankSettlement: string | null;
  mobilePhone: string | null;
  email: string | null;
  created: string | null;
  acceptsOffer: boolean;
  parentId: number;
  birthDate: string | null;
  executorType: number;
  status: number;
  fullName: string | null;
  citizenship: ECitizenship;
  pyrusUrl?: string | null;
  passport: Passport;
  executorPhotos: {
    files: OrderFile[];
  };
  selfemployedCertificate: SelfemployedCertificate | null;
  educations: Education[];
  workExperiences: WorkExperience[];
  serviceCities: Settlement[];
  executorSpecializations: EExecutorSpecialization[];
  individualCertificate: IndividualCertificate | null;
  userRegistration: string;
  recruiterFullName: string;
}

interface Profile {
  citiesFiasIds: number[];
  created: string;
  email: string;
  emailConfirmed: boolean;
  executorSpecializationIds: number[];
  executorType: number;
  firstName: string;
  fullName: string;
  fullNameWithInn: string;
  id: number;
  inn: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  timeZone: number;
  userName: string;
}

interface ChatMembership {
  chatId: number;
  userId: number;
  user: User;
  created: string;
  userGroup: number;
  lastViewedMessageId: number | null;
  notViewedMessages: number;
}

interface ChatUser {
  id: UserID;
  fullName: string;
  firstName: string;
  lastName: string;
}

interface ChatMembershipV2 {
  userId: UserID;
  user: ChatUser;
  lastViewedMessageId: number | null;
  notViewedMessages: number;
}

interface ChatMessage {
  chatId: number;
  userId: UserID;
  content: string;
  created: DateUTC;
  id: number;
  isSystem: boolean;
  userGroup: number;
}

interface ChatMessageV2 {
  id: number;
  created: DateUTC;
  content: string;
  userId: UserID;
  canEdit: boolean;
}

interface ChatSettings {
  application: number;
  chatId: number;
  entityId: number;
  entityType: number;
}

interface Chats {
  id: number;
  name: string;
  chatToEntity: ChatSettings;
  memberships: ChatMembership[];
  messages: ChatMessage[];
}

interface ChatsV2 {
  name: string;
  messages: ChatMessageV2[];
  memberships: ChatMembershipV2[];
}

interface ChatCanSendMessage {
  canSendMessage: boolean;
}

interface AddUserToChatResult {
  chat: Chats;
  isUserAdded: boolean;
}

interface PaymentOrder {
  id: number;
  created: string;
  number: string | null;
  date: string;
  amount: number;
  description: string | null;
  executorInn: string | null;
  executorId: number;
  files: OrderFile[];
}

export enum RefuseReasonEnum {
  'Некорректная дата' = 1,
  'Некорректная сумма' = 2,
  'Некорректное ФИО' = 3,
  'Другое' = 4,
}

interface RefuseReason {
  refuseReason: RefuseReasonEnum;
  refuseComment: string | null;
}

export interface NewPaymentOrder {
  id: number;
  created: string;
  number: string | null;
  date: string;
  amount: number;
  fullName: string | null;
  description: string | null;
  files?: OrderFile[];
  status: PaymentOrderEnum;
  refuseReasons: RefuseReason[] | null;
}

export enum PaymentOrderEnum {
  'Ожидает загрузки' = 1,
  'Ожидает проверки' = 2,
  'Чек принят' = 3,
  'Чек не принят' = 4,
}

export interface PaymentBySumm {
  id: number;
  date: string;
  amount: number;
  description: string | null;
  status: PaymentOrderEnum;
}

interface PaymentOrderRequest {
  paymentOrderId: number;
  files: OrderFile[];
}

type ProfileChangeResponse = {
  message: string;
  isSuccess: boolean;
};

type ProfileChangeResponseWithCommonSettlement = ProfileChangeResponse & {
  commonSettlement: SettlementShort;
};

type GetRegionBySuggestion = {
  message: string;
  isSuccess: boolean;
  region: SettlementShort;
};

type ProfileChangeResponseWithUpdateSettlements = ProfileChangeResponse & {
  userCurrentCommonSettlements: SettlementShort[];
};

type ProfileChangeErrorResponse = {
  errors: {
    CommonSettlements: string[];
  };
  status: number;
  title: string;
};

interface DadataAddress {
  source: string;
  result: string;
  postal_code: string;
  country: string;
  country_iso_code: string;
  federal_district: string;
  region_fias_id: string;
  region_kladr_id: string;
  region_iso_code: string;
  region_with_type: string;
  region_type: string;
  region_type_full: string;
  region: string;
  area_fias_id: string;
  area_kladr_id: string;
  area_with_type: string;
  area_type: string;
  area_type_full: string;
  area: string;
  city_fias_id: string;
  city_kladr_id: string;
  city_with_type: string;
  city_type: string;
  city_type_full: string;
  city: string;
  city_area: string;
  city_district_fias_id: string;
  city_district_kladr_id: string;
  city_district_with_type: string;
  city_district_type: string;
  city_district_type_full: string;
  city_district: string;
  settlement_fias_id: string;
  settlement_kladr_id: string;
  settlement_with_type: string;
  settlement_type: string;
  settlement_type_full: string;
  settlement: string;
  street_fias_id: string;
  street_kladr_id: string;
  street_with_type: string;
  street_type: string;
  street_type_full: string;
  street: string;
  house_fias_id: string;
  house_kladr_id: string;
  house_cadnum: string;
  house_with_type: string;
  house_type: string;
  house_type_full: string;
  house: string;
  block_type: string;
  block_type_full: string;
  block: string;
  entrance: string;
  floor: string;
  flat_fias_id: string;
  flat_cadnum: string;
  flat_type: string;
  flat_type_full: string;
  flat: string;
  flat_area: string;
  square_meter_price: string;
  flat_price: string;
  postal_box: string;
  fias_id: string;
  fias_code: string;
  fias_level: string;
  fias_actuality_state: string;
  kladr_id: string;
  geoname_id: string;
  capital_marker: string;
  okato: string;
  oktmo: string;
  tax_office: string;
  tax_office_legal: string;
  timezone: string;
  geo_lat: string;
  geo_lon: string;
  beltway_hit: string;
  beltway_distance: string;
  qc_geo: string;
  qc_complete: string;
  qc_house: string;
  qc: string;
  unparsed_parts: string;
  history_values: string[];
  metro: [
    {
      name: string;
      line: string;
      distance: number;
    }
  ];
  structure_type: number;
}

interface Suggestion {
  value: string;
  unrestricted_value: string;
  data: DadataAddress;
}

interface ExtendedSuggestion {
  isMarked: boolean;
  name: string | null;
  nameWithRegion: string | null;
  suggestion: Suggestion;
}

type SubcontractorRequestId = number;

interface ServiceArea {
  id: number;
  subcontractorId: SubcontractorRequestId;
  serviceAreaTypeId: EServiceAreaType;
  objectFullName?: string | null;
  federalDistrictId?: number | null;
  regionFiasId?: string | null;
  cityFiasId?: string | null;
}

interface SubcontractorModerationRequest {
  id: SubcontractorRequestId;
  rowVersion?: string;
  moderatedUserId: number;
  organizationTypeId: OrganizationType;
  ownerOrganizationId?: number;
  statusId: ESubcontractorModerationRequestStatusId;
  organizationName: string;
  serviceAreas: ServiceArea[];
  egrulScan: OrderFile | null;
  egripScan: OrderFile | null;
  legalAddress: string;
  address: string;
  phoneNumber: string;
  email: string;
  inn: string;
  innScan: OrderFile | null;
  ogrn: string | null;
  ogrnScan: OrderFile | null;
  ogrnip: string | null;
  ogrnipScan: OrderFile | null;
  bankSettlement: string;
  bankName: string;
  bik: string;
  withVat: boolean;
  kpp: string | null;
  okato: string | null;
  okpo: string | null;
  files: OrderFile[];
  serviceAreaTypeId: EServiceAreaType;
  contractSubcontractorProjectFiles: OrderFile[] | null;
  certificateElectricalSafetyQuantity: number;
  certificateElectricalSafetyScan: OrderFile[];
  certificateWorkHeightQuantity: number;
  certificateWorkHeightScan: OrderFile[];
}

interface SubcontractorChecklistItem {
  checkListType: number;
  checkListTypeId: number;
  isEditable: boolean;
}

type SubcontractorModerationRequestWithChecklist = SubcontractorModeration & {
  checkList: {
    [p: string]: SubcontractorChecklistItem;
  };
};

interface FileErrorResponse {
  detail: string;
  status: number;
  title: string;
}

type OrderFinishedByExecutorData = {
  id: number;
  description: string;
  totalCost: number;
  additionalPayment: number;
  services: WorkInfoService[];
  executorType: ExecutorTypeEnum;
  additionalFiles: OrderFile[];
  workActFiles: OrderFile[];
  mainRequestId: number;
  rowVersion: string;
};
type OrderRefusedByExecutorData = {
  mainRequestId: number;
  rowVersion: string;
};
type OrderBecomeExecutorData = {
  userId: number;
  mainRequestId: number;
  rowVersion: string;
};

type StaticFileDownloadType = {
  fileName: string;
  contentType: string;
  content: string;
};

type StaticFileInfoType = {
  key: string;
  description: string;
  fileName: string;
};

interface RecieptsByPeriodRequest {
  month: number;
  year: number;
  periodType: number;
  files: OrderFile[];
}

interface RecieptsByPeriodDto {
  id: number;
  created: Date;
  month: number;
  year: number;
  periodTypeId: number;
  files: OrderFile[];
}

interface FileMetadataDto {
  fileType: number;
  name: string | null;
  contentType: string | null;
  size: number;
  readLink: string | null;
  deleteLink: string | null;
}

interface FileContentDto {
  name: string | null;
  contentType: string | null;
  content: string | null;
  size: number;
}

interface BaseError {
  detail: string;
  status: number;
  title: string;
  type: string;
  traceId: string;
  errors?: Record<string, string[]>;
}

export type {
  Rate,
  OrderFile,
  OrderFileContent,
  TimeSpan,
  User,
  Equipment,
  WorkType,
  OrderType,
  Order,
  WorkInfo,
  City,
  CityShort,
  ServiceObject,
  FilterOrder,
  ExtendedFilter,
  ExtendedFilterResponse,
  OrderByFilterRequest,
  OrderByFilterResponse,
  OrderForListCard,
  SPS,
  Client,
  Project,
  ManagersContacts,
  SpsItem,
  CreateSpsRequest,
  SpsRequest,
  DeliveryAddress,
  Nomenclature,
  Warehouse,
  EntityShort,
  Address,
  ErrorResponse,
  Settlement,
  EditSpsRequest,
  ModerationRequest,
  Education,
  WorkExperience,
  Passport,
  IndividualCertificate,
  SelfemployedCertificate,
  Profile,
  Chats,
  ChatMessage,
  ChatMembership,
  ChatSettings,
  ChatCanSendMessage,
  ChatsV2,
  ChatMessageV2,
  ChatMembershipV2,
  ChatUser,
  AddUserToChatResult,
  OrderForListCardSettings,
  OrderCardTabSettings,
  OrderNotification,
  PaymentOrder,
  PaymentOrderRequest,
  Instruction,
  DeliveryAddressWithContacts,
  SettlementShort,
  ProfileChangeResponse,
  ProfileChangeResponseWithUpdateSettlements,
  ProfileChangeResponseWithCommonSettlement,
  DadataAddress,
  ExtendedSuggestion,
  ProfileChangeErrorResponse,
  OrderActions,
  SubcontractorModerationRequest,
  GetRegionBySuggestion,
  ServiceArea,
  SubcontractorRequestId,
  SubcontractorModerationRequestWithChecklist,
  FileErrorResponse,
  OrderRefusedByExecutorData,
  OrderFinishedByExecutorData,
  OrderBecomeExecutorData,
  StaticFileDownloadType,
  StaticFileInfoType,
  RecieptsByPeriodRequest,
  RecieptsByPeriodDto,
  FileMetadataDto,
  FileContentDto,
  BaseError,
};

