import { getDateWithTimezone } from 'utils/helpers';
import { ClientOrderTabsEnum } from './types';
import { Tab } from 'components/redesign/Tabs/types';
import { AgentOrClientOrderStatusForTable } from 'components/AgentOrClientOrderStatusForTable';
import { OrderDetailsStatusEnum } from 'services/AgentService/types';

const repeatingTableHeadings: TableHeading[] = [
  {
    attributeName: 'number',
    name: 'Номер заявки',
    renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  },
  {
    attributeName: 'creationTime',
    name: 'Дата создания',
    renderColumn: (column) => (
      <span className="whitespace-nowrap">
        {getDateWithTimezone(String(column), undefined, 'dd.MM.yyyy HH:mm')}
      </span>
    ),
  },
  {
    attributeName: 'statusName',
    name: 'Состояние',
    renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  },
  {
    attributeName: 'creatorFullName',
    name: 'Менеджер',
    renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  },
  {
    attributeName: 'statusUpdateTime',
    name: 'Дата перехода в статус',
    renderColumn: (column) => (
      <span className="whitespace-nowrap">
        {getDateWithTimezone(String(column), undefined, 'dd.MM.yyyy HH:mm')}
      </span>
    ),
  },
  {
    attributeName: 'cityName',
    name: 'Населенный пункт',
    renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  },
  {
    attributeName: 'agentOrganizationFullName',
    name: 'Исполнитель',
    renderColumn: (column) => <span className="whitespace-nowrap">{column as string}</span>,
  },
  {
    attributeName: 'status',
    name: 'Статус',
    renderColumn: (column) => (
      <AgentOrClientOrderStatusForTable status={column as OrderDetailsStatusEnum} />
    ),
  },
];

const bankManagerAllOrdersTableHeadings: TableHeading[] = repeatingTableHeadings
  .slice(0, 2)
  .concat(repeatingTableHeadings.slice(4));

const bankManagerInProgressOrdersTableHeadings: TableHeading[] = repeatingTableHeadings
  .slice(0, 2)
  .concat(repeatingTableHeadings.slice(4));

const bankManagerCompletedOrdersTableHeadings: TableHeading[] = repeatingTableHeadings
  .slice(0, 2)
  .concat(repeatingTableHeadings.slice(4));

const bankManagerPaidOrdersTableHeadings: TableHeading[] = repeatingTableHeadings
  .slice(0, 3)
  .concat(repeatingTableHeadings.slice(4));

const bankManagerCancelledOrdersTableHeadings: TableHeading[] = repeatingTableHeadings
  .slice(0, 2)
  .concat(repeatingTableHeadings.slice(4));

export const bankManagerOrdersTableHeadings = {
  [ClientOrderTabsEnum.all]: bankManagerAllOrdersTableHeadings,
  [ClientOrderTabsEnum.inProgress]: bankManagerInProgressOrdersTableHeadings,
  [ClientOrderTabsEnum.active]: bankManagerCompletedOrdersTableHeadings,
  [ClientOrderTabsEnum.paid]: bankManagerPaidOrdersTableHeadings,
  [ClientOrderTabsEnum.cancelled]: bankManagerCancelledOrdersTableHeadings,
};

const accreditologAllOrdersTableHeadings: TableHeading[] = repeatingTableHeadings
  .slice(0, 2)
  .concat(repeatingTableHeadings.slice(4));

const accreditologInProgressOrdersTableHeadings: TableHeading[] = repeatingTableHeadings
  .slice(0, 2)
  .concat(repeatingTableHeadings.slice(4));

const accreditologActiveOrdersTableHeadings: TableHeading[] = repeatingTableHeadings
  .slice(0, 2)
  .concat(repeatingTableHeadings.slice(4));

const accreditologPaidOrdersTableHeadings: TableHeading[] = repeatingTableHeadings
  .slice(0, 3)
  .concat(repeatingTableHeadings.slice(4));

const accreditologCancelledOrdersTableHeadings: TableHeading[] = repeatingTableHeadings
  .slice(0, 2)
  .concat(repeatingTableHeadings.slice(4));

export const accreditologOrdersTableHeadings = {
  [ClientOrderTabsEnum.all]: accreditologAllOrdersTableHeadings,
  [ClientOrderTabsEnum.inProgress]: accreditologInProgressOrdersTableHeadings,
  [ClientOrderTabsEnum.active]: accreditologActiveOrdersTableHeadings,
  [ClientOrderTabsEnum.paid]: accreditologPaidOrdersTableHeadings,
  [ClientOrderTabsEnum.cancelled]: accreditologCancelledOrdersTableHeadings,
};

export const clientTabs: Tab<ClientOrderTabsEnum>[] = [
  { link: '/client/orders/all', orderType: ClientOrderTabsEnum.all, name: 'Все' },

  {
    link: '/client/orders/inProgress',
    orderType: ClientOrderTabsEnum.inProgress,
    name: 'В работе',
  },
  {
    link: '/client/orders/active',
    orderType: ClientOrderTabsEnum.active,
    name: 'Активные',
  },
  {
    link: '/client/orders/paid',
    orderType: ClientOrderTabsEnum.paid,
    name: 'Оплачено',
  },
  {
    link: '/client/orders/cancelled',
    orderType: ClientOrderTabsEnum.cancelled,
    name: 'Отменённые заявки',
  },
];

export const accreditologTabQueryKeys = {
  [ClientOrderTabsEnum.all]: 12,
  [ClientOrderTabsEnum.inProgress]: 13,
  [ClientOrderTabsEnum.active]: 3,
  [ClientOrderTabsEnum.paid]: 4,
  [ClientOrderTabsEnum.cancelled]: 10,
};

export const bankManagerTabQueryKeys = {
  [ClientOrderTabsEnum.all]: 1,
  [ClientOrderTabsEnum.inProgress]: 2,
  [ClientOrderTabsEnum.active]: 3,
  [ClientOrderTabsEnum.paid]: 4,
  [ClientOrderTabsEnum.cancelled]: 10,
};
