import { useEffect, useState } from 'react';

import { AttachOrder, RateIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useMutation } from 'react-query';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { FileService } from 'services/FileService';
import { WorkInfo } from 'types';
import { File } from 'ui/redesign/File';
import { FilePreview } from 'ui/redesign/FilePreview';
import { Textarea } from 'ui/redesign/Textarea';
import { handleFileDownload } from 'utils/helpers';

interface CompletedWorkProps {
  worksInfo: WorkInfo;
  onDownload: (fileHash: string) => void;
}

export default function CompletedWork({ worksInfo, onDownload }: CompletedWorkProps) {
  const [currentFile, setCurrentFile] = useState<{ content: string; type: string } | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    currentFile &&
      dispatch(
        modalActions.handleOpenModal({
          content: <FilePreview file={currentFile} />,
          options: {
            title: 'Просмотр файла',
            modalClassName: 'sm:mx-auto',
            contentClassName: 'flex w-screen sm:w-auto items-center justify-center',
          },
        })
      );
  }, [currentFile]);

  const { mutateAsync: downloadFile } = useMutation({
    mutationFn: (hash: string) => {
      return FileService.fetchFile(hash);
    },
    onSuccess: (response) => handleFileDownload(Promise.resolve(response)),
  });

  const { mutateAsync: previewFile, isLoading: isPreviewLoading } = useMutation({
    mutationFn: (hash: string) => {
      return FileService.fetchFile(hash);
    },
    onSuccess: (response) => {
      setCurrentFile({
        content: response.data.content,
        type: response.data.contentType,
      });
    },
  });

  return (
    <>
      {worksInfo.description && (
        <div className="mt-5 flex max-w-[540px] flex-col justify-between rounded-[10px] p-5 shadow-chatPanel">
          <div className="mb-4">
            <RateIcon />
            <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">
              Описание выполненных работ
            </span>
          </div>

          <Textarea label={'Описание'} readOnly value={worksInfo.description} disabled />
        </div>
      )}
      {worksInfo?.workActFiles?.length > 0 && (
        <div className="mt-5 flex max-w-[540px] flex-col justify-between rounded-[10px] p-5 shadow-chatPanel">
          <div className="mb-4">
            <AttachOrder />
            <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">
              Акт выполненных работ
            </span>
          </div>
          <div className="mt-1 w-full">
            {worksInfo.workActFiles.map((file) => {
              return (
                <File
                  file={file}
                  key={file.downloadFileIdHash}
                  onDownload={() => downloadFile(file.downloadFileIdHash)}
                  onPreview={() => previewFile(file.downloadFileIdHash)}
                  isHideDeleteFileButton={true}
                />
              );
            })}
          </div>
        </div>
      )}
      {worksInfo?.additionalFiles?.length > 0 && (
        <div className="mt-5 mb-4 flex max-w-[540px] flex-col justify-between rounded-[10px] p-5 shadow-chatPanel">
          <div className="mb-4">
            <AttachOrder />
            <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">
              Дополнительные вложения
            </span>
          </div>
          <div className="mt-1 w-full">
            {worksInfo.additionalFiles.map((file) => {
              return (
                <File
                  file={file}
                  key={file.downloadFileIdHash}
                  onDownload={() => downloadFile(file.downloadFileIdHash)}
                  onPreview={() => previewFile(file.downloadFileIdHash)}
                  isHideDeleteFileButton={true}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
