import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC, useState } from 'react';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { PayFormProps } from './types';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ClientPayResultDto } from 'services/AgentService/types';
import { AgentService } from 'services/AgentService';
import { useSelector } from 'react-redux';
import { getModalState } from 'reduxStore/reducers/ModalSlice/selectors/getModaState';
import { Spinner } from 'ui/Spinner';
import { BaseError } from 'types';
import { toast } from 'react-toastify';

const errorMessage = 'Возникла техническая ошибка, повторите попытку позже';
const successMessage = 'Проверки пройдены успешно. Начался процесс выплат...';

export const PayForm: FC<PayFormProps> = ({ order }) => {
  const dispatch = useAppDispatch();
  const { content, options } = useSelector(getModalState);
  const [isRequestDone, setIsRequestDone] = useState(false);
  const queryClient = useQueryClient();

  const requestDoneHandler = (title: string) => {
    content &&
      options &&
      dispatch(
        modalActions.handleOpenModal({
          content,
          options: {
            ...options,
            title,
          },
        })
      );
    setIsRequestDone(true);
  };

  const { mutateAsync: clientOrderDetailsViewPay, isLoading } = useMutation<
    AxiosResponse<ClientPayResultDto>,
    AxiosError<BaseError>,
    { orderId: string; concurrencyStamp: string }
  >({
    mutationKey: 'clientOrderDetailsViewPay',
    mutationFn: (payload: { orderId: string; concurrencyStamp: string }) =>
      AgentService.pay(payload.orderId, payload.concurrencyStamp),
    onSuccess: (data) => {
      let title = '';
      if (data.status === 200) {
        const res = data.data;
        if (res.isSuccess) {
          title = successMessage;
        } else {
          res.error?.map((el) => {
            title += el + ' ';
          });
        }
      } else {
        title = errorMessage;
      }
      requestDoneHandler(title);
      queryClient.refetchQueries('getClientOrder');
    },
    onError: (data) => {
      data.response?.data.errors
        ? Object.values(data.response?.data.errors).forEach((errors) =>
            errors.forEach((message) => toast.error(message))
          )
        : toast.error(data.response?.data.detail);
      requestDoneHandler(errorMessage);
    },
  });

  const cancelHandler = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <div className="w-full p-4 sm:min-w-[500px] sm:p-0">
      <div className="mt-6 flex justify-center gap-6">
        {isLoading ? (
          <Spinner />
        ) : !isRequestDone ? (
          <>
            <Button
              variant={ButtonVariant.GHOST}
              onClick={cancelHandler}
              className="paragraphs-bold mb-1 ml-[auto] w-[130px] border border-solid border-text-50 !text-text-100"
            >
              ОТМЕНА
            </Button>
            <Button
              className="paragraphs-bold mb-1 w-[190px]"
              onClick={() => {
                if (order.concurrencyStamp) {
                  clientOrderDetailsViewPay({
                    orderId: order.id,
                    concurrencyStamp: order.concurrencyStamp,
                  });
                  dispatch(
                    modalActions.handleOpenModal({
                      content,
                      options: {
                        ...options,
                        title: 'Осуществляется проверка',
                        contentClassName: 'overflow-hidden',
                      },
                    })
                  );
                }
              }}
            >
              ОПЛАТИТЬ
            </Button>
          </>
        ) : (
          <Button className="paragraphs-bold mb-1 w-[190px]" onClick={cancelHandler}>
            ПРИНЯТЬ
          </Button>
        )}
      </div>
    </div>
  );
};
