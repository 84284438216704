import { request } from 'api';
import { GetAgentOrders } from 'services/OrderService/types';
import {
  ClientPaymentRequestRejectCommandDto,
  ExecutorPaymentRequestDto,
  ExecutorPaymentRequestRejectCommandDto,
} from 'types/orders';
import {
  AgentReceiptDto,
  BalanceDto,
  CancelReceiptBody,
  ClientOrderDetailsFeesRequestBody,
  ClientPaymentRequestCalculateFeeQueryDto,
  ClientPaymentRequestCreateCommandDto,
  ClientPaymentRequestDto,
  ClientPaymentRequestSendForSignAfterReworkCommandDto,
  ClientPaymentRequestSendForSignCommandDto,
  ClientPaymentRequestUpdateCommandDto,
  ClientPayResultDto,
  FtsNotificationPagedResultDto,
  GetClientOrders,
  GetOrganizationList,
  GetProductNames,
  MarkManyAsAcknowledgedCommandDto,
  ReceiptCreateCommandDto,
  ReceiptDto,
  ReceiptForListDto,
  ReferenceDto,
} from './types';

export const AgentService = {
  getAgentOrder(id: string) {
    return request<ExecutorPaymentRequestDto>('get', `/api/Agent/ExecutorPaymentRequest/${id}`);
  },
  getClientOrder(id: string) {
    return request<ClientPaymentRequestDto>('get', `/api/Agent/ClientPaymentRequest/${id}`);
  },
  orderDetailsForAgentSuccess(id: string, concurrencyStamp: string) {
    return request<ExecutorPaymentRequestDto>(
      'post',
      `/api/Agent/ExecutorPaymentRequest/${id}/Sign?concurrencyStamp=${concurrencyStamp}`
    );
  },
  orderDetailsForAgentReject(id: string, rejectCommand: ExecutorPaymentRequestRejectCommandDto) {
    return request<ExecutorPaymentRequestDto>(
      'patch',
      `/api/Agent/ExecutorPaymentRequest/${id}/Reject`,
      { data: rejectCommand }
    );
  },
  getClientOrders(tab: number, isMine: boolean, maxResultCount: number) {
    return request<GetClientOrders>(
      'get',
      `/api/Agent/ClientPaymentRequest/GetList?Tab=${tab}&IsMine=${isMine}&MaxResultCount=${maxResultCount}&api-version=1.0`
    );
  },
  createClientPaymentRequest(body: ClientPaymentRequestCreateCommandDto) {
    return request<ClientPaymentRequestDto>('post', `/api/Agent/ClientPaymentRequest/Create`, {
      data: body,
    });
  },
  updateClientPaymentRequest(id: string, body: ClientPaymentRequestUpdateCommandDto) {
    return request<ClientPaymentRequestDto>('put', `/api/Agent/ClientPaymentRequest/${id}/Update`, {
      data: body,
    });
  },
  clientOrderSendForSign(id: string, body: ClientPaymentRequestSendForSignCommandDto) {
    return request<ClientPaymentRequestDto>(
      'post',
      `/api/Agent/ClientPaymentRequest/${id}/SendForSign`,
      { data: body }
    );
  },
  clientOrderSendForSignAfterRework(
    id: string,
    body: ClientPaymentRequestSendForSignAfterReworkCommandDto
  ) {
    return request<ClientPaymentRequestDto>(
      'post',
      `/api/Agent/ClientPaymentRequest/${id}/SendForSignAfterRework`,
      { data: body }
    );
  },
  getAgentOrders(paymentRequestTab: number, skipCount: number, maxResultCount: number) {
    return request<GetAgentOrders>(
      'get',
      `/api/Agent/ExecutorPaymentRequest/GetList?ExecutorPaymentRequestTab=${paymentRequestTab}&SkipCount=${skipCount}&MaxResultCount=${maxResultCount}&api-version=1.0`
    );
  },

  getOrganizationList() {
    return request<GetOrganizationList>('get', '/api/Agent/ClientDictionary/GetOrganizationList?MaxResultCount=999');
  },

  clientOrderDetailsFees(body: ClientOrderDetailsFeesRequestBody) {
    return request<ClientPaymentRequestCalculateFeeQueryDto>(
      'post',
      '/api/Agent/ClientPaymentRequest/Fees',
      { data: body }
    );
  },

  getProductNames() {
    return request<GetProductNames>(
      'get',
      '/api/Agent/ClientDictionary/ProductNames?MaxResultCount=999'
    );
  },

  clientOrderDetailsViewReject(id: string, rejectCommand: ClientPaymentRequestRejectCommandDto) {
    return request<ClientPaymentRequestDto>(
      'patch',
      `/api/Agent/ClientPaymentRequest/${id}/Reject`,
      { data: rejectCommand }
    );
  },

  clientOrderDetailsViewSuccess(id: string, concurrencyStamp: string) {
    return request<ClientPaymentRequestDto>(
      'post',
      `/api/Agent/ClientPaymentRequest/${id}/Sign?concurrencyStamp=${concurrencyStamp}`
    );
  },

  clientOrderDetailsViewTakeForVerification(id: string, concurrencyStamp: string) {
    return request<ClientPaymentRequestDto>(
      'patch',
      `/api/Agent/ClientPaymentRequest/${id}/TakeForVerification?concurrencyStamp=${concurrencyStamp}`
    );
  },

  reassignForVerification(id: string, concurrencyStamp: string) {
    return request<ClientPaymentRequestDto>(
      'patch',
      `/api/Agent/ClientPaymentRequest/${id}/ReassignForVerification?concurrencyStamp=${concurrencyStamp}`
    );
  },

  clientOrderDetailsViewSendToPayment(id: string, concurrencyStamp: string) {
    return request<ClientPaymentRequestDto>(
      'patch',
      `/api/Agent/ClientPaymentRequest/${id}/SendToPayment?concurrencyStamp=${concurrencyStamp}`
    );
  },

  clientOrderDetailsViewCancel(id: string, concurrencyStamp: string) {
    return request<ClientPaymentRequestDto>(
      'patch',
      `/api/Agent/ClientPaymentRequest/${id}/Cancel?concurrencyStamp=${concurrencyStamp}`
    );
  },

  getFtsOrganizationReference() {
    return request<ReferenceDto[]>('get', `/api/FtsOrganization/Reference?Types=1&Types=2`);
  },

  makeFtsOrganizationReference(type: number) {
    return request<ReferenceDto>('post', `/api/FtsOrganization/Reference`, {
      data: { type: type },
    });
  },

  getFtsNotifications() {
    return request<FtsNotificationPagedResultDto>(
      'get',
      `/api/FtsOrganization/FtsNotification/Notifications?maxResultCount=999`
    );
  },

  readFtsNotifications(data: MarkManyAsAcknowledgedCommandDto) {
    return request<'Success'>('patch', `/api/FtsOrganization/FtsNotification/Acknowledge`, {
      data,
    });
  },

  readFtsNotification(id: string) {
    return request<'Success'>('patch', `/api/FtsOrganization/FtsNotification/${id}/Acknowledge`);
  },

  getFtsNotificationsCount() {
    return request<number>('get', `/api/FtsOrganization/FtsNotification/NewNotificationCount`);
  },

  getFtsReceipt(id: string) {
    return request<ReceiptDto>('get', `/api/FtsOrganization/FtsReceipt/${id}`);
  },

  getFtsReceiptList() {
    return request<ReceiptForListDto>(
      'get',
      `/api/FtsOrganization/FtsReceipt/GetList?maxResultCount=999`
    );
  },
  getAgentFtsReceipt(id: string) {
    return request<AgentReceiptDto>('get', `/api/FtsOrganization/FtsReceipt/${id}`);
  },
  cancelReceipt(id: string, body: CancelReceiptBody) {
    return request<AgentReceiptDto>('post', `/api/FtsOrganization/FtsReceipt/${id}/Cancel`, {
      data: body,
    });
  },
  createFtsReceipt(body: ReceiptCreateCommandDto) {
    return request<ReceiptDto>('post', `/api/FtsOrganization/FtsReceipt/Create`, {
      data: body,
    });
  },

  confirmPaymentReceipt(id: string, concurrencyStamp: string, paymentOperationTime: string) {
    return request<ClientPaymentRequestDto>(
      'post',
      `/api/Agent/ClientPaymentRequest/${id}/Receipts?concurrencyStamp=${concurrencyStamp}&paymentOperationTime=${paymentOperationTime}`
    );
  },

  createReceiptByAgent(id: string, concurrencyStamp: string) {
    return request<ExecutorPaymentRequestDto>(
      'post',
      `/api/Agent/ExecutorPaymentRequest/${id}/Receipts?concurrencyStamp=${concurrencyStamp}`
    );
  },

  getBalance() {
    return request<BalanceDto>('get', '/api/Agent/Balance/Amount');
  },

  pay(id: string, concurrencyStamp: string) {
    return request<ClientPayResultDto>(
      'post',
      `/api/Agent/ClientPaymentRequest/${id}/Pay?concurrencyStamp=${concurrencyStamp}`
    );
  },
};
