import { useAppSelector } from 'hooks/useAppSelector';
import { NavLink } from 'react-router-dom';
import { getPermissions } from 'reduxStore/reducers/ProfileSlice/selectors/getPermissions';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import ProfileHead from './ProfileHead';
import ProfileMenuItem from './ProfileMenuItem';
import { profileLinks } from './constants';

export const Profile = () => {
  const permissions = useAppSelector(getPermissions);
  const isAgent = permissions.includes(ProfilePermissions.Agent_PaymentRequest_ReadExecutor);

  return (
    <PageWrapper title="Профиль">
      <div className="flex flex-col gap-6">
        <ProfileHead />
        {profileLinks.map(
          ({ link, title, Icon }) =>
            (isAgent || title !== 'Документы') && (
              <NavLink className="max-w-[600px]" key={link} to={link}>
                <ProfileMenuItem title={title} Icon={Icon} />
              </NavLink>
            )
        )}
      </div>
    </PageWrapper>
  );
};
