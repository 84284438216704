import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { getAccessToken, getCookie } from 'utils/helpers';

const axiosInstance = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

axiosInstance.interceptors.request.use(async (options: AxiosRequestConfig) => {
  const token = getAccessToken();
  if (token && options && options.headers && options.method) {
    options.headers.Authorization = `Bearer ${token}`;

    if (!['GET', 'HEAD', 'OPTIONS', 'TRACE'].includes(options.method.toUpperCase())) {
      options.withCredentials = true;

      await request('GET', '/api/v1/antiforgery/token', { withCredentials: true });

      const cookie = getCookie('XSRF-REQUEST-TOKEN');
      if (cookie) {
        options.headers['X-XSRF-TOKEN'] = cookie;
      }
    }
  }

  return options;
});

export const request = async <T>(
  method: Method,
  url: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => await axiosInstance({ ...options, method, url });
