import { AttachOrder, WritingIcon } from 'assets/icons';
import { AgentOrClientOrderStatusForDetail } from 'components/AgentOrClientOrderStatusForDetail';
import { AlertBannerBlock } from 'components/redesign/AlertBannerBlock';
import { format } from 'date-fns';
import { styles } from 'pages/OrderDetails/OrderDetailsForSubOrEx/components/consts';
import { getAgentOrderDetailsBannerTexts } from 'pages/OrderDetails/utils';
import { FC, useState } from 'react';
import {
  LocalizedOrderDetailsBannerTextsEnum,
  LocalizedOrderReceiptStatusNameEnum,
  OrderDetailsStatusEnum,
} from 'services/AgentService/types';
import { FileNew } from 'ui/redesign/FileNew';
import { formatAmount, getMonthByNumber } from 'utils/helpers';
import { ROUBLE_SIGN } from 'utils/settings';
import { OrderDetailsForAgentActions } from './OrderDetailsForAgentActions';
import { OrderDetailsForAgentTabProps } from './types';

export const OrderDetailsForAgentTab: FC<OrderDetailsForAgentTabProps> = ({ order }) => {
  const [actualOrder, setActualOrder] = useState(order);

  const isShowReceiptBanner =
    actualOrder.status === OrderDetailsStatusEnum.NotFiscalize &&
    actualOrder.receipt?.statusName === LocalizedOrderReceiptStatusNameEnum.NotRegistered;

  const { title, description } =
    getAgentOrderDetailsBannerTexts(
      actualOrder.receipt?.reasonNotFiscalizeIncomeName as LocalizedOrderDetailsBannerTextsEnum
    ) ?? {};

  return (
    <section className="flex flex-col gap-4 py-4 sm:max-w-[950px] sm:gap-6">
      {isShowReceiptBanner && (
        <AlertBannerBlock
          title={<label className="!text-danger">{title}</label>}
          description={<label>{description}</label>}
        />
      )}
      <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
        <div>
          <WritingIcon />
          <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">
            Основная информация
          </span>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Заявка</p>
            <h2 className="headline-bold pt-[6px] text-primary-60">№ {actualOrder.number}</h2>
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Статус</p>
            <AgentOrClientOrderStatusForDetail status={actualOrder.status} />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Дата создания</p>
            <p className="pt-[6px]">
              {format(new Date(actualOrder.creationTime), 'dd.MM.yyyy HH:mm')} (UTC
              {format(new Date(actualOrder.creationTime), 'XXX')})
            </p>
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Вознаграждение</p>
            <h2 className="headline-bold pt-[6px]">
              {formatAmount(actualOrder.paymentAmount, { minimumFractionDigits: 2 })}&nbsp;
              {ROUBLE_SIGN}
            </h2>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Менеджер</p>
            <p className="pt-[6px]">{actualOrder.managerFullName}</p>
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Период</p>
            <p className="pt-[6px]">
              {format(new Date(actualOrder.periodStartDate), 'dd.MM.yyyy') +
                '-' +
                format(new Date(actualOrder.periodEndDate), 'dd.MM.yyyy')}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Год</p>
            <p className="pt-[6px]">{actualOrder.year}</p>
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Месяц</p>
            <p className="pt-[6px]">{getMonthByNumber(actualOrder.month)}</p>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Комиссия с самозанятого, руб.</p>
            <p className="pt-[6px]">{actualOrder.agentOrganizationFee}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
        <div>
          <AttachOrder />
          <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">Вложения</span>
        </div>
        {actualOrder.files?.length !== 0 && (
          <ul className="flex flex-col gap-2">
            {actualOrder.files?.map((file) => (
              <li key={file.readLink} className="w-full max-w-[700px]">
                <FileNew file={file} isHideDeleteFileButton={true} />
              </li>
            ))}
          </ul>
        )}
      </div>
      {actualOrder.receipt && (
        <div className="flex flex-col justify-between gap-4 rounded-[10px] p-5 shadow-chatPanel">
          <div>
            <AttachOrder />
            <span className="body-bold sm:title2-bold mx-3.5 flex-1 sm:flex-none">Чек</span>
          </div>
          <div className="w-full border-b border-[#E4E4E5] pb-1 sm:max-w-[430px]">
            <p className={styles.label}>Статус</p>
            <p>{actualOrder.receipt.statusName}</p>
          </div>
          {actualOrder.receipt.linkReceiptFts && (
            <div className="w-full border-b border-[#E4E4E5] pb-1">
              <p className={styles.label}>Ссылка на чек</p>
              <a
                href={actualOrder.receipt.linkReceiptFts}
                target="_blank"
                rel="noreferrer"
                className="text-primary-60"
              >
                {actualOrder.receipt.linkReceiptFts}
              </a>
            </div>
          )}
        </div>
      )}
      <OrderDetailsForAgentActions order={actualOrder} setActualOrder={setActualOrder} />
    </section>
  );
};
