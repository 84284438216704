import { FC } from 'react';
import { FieldServicesProps } from './types';
import { PlusIcon, TrashIcon } from 'assets/icons';
import classNames from 'classnames';
import { FieldTextarea } from 'components/redesign/FieldTextArea';
import { CustomInput } from 'ui/redesign/CustomInput';
import { Controller } from 'react-hook-form';
import { Button } from 'ui/redesign/Button';
import { newService } from '../consts';

export const FieldServices: FC<FieldServicesProps> = ({
  services,
  serviceRemove,
  serviceAppend,
  control,
  errors,
}) => {
  const disabled = false;

  return (
    <div className="mt-8">
      {services.map((item, index) => {
        return (
          <div key={item.id} className="mb-10 rounded-[10px] shadow-3xl">
            <div className="flex justify-end">
              <button type="button" onClick={() => serviceRemove(index)}>
                <TrashIcon
                  className={classNames('m-2', {
                    'cursor-not-allowed text-text-50': disabled,
                    'cursor-pointer text-danger': !disabled,
                  })}
                />
              </button>
            </div>
            <div className="p-5">
              <FieldTextarea
                name={`services.${index}.name`}
                control={control}
                label="Наименование товаров, работ, услуг"
                isClearable
                maxLength={256}
                errorMessage={errors[`services${index}name`]?.message}
              />
              <br />
              <Controller
                name={`services.${index}.cost`}
                control={control}
                render={({ field }) => {
                  return (
                    /** TODO Переделать компоненту CustomInput DecimalNumber */
                    <CustomInput
                      label="Сумма дохода, руб"
                      onChange={(value) => field.onChange(value)}
                      value={field.value}
                      maxValue={2399999.99}
                      decimal={2}
                      errorMessage={errors[`services${index}cost`]?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
        );
      })}
      <div className="flex justify-end">
        <Button
          className="callout-bold h-10 py-0 !text-white"
          type="button"
          onClick={() => serviceAppend(newService)}
          disabled={services.length > 5}
        >
          ДОБАВИТЬ ТОВАР/УСЛУГУ
          <PlusIcon className="ml-3 -mt-1" />
        </Button>
      </div>
    </div>
  );
};
